import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Task } from "constants/tasks.constants";


interface IUiState {
  loading:boolean;
  error:boolean;
  items:Task[];
  comments: []
}


const initialState: IUiState = {
    loading:false,
    error:false,
    items : [],
    comments: []
}

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload;
    },
    setComments: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.comments = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    setTask:(state, { payload }) => {
      const index = _.findIndex(state.items,{id:payload.id});
      state.items[index] = payload;
    },
  },
})

export const { setLoading, setItems, setError, setTask, setComments } = tasksSlice.actions;

export default tasksSlice.reducer;