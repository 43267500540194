import React from 'react';

import { RegularText, Space } from 'components/Layout/Shared/Elements';
import { Flex,ParagraphTitle,ParagraphText } from 'components/Layout/Shared/Elements';

export const INPUTS_GROUP_CARD: React.FC<{ children:any, title?:string}> = (props):JSX.Element => {
    return (
        <div className={`card inputs-group-card`}>
            {props?.title && <header>{props?.title}</header>}
            {props.children}
        </div>
    );
}

export const INPUTS_GROUP_CARD_ROW: React.FC<{ children:any, label:string, tooltip?:string}> = (props):JSX.Element => {
    return (
        <Flex style='inputs-group-card-row'>
            <ParagraphText style='input-label'>{props.label}</ParagraphText>
            <ParagraphText>{props.children}</ParagraphText>
            <ParagraphText style='input-tooltip'>{props.tooltip}</ParagraphText>
        </Flex>
    );
}
