import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import _ from 'lodash';

import { timing } from 'constants/ui.constants';
import { userPreference, insightStatus } from 'constants/insights.constants';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { updateInsight } from 'services/insights.services';
import * as ICONS from 'assets/icons/General';
import { Completed } from './Completed';
import { DueDateMenu } from './DueDateMenu';
import { userRoles } from 'state/features/global.features';

export const DueDate: React.FC<{ updateInsights: any }> = ({ updateInsights }): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedInsight = useAppSelector((state) => state.insights?.selected);
  const userRole = useAppSelector((state) => state.global?.userRole);
  const [isOpen, setIsOpen] = useState(false);
  const [insightDueDate, setInsightDueDate] = useState(selectedInsight?.due_date);

  function changeDate(date: Date) {
    dispatch(updateInsight({ ...selectedInsight, due_date: date }));
  }

  const handleClick = (event: Event) => {
    event.preventDefault();
    if(_.isEqual(userRole,userRoles.ADMIN)){
      setIsOpen(!isOpen)
    };
  };

  const handleChange = (date: any) => {
    setIsOpen(!isOpen);
    setInsightDueDate(date[0]);
    changeDate(date[0]);
  };

  return (
    <>
      {selectedInsight?.user_preference === userPreference.INTERESTED && (
        <div className='selected-insight-due-date'>
          <div className='text'>
            <ICONS.LOADING />
            &nbsp;
            <p>
              {selectedInsight?.status === insightStatus.AT_WORK ? 'In progress' : 'Waiting for work...'}
              &nbsp;
            </p>
            <ICONS.KEBAB_MENU />
            &nbsp;
            <div className='status-wrapper'>
              <p>Due date: &nbsp;</p>
              {selectedInsight?.status === insightStatus.AT_WORK ? (
                <div className='select-box__control'>
                  <div className='select-box__value-container'>
                    <div className='select-box__single-value' onClick={handleClick}>
                      <>
                        {insightDueDate !== null
                          ? format(new Date(insightDueDate), timing.DATE)
                          : format(new Date(), timing.DATE)}
                      </>
                    </div>
                  </div>
                  {isOpen && (
                    <div className='datepicker-wrap'>
                      <DatePicker
                        minDate={new Date()}
                        startDate={insightDueDate !== null ? new Date(insightDueDate) : new Date()}
                        endDate={new Date()}
                        onChange={handleChange}
                        selectsRange={true}
                        selectsStart
                        inline
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>{insightDueDate || 'Not set'}</>
              )}
            </div>
          </div>
          <DueDateMenu updateInsights={updateInsights} />
        </div>
      )}
      <Completed />
    </>
  );
};
