import React, { Fragment } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

import { timing } from 'constants/ui.constants';
import { useAppSelector } from 'hooks/redux.hooks';
import { Top } from './Top';
import { IncidentsCls } from 'class/class.incidents';
import { TasksCls } from 'class/class.tasks';
import { IncidentStatus } from 'constants/incident.constants';
import { ParagraphTitle } from 'components/Layout/Shared/Elements';
import { IncidentsList } from './IncidentsList';

export const LeftSide: React.FC = () => {
    const incidents = useAppSelector((state) => state.incidents.items);
    const filter = useAppSelector((state) => state.ui.controlRoom.filter);
    const incidentsCls = new IncidentsCls(incidents);
    const tasks = useAppSelector((state) => state.tasks.items);
    const tasksCls = new TasksCls(tasks);
    const uniqueOwners = incidentsCls.uniqueOwners;
    incidentsCls.filter(filter);
    incidentsCls.joinTasks(tasksCls.groupedBy('incident_id'));
    const lastUpdated = incidentsCls.lastUpdate ?
    format(new Date(incidentsCls.lastUpdate),timing.TIME): "";
    const openedIncidents = incidentsCls.groupedByStatus([
        IncidentStatus.IN_PROGRESS,
        IncidentStatus.TUNING,
        IncidentStatus.OPEN,
        IncidentStatus.WAITING,
        IncidentStatus.TIME_TO_ASSIGN,
        IncidentStatus.TIME_TO_RESPONSE
    ])
    const closedIncidents = incidentsCls.groupedByStatus([IncidentStatus.CLOSE]);
    return  <>
        <Top owners={uniqueOwners} lastUpdate={lastUpdated}/>
        { openedIncidents.length > 0 ?
            <IncidentsList incidents={openedIncidents}/> :
            <div>no results</div>
        }
        <ParagraphTitle>Closed Incidents</ParagraphTitle>
        { closedIncidents.length > 0 ?
        <IncidentsList incidents={closedIncidents}
        style={'grayed low-opacity'}/> : <div>no results</div>
        }
    </>
}