import {
  API_GET_INSIGHTS,
  API_UPDATE_INSIGHT,
  API_CREATE_INSIGHT,
  API_GET_INSIGHT_IMAGE,
  IMAGE_TYPE
} from 'constants/db.constants';
import {
  setError,
  setItems,
  setLoading,
  setSelected,
  updateInsights,
  addInsight,
  setInsightImage
} from 'state/features/insights.features';
import { Insight } from 'constants/insights.constants';
import API from '../services/api.service';

export function fetchInsights() {
  const paramsSetting = {
    sinceDaysAgo: 'All',
    untilDaysAgo: 0
  };

  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_INSIGHTS, API.wrapParamsWithCompany(getState, paramsSetting))
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function setSelectedInsight(itemId: number) {
  return (dispatch: any, getState:any) => {
    dispatch(setSelected(itemId));
    getInsightImage({ type: IMAGE_TYPE.INSIGHT, id: itemId });
  };
}

export function updateInsight(item: Insight) {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .post(API_UPDATE_INSIGHT + API.addQueryWithCompany(getState), item)
      .then((response) => {
        dispatch(updateInsights(response.data.insight));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function createInsight(item: object) {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .post(API_CREATE_INSIGHT + API.addQueryWithCompany(getState), item)
      .then((response) => {
        dispatch(addInsight(response.data.insight));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function getInsightImage(data: object) {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .post(API_GET_INSIGHT_IMAGE+ API.addQueryWithCompany(getState), data)
      .then((response) => {
        dispatch(setInsightImage(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}
