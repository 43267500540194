import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import _ from 'lodash';

import rootReducer from './reducers';
import { 
  togglePage,
  setConrolRoomSortTasks,
  controlRoomSortInitialState,
  setReportsTasks,
  reportsTasksInitialState,
  setReportsAllFilters,
  setReportsFalsePositiveFilter,
  reportsFilterInitialState,
  setConrolRoomActiveIncident,
  activeIncidentInitialState,
  setStatus,
  setSevirirty,
  setControlRoomSortIncidents,
  controlRoomSortIncidentInitialState
} from './features/ui.features';
import { setAdminCompany, setAdminCompanies } from './features/company.features';
import { IncidentsClosingReason, IncidentStatus } from 'constants/incident.constants';
import { setItems } from './features/incidents.features';
import { pagesEnum } from 'constants/ui.constants';
import { fetchCompanyDetails, fetchMonitoredDevices, fetchCompanyUsers, fetchSourceIps, fetchUseCaseRules } from 'services/company.services';
import { setSelected } from './features/insights.features';

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: setAdminCompanies,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setAdminCompany(action.payload?.[0].company_name));   
  },
})

listenerMiddleware.startListening({
  actionCreator: setAdminCompany,
  effect: async (action, listenerApi) => {  
    listenerApi.dispatch(togglePage(pagesEnum.DASHBOARD))
    listenerApi.dispatch(setSelected({})) 
    listenerApi.dispatch(fetchCompanyDetails());
  },
})

listenerMiddleware.startListening({
  actionCreator: togglePage,
  effect: async (action, listenerApi) => {    
    const previesState = listenerApi.getOriginalState();
    if(previesState?.ui.page !== action.payload){
      listenerApi.dispatch(setItems([]))
    }  
    if (action.payload !== pagesEnum.CONTROL_ROOM) {
      listenerApi.dispatch(setConrolRoomActiveIncident(activeIncidentInitialState));
      listenerApi.dispatch(setStatus(reportsFilterInitialState.status))
      listenerApi.dispatch(setSevirirty(reportsFilterInitialState.severity))
      listenerApi.dispatch(setControlRoomSortIncidents(controlRoomSortIncidentInitialState)) 
      listenerApi.dispatch(fetchUseCaseRules());
    } 
    switch(action.payload){ 
      case pagesEnum.CONTROL_ROOM:
          listenerApi.dispatch(setConrolRoomSortTasks(controlRoomSortInitialState));
      break;
      case pagesEnum.REPORTS:
        listenerApi.dispatch(setReportsTasks(reportsTasksInitialState));
        listenerApi.dispatch(setReportsAllFilters(reportsFilterInitialState)); 
      break;
      case pagesEnum.REPORTS_FALSE_POSITIVE_RATE:
        listenerApi.dispatch(setReportsTasks(reportsTasksInitialState));
        listenerApi.dispatch(setReportsFalsePositiveFilter());
      break;
      case pagesEnum.COMPANY_PROFILE:
      case pagesEnum.COMPANY_PROFILE_MONITOR_DEVICE:
        if(_.isEmpty(previesState.company.details)){
          listenerApi.dispatch(fetchCompanyDetails());
        }
        listenerApi.dispatch(fetchCompanyUsers());
        listenerApi.dispatch(fetchSourceIps());
        listenerApi.dispatch(fetchMonitoredDevices(previesState.company.companyName));
        listenerApi.dispatch(fetchUseCaseRules());
      break;
    }
  },
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
