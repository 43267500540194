import React, { Fragment, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { fetchIncidents } from 'services/incidents.services';
import { fetchCompanyUsers, fetchTranslation } from 'services/company.services';
import * as THEME from 'components/Layout/theme';
import { fetchTasks } from 'services/tasks.services';
import { OpenIncident } from './OpenIncident';
import { RightSide } from './RightSide';
import { LeftSide } from './LeftSide';
import { getSAS } from 'services/global.service';
import { setConrolRoomActiveIncident } from 'state/features/ui.features';
import { activeTabs } from 'constants/incident.constants';
import { TasksCls } from 'class/class.tasks';
import { IncidentsCls } from 'class/class.incidents';

export const ControlRoom: React.FC = () => {
  const dispatch = useAppDispatch();
  const sinceDay = useAppSelector((state) => state.ui.controlRoom.sinceDaysAgo);
  const untilDay = useAppSelector((state) => state.ui.controlRoom.untilDaysAgo);
  const incidents = useAppSelector((state) => state.incidents.items);
  const tasks = useAppSelector((state) => state.tasks.items);
  const [loadingIncidents, setLoadingIncidents] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);

  const tasksCls = new TasksCls(tasks);
  const incidentsCls = new IncidentsCls(incidents);
  incidentsCls.joinTasks(tasksCls.groupedBy('incident_id'));

  const companyName = useAppSelector((state) => state.company.details?.company_name);

  useEffect(() => {
    dispatch(fetchTranslation());
    if (companyName !== 'trustnet') {
      dispatch(getSAS());
      const intervalId = setInterval(() => {
        dispatch(getSAS());
      }, 1000 * 60 * 60);
      return () => clearInterval(intervalId);
    }
  }, [companyName]);

  useEffect(() => {
    if (companyName !== 'trustnet') {
      dispatch(fetchIncidents(sinceDay, untilDay)).then(() => setLoadingIncidents(false));
      dispatch(fetchTasks(sinceDay, untilDay)).then(() => setLoadingTasks(false));
      dispatch(fetchCompanyUsers());
    }
  }, [sinceDay, untilDay, companyName]);

  useEffect(() => {
    if (!loadingIncidents && !loadingTasks) {
      const path = location.pathname;
      const match = path.match(/\/ticket\/(\d+)/);

      if (match) {
        const ticketId = parseInt(match[1], 10);
        const foundIncident = incidentsCls.incidents.find((incident) => incident.external_id === ticketId);

        if (foundIncident) {
          const dispatchState = {
            tab: activeTabs.INEVESTIGATION,
            active: true,
            incident: foundIncident
          };

          dispatch(setConrolRoomActiveIncident(dispatchState));
        }
      }
    }
  }, [loadingIncidents, loadingTasks, location.pathname, incidentsCls.incidents, dispatch]);

  if (loadingIncidents || loadingTasks) {
    return <div style={{ position: 'absolute', top: '50%', left: '50%' }}>Loading...</div>;
  }

  return (
    <Fragment>
      <OpenIncident />
      <THEME.TWO_COLUMNS page='controlRoom' LeftSide={LeftSide} RightSide={RightSide} rightSideWidth='wide' />
    </Fragment>
  );
};
