import React from 'react';
import _ from 'lodash';

import { Insight, insightStatus } from 'constants/insights.constants';

export const InsightStatusBadge: React.FC<{ item: Insight }> = ({ item }): JSX.Element => {
  return (
    <>
      {(item && !_.isEqual(item.status,insightStatus.NEW_RECOMMENDATION) && 
        <div className={`insight-status ${_.isEqual(item.status,insightStatus.FINISHED) ? 'done' : 'progress'}`}>
          <p>
            <>{_.isEqual(item.status,insightStatus.WAITING_FOR_WORK) ? 'Waiting for work' : _.isEqual(item.status,insightStatus.AT_WORK) ? 'In Progress' : 'Completed'}</>
          </p>
        </div>
      )}
    </>
  );
};