import React from 'react';
import { ProviderProps } from 'constants/ui.constants';
import { Insight, userPreference, insightStatus } from 'constants/insights.constants';

export const InsightActionButtons: React.FC<{ buttonsData: any; selectedInsight: Insight; updateInsights: any }> = ({
  buttonsData,
  selectedInsight,
  updateInsights
}) => {
  return (
    <ITEM_ACTIONS>
      <ITEM_ACTIONS_BAR buttonsData={buttonsData} selectedInsight={selectedInsight} updateInsights={updateInsights} />
    </ITEM_ACTIONS>
  );
};

const ITEM_ACTIONS: React.FC<ProviderProps> = (props) => {
  return <div className='item-actions'>{props.children}</div>;
};

const ITEM_ACTIONS_BAR: React.FC<{ buttonsData: any; selectedInsight: Insight; updateInsights: any }> = ({
  buttonsData,
  selectedInsight,
  updateInsights
}) => {
  return buttonsData.map((button: any, index: number) => {
    return (
      <ITEM_ACTION key={index} button={button} selectedInsight={selectedInsight} updateInsights={updateInsights} />
    );
  });
};

const ITEM_ACTION: React.FC<{ selectedInsight: Insight; updateInsights: any; button: any }> = (props) => {
  const buttonText = () => {
    return props.selectedInsight && props.selectedInsight.user_preference === props.button.title.toLowerCase();
  };

  const getInterested = () => {
    return props.selectedInsight && props.selectedInsight.user_preference === userPreference.INTERESTED;
  };

  const setClassName = () => {
    if (props.selectedInsight?.status === insightStatus.FINISHED) {
      return 'disabled';
    } else {
      if ((getInterested() && buttonText()) || (!getInterested() && buttonText())) {
        return 'selected';
      } else if (getInterested() && !buttonText()) {
        return 'disabled';
      }
    }
    return '';
  };

  return (
    <div
      className={`button button-action item-action 
      ${setClassName()}`}
      onClick={
        props.selectedInsight?.status === insightStatus.FINISHED || getInterested() ? undefined : props.updateInsights
      }
    >
      <div>{props.button.title}</div>
      <div className='item-action-icon'>{<props.button.icon />}</div>
    </div>
  );
};
