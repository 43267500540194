import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import * as ICONS from 'assets/icons/General';
import { NOTIFICATION_CARD } from './Cards/NotificationCard';
import { updateIntervalNotifications } from 'state/features/notifications.features';
import { fetchIntervalNotifications } from 'services/notifications.services';

export const NotificationsStack: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications);
  useEffect(() => {
    dispatch(fetchIntervalNotifications());
  }, []);

  useEffect(() => {
    setInterval(() => {
      dispatch(fetchIntervalNotifications());
    }, 300000);
  }, []);

  function closeNotification(index: number) {
    dispatch(updateIntervalNotifications(index));
  }

  return (
    <div className='notifications-stack'>
      {notifications.allowDesktopNotifications &&
        notifications.intervalNotifications.map((item, index) => {
          return (
            <div className='card notification-card' key={index}>
              <NOTIFICATION_CARD notification={item} />
              <div className='notification-close' onClick={() => closeNotification(index)}>
                <ICONS.CLOSE />
              </div>
            </div>
          );
        })}
    </div>
  );
};
