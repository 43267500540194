import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { differenceInCalendarDays, format } from 'date-fns';
import { Page, Text, View, Document,  Image, PDFDownloadLink, PDFRenderer} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';

import {  setReportsExecutiveFilter } from "state/features/ui.features";
import { _t } from 'components/helpers';
import { DOWNLOAD } from 'assets/icons/General';
import { DateFilter } from '../DateFilter';
import * as CHARTS from 'components/Pages/Dashboard/Charts';
import { GraphsCls } from 'class/class.graphs';
import { useAppSelector } from 'hooks/redux.hooks';
import { eventPerSeconds, timing } from 'constants/ui.constants';
import { IncidentsCls } from 'class/class.incidents';
import { InfoBox } from './components/InfoBox';
import { Incident } from 'constants/incident.constants';
import { styles } from './styles';
import { IncidentsTable } from './components/IncidentsTable';
import { InsightsTable } from './components/InsightsTable';
import { Insight } from 'constants/insights.constants';
import { ChartWrap } from './components/ChartWrap';

export const PageHeader: React.FC<{start:string, end:string, companyName:string}> = ({start, end, companyName}) => {
    return (
        <View style={{height:'100px'}} fixed>
            <View style={{position:'absolute', left:'0'}}>
                <Text style={styles.headerTitle}>TSOC Executive Report</Text>
                <Text style={styles.headerDate}>{start} - {end}</Text>
            </View>
            <View style={{position:'absolute', right:'0'}}>
                <Text style={styles.companyName}>{companyName}</Text>
            </View>
        </View>
    )
}

export const ViewerPDF: React.FC<{incidents:Incident[], totalIncidents:Incident[], insights:Insight[]}> = ({incidents, totalIncidents, insights}) => {
    const configuration = useAppSelector((state) => state.company.configuration);
    const comapny = useAppSelector((state) => state.company);
    const keyEventPerSecond = eventPerSeconds['14'];
    const eventPerSecond = configuration[keyEventPerSecond];
    const monitoredDeviceNumber = useAppSelector((state) => state.company.monitoredDeviceNumber);
    const graphsCls = new GraphsCls();
    graphsCls.setIncidents = incidents;
    const incidentsCls = new IncidentsCls(incidents);
    const uiReports = useAppSelector((state) => state.ui.reports.executive);
    const startDate = _.isEqual(uiReports.start_date,'all_') ?
    new Date(incidentsCls.startDate) : new Date(uiReports.start_date);
    const range = differenceInCalendarDays(new Date(),startDate)
    const [chart1,setChart1] = useState();
    const [chart2,setChart2] = useState();
    const [chart3,setChart3] = useState();
    const [chart4,setChart4] = useState();
    const [chart5,setChart5] = useState();
    const refChart1 = useRef(null);
    const refChart2 = useRef(null);
    const refChart3 = useRef(null);
    const refChart4 = useRef(null);
    const refChart5 = useRef(null);
    const PdfDocument = <Document>
                    <Page size="A4" style={styles.page}>
                        <PageHeader start={format(startDate, timing.DATE)} end={format(new Date(), timing.DATE)} companyName={comapny.details.company_name}/>
                        <View style={{position:'relative', height:'100px'}}>
                            <View style={{width:'22%',position:'absolute',left:0}}>
                                <InfoBox title={incidentsCls.falsePositiveRate} label='False positive rate'/>
                            </View>
                            <View style={{width:'22%',position:'absolute',left:'26%'}}>
                                <InfoBox title={eventPerSecond} label='Events per second'/>
                            </View>
                            <View style={{width:'22%',position:'absolute',right:'26%'}}>
                            <InfoBox title={String(incidentsCls.amount)} label='Incident count'/>
                                </View>
                            <View style={{width:'22%',position:'absolute',right:0}}>
                                <InfoBox title={String(monitoredDeviceNumber)} label='Monitored Data source'/>
                            </View>
                        </View>
                        <View>
                            <View style={{border:'1px solid gray',position:'relative',height:'170px'}}>
                            <Text style={styles.legend}>Incidents by severity</Text>
                            {chart2 && <Image
                                src={chart2} style={{height:'100%',marginLeft:'20%'}}
                            />}
                            </View>
                            <View style={{width:'100%',height:'25px'}}></View>
                            <View style={{border:'1px solid gray',position:'relative',height:'170px'}}>
                            <Text style={styles.legend}>Incidents by status</Text>
                            {chart3 && <Image
                                src={chart3} style={{height:'100%',marginLeft:'20%'}}
                            />}
                            </View>
                        </View>
                        <View style={{width:'100%',height:'25px'}}></View>
                        <View style={{border:'1px solid gray',position:'relative',height:'180px'}}>
                            <Text style={styles.legend}>SLA</Text>
                            {chart4 && <Image
                                src={chart4} style={{height:'100%',marginLeft:'20%'}}
                            />}
                        </View>
                        <View style={styles.section} break>
                            <View style={{border:'1px solid gray',position:'relative',height:'180px'}}>
                                <Text style={styles.legend}>Incidents by Category</Text>
                                {chart1 && <Image cache={false}
                                        src={chart1} style={{height:'100%',marginLeft:'30%'}}
                                    />}
                            </View>
                            <View style={{width:'100%',height:'25px'}}></View>
                            <View style={{border:'1px solid gray',position:'relative',height:'230px'}}>
                                <Text style={styles.legend}>Historical Data</Text>
                                {chart1 && <Image
                                        src={chart5} style={{height:'100%',marginLeft:'0%'}}
                                 />}
                            </View>
                        </View>
                        <View style={{width:'100%',height:'25px'}}></View>
                        <IncidentsTable incidents={incidents}/>
                        <InsightsTable insights={insights}/>
                        <Text style={{position:'absolute','bottom':'16px',left:'16px',fontSize:'9px'}} render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} of ${totalPages}`
                        )} fixed />
                    </Page>
             
               </Document>;
    useEffect(()=>{
        html2canvas(refChart1.current,{scale:3}).then(canvas => {
            if(!_.isEqual(canvas.toDataURL("image/png", 1.0),'data:,')){
                setChart1(canvas.toDataURL("image/png", 1.0))
            }
        })
        html2canvas(refChart2.current,{scale:3}).then(canvas => {
            if(!_.isEqual(canvas.toDataURL("image/png", 1.0),'data:,')){
                setChart2(canvas.toDataURL("image/png", 1.0))
            }
        })
        html2canvas(refChart3.current,{scale:3}).then(canvas => {
            if(!_.isEqual(canvas.toDataURL("image/png", 1.0),'data:,')){
                setChart3(canvas.toDataURL("image/png", 1.0))
            }
        })
        html2canvas(refChart4.current,{scale:3}).then(canvas => {
            if(!_.isEqual(canvas.toDataURL("image/png", 1.0),'data:,')){
                setChart4(canvas.toDataURL("image/png", 1.0))
            }
        })
        html2canvas(refChart5.current,{scale:3}).then(canvas => {
            if(!_.isEqual(canvas.toDataURL("image/png", 1.0),'data:,')){
                setChart5(canvas.toDataURL("image/png", 1.0))
            }
        })
    })

    return (
        <>
        <header>
            <DateFilter
                label='From'
                uiDate={uiReports.start_date}
                dateKey='start_date'
                handleDispatch={setReportsExecutiveFilter}
            />
            <DateFilter
                label='To'
                dateKey='end_date'
                uiDate={uiReports.end_date}
                handleDispatch={setReportsExecutiveFilter}
            />
            <PDFDownloadLink document={PdfDocument}>             
                <div className='button'>Export to PDF <DOWNLOAD/></div>
            </PDFDownloadLink>
        </header>
        <div style={{position:'relative',overflow:'hidden'}}>
            {(chart1 && chart2 && chart3 && chart4 && chart5) &&  <PDFViewer className='pdf-viewer' >
                {PdfDocument}
            </PDFViewer>}
            <ChartWrap forwardRef={refChart1} id="chart1">
                <CHARTS.BOTTOM_LEFT_CHART enlargeState={{maximize:true}} translateY={0} data={graphsCls.categoryLevelSummary}/>
            </ChartWrap>
            <ChartWrap forwardRef={refChart2} id="chart2">
                <CHARTS.TOP_LEFT_CHART name='sevirity' data={graphsCls.incidentServirity.reverse()}/>
            </ChartWrap>
            <ChartWrap forwardRef={refChart3} id="chart3">
                <CHARTS.TOP_LEFT_CHART name='status' data={graphsCls.incidentStatus}/>
            </ChartWrap>
            <ChartWrap forwardRef={refChart4} id="chart4">
                <CHARTS.BOTTOM_RIGHT_CHART data={graphsCls.getAvrageSLA}/>
            </ChartWrap>
            <ChartWrap forwardRef={refChart5} id="chart5">
                <CHARTS.HISTORICAL_DATA data={GraphsCls.getHistoricalData(range,totalIncidents)}/>
            </ChartWrap>
                     
        </div>
        </>
    )
}