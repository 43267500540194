import React, { useState } from 'react';
import Clock from 'react-digital-clock';
import { format } from 'date-fns';

import { toggleMode } from 'state/features/ui.features';
import { timing } from 'constants/ui.constants';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import * as ICONS from 'assets/icons/General';
import { ProfileMenu } from './ProfileMenu';
import { Notifications } from './Notifications';
import { ContactForm } from '../Shared/ContactForm';

export const Right: React.FC<{ handleShow: any }> = ({ handleShow }) => {
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector((state) => state.ui.darkMode);
  const _toogleMode = () => {
    localStorage.setItem('theme', darkMode ? 'light' : 'dark');
    dispatch(toggleMode());
  };

  return (
    <div className='nav-right'>
      <div className='profile'>
        <div className='logo logo-profile'>
          <ProfileMenu handleShow={handleShow} />
        </div>
        <div className='icon notifications icons-notifications-light'>
          <Notifications />
        </div>
        <div className='icon icons-chat-light'>
          <ContactForm />
        </div>
        <div className='icon icons-moon-light' onClick={_toogleMode}>
          <ICONS.MOON />
        </div>
      </div>
      <div className='clock'>
        <div className='icon icons-clock-light'>
          <ICONS.CLOCK />
        </div>
        <div className='time'>
          <Clock format={'hh-mm'} hour12={false} />, {format(new Date(), timing.MONTH_DAY)}
        </div>
      </div>
    </div>
  );
};
