import React,{ Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { fetchIncidents } from 'services/incidents.services';
import { fetchTasks } from 'services/tasks.services';
import * as THEME from 'components/Layout/theme';
import * as TAB from 'components/Layout/Shared/Tabs';
import { FiltersRow } from './Excel/FiltersRow';
import { IncidentsTable } from './Excel/IncidentsTable';
import { IncidentsCls } from 'class/class.incidents';
import { TasksCls } from 'class/class.tasks';
import { IncidentEnum, reportsTabs } from 'constants/incident.constants';
import { ExecutiveReport } from './Executive';
import { fetchInsights } from 'services/insights.services';
import { IncidentTasks } from './Excel/IncidentTasks';
import { _t} from 'components/helpers';

export const Reports: React.FC = () => {
    const dispatch = useAppDispatch();
    const uiReports = useAppSelector((state) => state.ui.reports);
    const incidents = useAppSelector((state) => state.incidents.items);
    const incidentsCls = new IncidentsCls(incidents);
    const tasks = useAppSelector((state) => state.tasks.items);
    const tasksCls = new TasksCls(tasks);
    incidentsCls.joinTasks(tasksCls.groupedBy('incident_id'));
    incidentsCls.filter(uiReports.filter);
    const sortedIncidents = incidentsCls.sortBy(uiReports.sort.name,uiReports.sort.orderByState);
    const filteredColumns = _.xor(_.values(IncidentEnum),_.xor(_.values(IncidentEnum), uiReports.columns));
    const [tab,setTab] = useState(reportsTabs.EXPORT_EXCEL); 
    useEffect(() => {
        dispatch(fetchIncidents(uiReports.sinceDaysAgo, uiReports.untilDaysAgo));
        dispatch(fetchTasks(uiReports.sinceDaysAgo, uiReports.untilDaysAgo));
        dispatch(fetchInsights());
    }, [uiReports.sinceDaysAgo]);

    return (
        <THEME.ONE_COLUMN page='reports regular'>
                <TAB.TABS_HEADER style='line'>
            <TAB.TAB_BUTTON
                tab={{
                    key:reportsTabs.EXPORT_EXCEL,
                    title:'Export to Excel',
                    isActive:_.isEqual(reportsTabs.EXPORT_EXCEL,tab)
                }}
                toggle={() => { setTab(reportsTabs.EXPORT_EXCEL) }}
            />
            <TAB.TAB_BUTTON
                tab={{
                    key:reportsTabs.EXECUTIVE_REPORT,
                    title:'Executive Report',
                    isActive:_.isEqual(reportsTabs.EXECUTIVE_REPORT,tab)
                }}
                toggle={() => { setTab(reportsTabs.EXECUTIVE_REPORT) }}
            />
        </TAB.TABS_HEADER>
                <TAB.TABS_CONTAINER>
                    <Fragment>
                    {_.isEqual(reportsTabs.EXPORT_EXCEL,tab) && <span>
                        <FiltersRow incidents={incidents} sortedIncidents={sortedIncidents} filteredColumns={filteredColumns}/>
                        <IncidentsTable sortedIncidents={sortedIncidents} filteredColumns={filteredColumns}/>
                    </span>}
                    {_.isEqual(reportsTabs.EXECUTIVE_REPORT,tab) &&
                        <ExecutiveReport/>
                    }
                    </Fragment>                
                </TAB.TABS_CONTAINER>
                <IncidentTasks tasksState={uiReports.tasks} tasks={tasks}/>
        </THEME.ONE_COLUMN>
    )
}