import _ from 'lodash';
import {
    Incident,
    IncidentSeverityOrder,
    IncidentsClosingReason,
    IncidentFieldsForDisplay,
    IncidentEnum
} from 'constants/incident.constants';
import { orderBy } from 'constants/ui.constants';
import { Task, taskStatus } from 'constants/tasks.constants';

export class IncidentsCls {
    incidents:Incident[];

    constructor(incidents:Incident[] = []){
        this.incidents = [];
        incidents.forEach((incident) => {
            let _incident = {...incident};
            _incident.tasks = [];
            this.incidents.push(_incident);
        })
    }

    get amount():number{
        return this.incidents.length;
    }

    get lastUpdate():Date | undefined{
        const sortList = this.sortByDate;
        return sortList.length > 0 ? sortList[0].created_at : undefined;
    }

    get startDate():Date{
        const sortList = _.orderBy(this.incidents, IncidentEnum.CREATED_AT);
        return sortList.length > 0 ? new Date(sortList[0].created_at) : new Date();
    }

    get falsePositiveRate():string{
        const ObjGrouping = _.groupBy(this.incidents,'closing_reason');

        if(_.has(ObjGrouping,IncidentsClosingReason.FALSE_POSITIVE_RATE)){
            const precent = Math.round((ObjGrouping[IncidentsClosingReason.FALSE_POSITIVE_RATE].length / this.incidents.length) * 100);
            return precent+'%';
        }
        return '0%';
    }

    get sortByDate():Incident[] {
        return _.orderBy(this.incidents,['created_at'],['desc']);
    }

    get uniqueOwners():string[] {
        return _.keys(_.groupBy(this.incidents,'owner'));
    }

    static uniques(name:IncidentEnum,incidents:Incident[]):string[] {
        return  _
        .chain(incidents)
        .filter((incident:Incident) => incident[name] !== null)
        .groupBy(name)
        .keys()
        .value();
    }

    get sortBySevirity():Incident[] {
        let results:Incident[] = [];
        const ObjGrouping = _.groupBy(this.incidents,'severity');
        IncidentSeverityOrder.forEach(sevirity => {
            if(_.has(ObjGrouping,sevirity)){
                results = _.concat(results,ObjGrouping[sevirity])
            }
        })
        return results;
    }

    filter(filter:{[key:string]:string}) {
        for (let key in filter){
            try{   
            if(!filter[key].match('all_')){
                switch(key){
                    case IncidentEnum.TASKS:
                        this.incidents = _.filter(this.incidents, (incident) => { 
                            return _.find(incident.tasks,{status : filter[key]})});
                    break;
                    case 'start_date':
                    case 'end_date':
                        this.incidents = _.filter(this.incidents, (incident) => {
                            return new Date(filter.start_date) <= new Date(incident[IncidentEnum.CREATED_AT]) && 
                            new Date(filter.end_date) >= new Date(incident[IncidentEnum.CREATED_AT])
                        });   
                    break;
                    default:
                        this.incidents = _.filter(this.incidents,{[key]:filter[key]})
                }
            }
            }catch(error){
                console.log(error)
            }
        }
    }

    groupedByStatus(status:string[]) {
        return  _
        .chain(this.incidents)
        .filter((incident:Incident) => status.includes(String(incident.status)))
        .orderBy(['created_at'],['desc'])
        .value();
    }

    joinTasks(groupedTasks:{[key:number]:Task[]}){
       this.incidents.forEach((incident:Incident) => {
            if(_.has(groupedTasks,incident.id))
                incident.tasks = groupedTasks[incident.id]
       })
    }

    static tasksSummary(incident:Pick<Incident,IncidentFieldsForDisplay>):{[key:string]:number}{
        let taskSummary = {
            total:incident.tasks?.length,
            opened:_.filter(incident.tasks,(task:Task) => task.status !== taskStatus.CLOSE)?.length
        };
        return taskSummary;
    }

    sortBy(name:IncidentEnum, orderByState:orderBy ):Incident[] {
        return _.orderBy(this.incidents,[name],orderByState);
    }
}