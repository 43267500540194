import { combineReducers } from '@reduxjs/toolkit';
import globalReducer from './features/global.features';
import uiRreducer from './features/ui.features';
import incidentsReducer from './features/incidents.features';
import tasksReducer from './features/tasks.features';
import companyReducer from './features/company.features';
import insightsReducer from './features/insights.features';
import notificationsReducer from './features/notifications.features';
import contactReducer from './features/contact.features';

const rootReducer = combineReducers({
  global: globalReducer, // example with async dispatch
  ui: uiRreducer,
  incidents: incidentsReducer,
  tasks: tasksReducer,
  company: companyReducer,
  insights: insightsReducer,
  notifications : notificationsReducer,
  contact: contactReducer,
});

export default rootReducer;
