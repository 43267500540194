import { DASHBOARD } from 'assets/icons/General';
import { createContext } from 'react';
import { IncidentStatus } from './incident.constants';
export interface ProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export enum arrowActions {
  BACK = 'back',
  FORWARD = 'forward'
}

export enum orderBy {
  ASC = 'asc',
  DESC = 'desc'
}

export type dropdownOption = {
  value: any;
  label: string;
};

export enum timing {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  DATE = 'd/MM/yyyy',
  FULLDATE = 'd/MM/yyyy hh:mm a',
  TIME = 'hh:mm a',
  MONTH_DAY = 'dd MMM yyyy'
}

export const controlRoomSinceDay: string = '7';
export const controlRoomMaxInfoBox: number = 6;
export const notificationsAmount: number = 10;
export const reportsExecutiveCounter: number = 4;

export enum rangeOptions {
  SINCE = 'sinceDaysAgo',
  UNTIL = 'untilDaysAgo'
}

export enum rangeNumbersOptions {
  LAST_3 = '3',
  LAST_7 = '7',
  LAST_14 = '14',
  LAST_30 = '30',
  LAST_90 = '90'
}

export const eventPerSeconds = {
  '3': 'event_per_second_first',
  '7': 'event_per_second_second',
  '14': 'event_per_second_third',
  '30': 'event_per_second_fourth',
  '90': 'event_per_second_fifth'
};

export const orderIncidentsStatusUI: Array<IncidentStatus> = [
  IncidentStatus.OPEN,
  IncidentStatus.IN_PROGRESS,
  IncidentStatus.TUNING,
  IncidentStatus.WAITING
];

export type pagesStack = {
  name: string;
  icon: string;
};
export enum pagesEnum {
  DASHBOARD = 'dashboard',
  CONTROL_ROOM = 'controlRoom',
  REPORTS = 'reports',
  REPORTS_FALSE_POSITIVE_RATE = 'reportsFalsePositiveRate',
  INSIGHTS = 'insights',
  PROFILE = 'profile',
  COMPANY_PROFILE = 'companyProfile',
  COMPANY_PROFILE_MONITOR_DEVICE = 'companyProfileMonitorDevice',
  TERMS_OF_SERVICE = 'termsOfService',
  LOGOUT = 'logout',
  COMPANY_PROFILE_USE_CASE = 'companyProfileUseCase',
}

export const pagesList = [pagesEnum.DASHBOARD, pagesEnum.CONTROL_ROOM, pagesEnum.REPORTS, pagesEnum.INSIGHTS];

export type unionValue = string | number;
export interface selectBoxOptions {
  [key: string]: unionValue;
}
export type infoBox = {
  title: string;
  text: string;
  linkTo: pagesEnum;
};

export type UItab = {
  key: string;
  title: string;
  isActive: boolean;
};

export type dataGraph = {
  id: string;
  label: string;
  value: number;
};

export const graphContext = createContext({});

//export const sevirityColors = ['#BD0003', '#ff0000', '#FA9D00', '#FCE700', '#B1BEC9'];
export const sevirityColors = ['#B1BEC9', '#FCE700', '#FA9D00', '#ff0000', '#BD0003'];
export const sevirityColorsSLA = [ '#FCE700', '#FA9D00', '#ff0000', '#BD0003'];
export const statusColors = ['#00e676', '#ff8c40', '#24e3ad', '#ffe700', '#505d6f'];
export const statusColorsObj = {
  open: '#00e676',
  'in progress': '#ff8c40',
  'tuning': '#24e3ad',
  waiting: '#ffe700',
  close: '#000'
};
export const categoryColors = ['#5f40f7', '#826af9', '#9e86ff', '#d0aeff', '#f7d2ff'];
export const summaryColors = ['#0069cc', '#1a90ff', '#47a6ff', '#83cfff', '#a5f3ff'];

export enum lang_prefix {
  INCIDENTS_PROPERTY = 'incidents.property.',
  INCIDENTS_STATUS = 'incidents.status.',
  INCIDENTS_SEVIRITY = 'incidents.sevirity.',
  CONTROLROOM_FILTER = 'control_room.filter.',
  API_FILTER_DAYS = 'api.filter.days.',
  API_FILTER_TITLES = 'api.filter.titles.',
  API_TIMING = 'api.timing.',
  API_GLOBAL = 'api.global.',
  API_TITLE = 'api.title.',
  API_NAV_TITLE = 'api.navigation.title.',
  TASK_STATUS = 'task.status.',
  CONTACT_LABELS = 'contact.label.',
  PROFILE_TITLES = 'profile.label.'
}

export const translation = {
  'api.global.not_available': 'n/a',

  'api.navigation.title.dashboard': 'Dashboard',
  'api.navigation.title.controlRoom': 'Control Room',
  'api.navigation.title.reports': 'Reports',
  'api.navigation.title.insights': 'Insights',

  'api.title.waiting_for_you': 'Waiting for you',
  'api.title.priority': 'Priority',
  'api.title.status': 'Status',
  'api.title.sort_by': 'Sort By',
  'api.title.live_incidents': 'Live Incidents',
  'api.title.last_modified': 'Last modified',
  'api.title.open_tasks': 'Opened Tasks',

  'api.filter.days.3': 'Last 3 Days',
  'api.filter.days.7': 'Last 7 Days',
  'api.filter.days.14': 'Last 14 Days',
  'api.filter.days.30': 'Last 30 Days',
  'api.filter.days.90': 'Last 90 Days',
  'api.filter.titles.all_closing_reason': 'Reason',
  'api.filter.titles.all_tasks': 'Status',
  'api.filter.titles.all_columns': 'Filter',

  'api.timing.yesterday': 'Yesterday',
  'api.timing.today': 'Today',

  'incidents.property.source_ip': 'Source IP',
  'incidents.property.destination_ip': 'Destination IP',
  'incidents.property.source_geo': 'Source Geo',
  'incidents.property.destination_geo': 'Destination Geo',
  'incidents.property.source_user': 'Source User',
  'incidents.property.destination_user': 'Destination User',
  'incidents.property.origin': 'Origin',
  'incidents.property.severity': 'Severity',
  'incidents.property.external_id': 'Incident ID',
  'incidents.property.id': 'Internal ID',
  'incidents.property.mitre_tactic': 'MITRE Tactic',
  'incidents.property.host_name': 'Host Name',
  'incidents.property.created_at': 'Created time',
  'incidents.property.category_name': 'Category',
  'incidents.property.title': 'Name',
  'incidents.property.summary': 'Summary',
  'incidents.property.description': 'Description',
  'incidents.property.investigation': 'Investigation',
  'incidents.property.closing_reason': 'Reason',
  'incidents.property.owner': 'Owner',
  'incidents.property.status': 'Status',
  'incidents.property.tasks': 'Tasks',

  'incidents.status.all_status': 'Status',
  'incidents.status.close': 'Close',
  'incidents.status.open': 'Open',
  'incidents.status.in progress': 'In Progress',
  'incidents.status.tuning': 'Tuning',
  'incidents.status.waiting': 'Waiting',

  'incidents.sevirity.all_severity': 'Severity',
  'incidents.sevirity.critical': 'Critical',
  'incidents.sevirity.high': 'High',
  'incidents.sevirity.medium': 'Medium',
  'incidents.sevirity.low': 'Low',
  'incidents.sevirity.info': 'Info',

  'task.status.close': 'Close',
  'task.status.open': 'Open',
  'task.status.in progress': 'In Progress',
  'task.status.all_tasks': 'Task Status',

  'control_room.filter.all_owner': 'Owner',

  'profile.label.profile': 'My profile',
  'profile.label.photo': 'Your photo',
  'profile.label.last_login': 'Last connected',
  'profile.label.name': 'Full name',
  'profile.label.role': 'Role',
  'profile.label.company': 'Company',

  'contact.label.title': 'Title',
  'contact.label.description': 'Description'
};
