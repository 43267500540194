import React from 'react';
import _ from 'lodash';

export const Button: React.FC<{ text: string; style?: string; callback?: any }> = (props): JSX.Element => {
  return (
    <div onClick={props?.callback} className={`button button-action${props?.style ? ' ' + props?.style : ''}`}>
      {props.text}
    </div>
  );
};

export const Badge: React.FC<{ text: string; class?: string }> = (props): JSX.Element => {
  return <div className={`badge${props?.class ? ' ' + props?.class : ''}`}>{props.text}</div>;
};

export const Checkbox: React.FC<{ onToggle: any }> = (props): JSX.Element => {
  return <input onChange={(e) => props.onToggle(e.target.checked)} type='checkbox' />;
};

export const FlexSpaceBetween: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`flex-space-between${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>;
};

export const ParagraphTitle: React.FC<{ children: any; style?: string }> = (props) => {
  return (
    <div className={`paragraph-headline-title${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>
  );
};

export const ParagraphTitleText: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`title${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>;
};

export const SubParagraphTitle: React.FC<{ children: string }> = (props) => {
  return <div className='sub-paragraph-headline-title'>{props.children}</div>;
};

export const ParagraphText: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`paragraph-text${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>;
};

export const H1: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`h1${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>;
};

export const LabelText: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`label-text${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>;
};

export const Caption: React.FC<{ children: any; style?: string; onClick?: any }> = (props) => {
  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={`caption${_.isEmpty(props.style) ? '' : ' ' + props.style}${props.onClick ? ' link' : ''}`}
    >
      {props.children}
    </div>
  );
};

export const RegularText: React.FC<{ children: any; style?: string; onClick?: any }> = (props) => {
  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={`regular-text${_.isEmpty(props.style) ? '' : ' ' + props.style}${props.onClick ? ' link' : ''}`}
    >
      {props.children}
    </div>
  );
};

export const TinyText: React.FC<{ children: any; style?: string; onClick?: any }> = (props) => {
  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={`tiny-text${_.isEmpty(props.style) ? '' : ' ' + props.style}${props.onClick ? ' link' : ''}`}
    >
      {props.children}
    </div>
  );
};

export const Space: React.FC = () => {
  return <span>&nbsp;</span>;
};

export const Flex: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`flex${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>{props.children}</div>;
};

export const VerticalContentMiddle: React.FC<{ children: any; style?: string }> = (props) => {
  return <div className={`vertical-content${_.isEmpty(props.style) ? '' : ' ' + props.style}`}>
    <div className='vertical-middle'>{props.children}</div>
    </div>;
};

export const LineSeparator: React.FC = () => {
  return <div className='line-separator'></div>;
};
