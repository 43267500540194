import React from 'react';
import { useAppSelector } from 'hooks/redux.hooks';

import { insightStatus } from 'constants/insights.constants';
import { LIGHT_CHECK } from 'assets/icons/General';

export const Completed: React.FC<{}> = (): JSX.Element => {
  const selectedInsight = useAppSelector((state) => state.insights.selected);

  return (
    <>
      {selectedInsight?.status === insightStatus.FINISHED && (
        <div className='selected-insight-due-date'>
          <div className='text'>
            <LIGHT_CHECK />
            <p>
              <>Completed on {selectedInsight?.due_date}</>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
