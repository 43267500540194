import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useMsal } from '@azure/msal-react';

import { logoutUser } from 'services/auth.service';
import { pagesEnum } from 'constants/ui.constants';
import { useAppSelector } from 'hooks/redux.hooks';
import * as ICONS from 'assets/icons/General';
import { togglePage } from 'state/features/ui.features';
import { store } from 'state/store';

export const ProfileMenu: React.FC<{ handleShow: any }> = ({ handleShow }) => {
  const avatar = useAppSelector((state) => state.company.details.url);
  const sas = useAppSelector((state) => state.global.SAS);
  const { instance, accounts } = useMsal();
  return (
    <Dropdown>
      <Dropdown.Toggle as='div' id='dropdown-basic'>
        {avatar && avatar !== '' ? (
          <div id='profile-image' style={{backgroundPosition:'center',backgroundSize: 'cover',height:'40px',backgroundImage:'url("'+avatar + sas+ '")'}}></div>
        ) : (
          <ICONS.DEFAULT_AVATAR />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu align='end'>
        <Dropdown.Item
          as='div'
          onClick={() => {
            handleShow('profile-popup');
          }}
        >
          <Dropdown.ItemText className='dropdown-icon'>
            <ICONS.MENU_PROFILE />
          </Dropdown.ItemText>
          <Dropdown.ItemText>My Profile</Dropdown.ItemText>
        </Dropdown.Item>
        <Dropdown.Item
          as='div'
          onClick={() => {
            store.dispatch(togglePage(pagesEnum.COMPANY_PROFILE));
          }}
        >
          <Dropdown.ItemText className='dropdown-icon'>
            <ICONS.HAND_CASE />
          </Dropdown.ItemText>
          <Dropdown.ItemText>Company Profile</Dropdown.ItemText>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as='div'
          onClick={() => {
            store.dispatch(togglePage(pagesEnum.TERMS_OF_SERVICE));
          }}
        >
          <Dropdown.ItemText className='dropdown-icon'>
            <ICONS.TEXT_BOARD />
          </Dropdown.ItemText>
          <Dropdown.ItemText>Terms of Use</Dropdown.ItemText>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as='div'
          onClick={() => {
            logoutUser(instance, accounts);
          }}
        >
          <Dropdown.ItemText className='dropdown-icon'>
            <ICONS.LOGOUT />
          </Dropdown.ItemText>
          <Dropdown.ItemText>Logout</Dropdown.ItemText>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
