import { API_SEND_CONTACT_US_EMAIL } from 'constants/db.constants';
import { setError, setLoading, setSuccess } from 'state/features/contact.features';
import API from '../services/api.service';

export function sendEmail(message: object) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    await API.getInstance()
      .post(API_SEND_CONTACT_US_EMAIL, message)
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setSuccess(true));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setError(error?.data?.error));
      });
  };
}
