import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Insight, userPreference } from 'constants/insights.constants';
interface IUiState {
  loading: boolean;
  error: boolean;
  items: Insight[];
  selected: Insight;
  selectedInsightId: number;
  insightImage: string;
}

const initialState: IUiState = {
  loading: false,
  error: false,
  items: [],
  selected: {},
  selectedInsightId: 0,
  insightImage: ''
};

export const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      const grouped = _.groupBy(payload, 'user_preference');
      Object.values(userPreference).map((preference) => {
        if (_.has(grouped, preference) && _.isEmpty(state.selected)) {
          state.selected = grouped[preference][0];
        }
      });
      state.loading = false;
      state.error = false;
      state.items = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    setSelected: (state, { payload }) => {
      state.selectedInsightId = payload;
      state.selected = state.items.filter((item) => item.id === payload)?.[0];
    },
    updateInsights: (state, { payload }) => {
      state.selected = payload;
      const indexToUpdate = state.items.findIndex((item) => item.id === payload.id);
      const url = state.items[indexToUpdate].url;
      state.items[indexToUpdate] = {...payload, url:url};
    },
    addInsight: (state, { payload }) => {
      state.items.unshift(payload);
    },
    setInsightImage: (state, { payload }) => {
      state.insightImage = payload;
    }
  }
});

export const { setLoading, setItems, setError, setSelected, updateInsights, addInsight, setInsightImage } =
  insightsSlice.actions;

export default insightsSlice.reducer;
