import { API_UPDATE_AVATAR, API_GET_SAS } from 'constants/db.constants';
import { setAvatar } from 'state/features/company.features';
import { setError, setSAS } from 'state/features/global.features';
import API from '../services/api.service';

export function uploadAvatar(data: any) {
  return async (dispatch: any) => {
    API.getInstance()
      .post(API_UPDATE_AVATAR, data)
      .then((response) => {
        try{
          dispatch(setAvatar(response.data.newImage.url))
        }catch(error){}
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function getSAS() {
  return async (dispatch: any) => {
    await API.getInstance()
      .get(API_GET_SAS )
      .then((response) => {
        dispatch(setSAS(response.data));
      })
      .catch((error) => {
        
      });
  };
}