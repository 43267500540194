import React from "react";

export const ENDPOINT_THREAT: React.FC = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z"/>
                <path d="M9 9V8a3 3 0 0 1 6 0v1M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1-10 0v-3a6 6 0 0 1 1-3M3 13h4M17 13h4M12 20v-6M4 19l3.35-2M20 19l-3.35-2M4 7l3.75 2.4M20 7l-3.75 2.4" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    )
}

export const UNCATEGORIZED: React.FC = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z"/>
                <g>
                    <path d="M0 0h24v24H0z"/>
                    <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="6.5" cy="6.5" r="4"/>
                    <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="6.5" cy="17.5" r="4"/>
                    <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="17.5" cy="6.5" r="4"/>
                    <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="17.5" cy="17.5" r="4"/>
                </g>
            </g>
        </svg>
    )
}

export const OT_SECURITY: React.FC = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z"/>
                <g transform="translate(1 1)" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="3" y="3" width="16" height="16" rx="2"/>
                    <path d="M8 8h6v6H8zM8 0v3M14 0v3M8 19v3M14 19v3M19 8h3M19 13h3M0 8h3M0 13h3"/>
                </g>
            </g>
        </svg>
    )
}

export const SABOTAGE_DESCRUTION: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path d="M0 0h24v24H0z"/>
        <path d="m13 9 7.383 7.418c.823.82.823 2.148 0 2.967a2.11 2.11 0 0 1-2.976 0L10 12" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="m6.66 15.66-3.32-3.32a1.25 1.25 0 0 1 .42-2.044L7 9l6-6 3 3-6 6-1.296 3.24a1.25 1.25 0 0 1-2.044.42h0z" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
</svg>
    )
}

export const DDOS: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <g>
            <path d="M0 0h24v24H0z"/>
            <path d="M4 13a8 8 0 0 1 7 7 6 6 0 0 0 3-5 9 9 0 0 0 6-8 3 3 0 0 0-3-3 9 9 0 0 0-8 6 6 6 0 0 0-5 3" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 14a6 6 0 0 0-3 6 6 6 0 0 0 6-3" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
            <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="15" cy="9" r="1"/>
        </g>
    </g>
</svg>
    )
}

export const ASSETS_STATUS: React.FC = () => {
    return(
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z"/>
            <path d="M6.982 21.108c-.945.945-2.52.909-3.51-.081-.989-.99-1.025-2.564-.08-3.51l3.91-3.909 1.371.228a.216.216 0 0 0 .25-.25l-.229-1.37 1.171-1.171 3.59 3.59-1.17 1.17-1.372-.228a.216.216 0 0 0-.19.06.218.218 0 0 0-.06.19l.229 1.37-3.91 3.91zM17.39 8.44a.214.214 0 0 0-.123.06l-4.66 4.661-1.267-1.267 4.66-4.66a.214.214 0 0 0 .06-.123l.26-1.935 3.326-2.086 1.764 1.765-2.085 3.326-1.935.26zm-6.033 8.816-.197-1.184 1.184.198c.07.011.14-.01.19-.06l1.405-1.405a.226.226 0 0 0-.008-.319l-.998-.999 4.61-4.61 1.962-.262a.213.213 0 0 0 .152-.098l2.233-3.56a.228.228 0 0 0-.037-.282l-2.027-2.028a.228.228 0 0 0-.282-.037l-3.56 2.234a.213.213 0 0 0-.098.152l-.263 1.962-4.61 4.61-.998-.998a.226.226 0 0 0-.32-.008L8.29 11.967a.218.218 0 0 0-.06.19l.198 1.184-1.184-.197a.217.217 0 0 0-.19.06l-3.988 3.988c-1.117 1.117-1.075 2.977.095 4.147 1.17 1.17 3.03 1.212 4.147.095l3.988-3.988c.05-.049.072-.12.06-.19z" stroke="#2D99FF" strokeWidth=".45" fill="#2D99FF"/>
        </g>
    </svg>
    )
}

export const CTI: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path d="M13.241 5c.22 0 .4.177.4.395v1.001h3.21c.945 0 1.841.45 2.4 1.203l2.626 3.544A4.743 4.743 0 0 1 23 14.208C23 16.85 20.827 19 18.156 19a4.856 4.856 0 0 1-4.079-2.21c-.374.312-1.03.662-2.077.662-1.048 0-1.703-.35-2.08-.664A4.847 4.847 0 0 1 5.844 19C3.173 19 1 16.85 1 14.208a4.739 4.739 0 0 1 1.123-3.065L4.75 7.599a2.995 2.995 0 0 1 2.399-1.203h3.21V5.395c0-.218.18-.395.4-.395h2.482zm-7.397 5.206c-2.23 0-4.046 1.795-4.046 4.002s1.815 4.002 4.046 4.002c2.23 0 4.045-1.795 4.045-4.002s-1.815-4.002-4.045-4.002zm12.312 0c-2.23 0-4.045 1.795-4.045 4.002s1.815 4.002 4.045 4.002 4.046-1.795 4.046-4.002-1.815-4.002-4.046-4.002zM12.842 5.79h-1.684V6.79a.397.397 0 0 1-.4.395h-3.61c-.69 0-1.346.328-1.754.88L4.174 9.71a4.868 4.868 0 0 1 1.67-.293c2.67 0 4.843 2.15 4.843 4.79 0 .66-.135 1.29-.38 1.862.202.214.7.593 1.693.593.994 0 1.492-.38 1.694-.594a4.7 4.7 0 0 1-.38-1.86c0-2.642 2.172-4.791 4.842-4.791.587 0 1.149.103 1.67.293l-1.22-1.644a2.189 2.189 0 0 0-1.754-.88h-3.61c-.221 0-.4-.177-.4-.395V5.79z" stroke="#2D99FF" strokeWidth=".2" fill="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
</svg>
    )
}

export const ACTION_ON_OBJECTIVES: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <circle stroke="#2D99FF" fill="#2D99FF" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round" cx="12" cy="12" r="1"/>
        <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="12" cy="12" r="7"/>
        <path stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" d="M12 3v2M3 12h2M12 19v2M19 12h2"/>
    </g>
</svg>

    )
}

export const REONNAISSANCE: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <g transform="translate(3 3)" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="8" cy="8" r="8"/>
            <path d="m18 18-4.35-4.35"/>
        </g>
    </g>
</svg>
    )
}

export const MALICIOS_DELIVERY: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path d="M14 3v4a1 1 0 0 0 1 1h4" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM12 17h.01M12 11v3" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
</svg>
    )
}

export const COMPLIANCE: React.FC = () => {
    return(
<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <g>
            <path d="M0 0h24v24H0z"/>
            <path d="M14 3v4a1 1 0 0 0 1 1h4" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 8V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2h-5" stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round"/>
            <circle stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" cx="6" cy="14" r="3"/>
            <path stroke="#2D99FF" strokeLinecap="round" strokeLinejoin="round" d="M4.5 17 3 22l3-1.5L9 22l-1.5-5"/>
        </g>
    </g>
</svg>

    )
}

export const COMMAND_CONTROL: React.FC = () => {
    return(
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="7.0-Reports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-14">
            <g fill="#2D99FF">
                <polygon id="Rectangle" points="2 23 20 23 20 24 2 24"></polygon>
                <polygon id="Rectangle-Copy-3" points="6 9 15 9 15 10 6 10"></polygon>
                <polygon id="Rectangle-Copy-5" points="6 4 15 4 15 5 6 5"></polygon>
                <g id="Group-12">
                    <polygon id="Rectangle-Copy-7" points="-5.48450174e-13 0 5 2 5 3 -5.48450174e-13 1"></polygon>
                    <polygon id="Rectangle-Copy-7" points="-5.48450174e-13 3 5 5 5 6 -5.48450174e-13 4"></polygon>
                </g>
                <g id="Group-12">
                    <polygon id="Rectangle-Copy-7" points="-5.48450174e-13 0 5 2 5 3 -5.48450174e-13 1"></polygon>
                    <polygon id="Rectangle-Copy-7" points="-5.48450174e-13 3 5 5 5 6 -5.48450174e-13 4"></polygon>
                </g>
                <polygon id="Rectangle-Copy-3" points="6 4 7 4 7 9 6 9"></polygon>
                <polygon id="Rectangle-Copy-6" points="10 0 11 0 7 4 6 4"></polygon>
                <polygon id="Rectangle-Copy-6" points="14 0 15 0 11 4 10 4"></polygon>
                <polygon id="Rectangle-Copy-4" points="14 4 15 4 15 9 14 9"></polygon>
                <polygon id="Rectangle" points="6 9 7 9 5 23 4 23"></polygon>
                <polygon id="Rectangle-Copy-2" points="14 9 15 9 17 23 16 23"></polygon>
            </g>
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        </g>
    </g>
</svg>
    )}