import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';

export const InfoBox: React.FC<{title:string,label:string}> = ({title,label}) => {
    return (
        <View style={styles.infoBox}>
        <Text style={{fontSize:'15px'}}>{title}</Text>
        <Text style={{fontSize:'10px',padding:'4px 0'}}>{label}</Text>
    </View>
    )
}