import * as ICONS from 'assets/icons/General';

export const cancelText = 'Cancel request';
export const inProgress = 'In progress';
export const progressDone = 'finished';

export enum insightStatus {
  NEW_RECOMMENDATION = 'new recommendation',
  WAITING_FOR_WORK = 'waiting for work',
  AT_WORK = 'at work',
  FINISHED = 'finished'
}

export enum userPreference {
  RECOMMENDED_FOR_YOU = 'recommended for you',
  INTERESTED = 'interested',
  WE_GOT_ONE = 'we got one',
  MAYBE_LATER = 'maybe later'
}

export const addFormFields = [
  { label: 'Insight title', name: 'insightTitle', type: 'text' },
  { label: 'Insight description', name: 'insightDescription', type: 'text' },
  { label: 'Priority', name: 'insightPriority', type: 'select' }
];

export const galleryButtons = [
  { title: userPreference.INTERESTED, icon: ICONS.THUMBS_UP },
  { title: userPreference.WE_GOT_ONE, icon: ICONS.CHECK },
  { title: userPreference.MAYBE_LATER, icon: ICONS.CLOCK }
];

export interface Insight {
  id: number;
  title: string;
  status: insightStatus;
  user_preference: userPreference;
  priority?: string;
  summary: string;
  description: string;
  is_relevant: boolean;
  created: Date;
  due_date: Date;
  updated_at: Date;
  url: string;
}
