import React from 'react';

export const SEARCH_ICON: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7422 10.1818H10.9824L10.7135 9.92343C11.509 8.98821 12 7.77865 12 6.45455C12 2.89426 9.31373 0 6 0C2.68627 0 0 2.89426 0 6.45455C0 10.0148 2.68627 12.9091 6 12.9091C7.30392 12.9091 8.5049 12.4886 9.47021 11.7843L9.73828 12.0341V12.8182L14.634 17.8182L16 16.5455L11.7422 10.1818ZM6 10.1818C3.69412 10.1818 1.82745 8.23292 1.82745 6.45455C1.82745 4.67619 3.69412 2.72727 6 2.72727C8.30588 2.72727 10.1725 4.67619 10.1725 6.45455C10.1725 8.23292 8.30588 10.1818 6 10.1818Z" fill="#aaa"/>
  </svg>
  );
};

export const CHECK: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M19.646 5.646a.5.5 0 0 1 .765.638l-.057.07-11 11a.5.5 0 0 1-.638.057l-.07-.057-5-5a.5.5 0 0 1 .638-.765l.07.057L9 16.293 19.646 5.646z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const BLUE_TICK: React.FC = () => {
  return (
<svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.5 1.25L5.25 9.5L1.5 5.75" stroke="#2D99FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  );
};

export const OPEN_COMMENTS: React.FC = () => {
  return (
<svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" fill="#2D99FF"></path>
</svg>
  );
};

export const CLOSE_COMMENTS: React.FC = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.484 16.452c-0.436 0.446-1.043 0.481-1.576 0l-3.908-3.747-3.908 3.747c-0.533 0.481-1.141 0.446-1.574 0-0.436-0.445-0.408-1.197 0-1.615 0.406-0.418 4.695-4.502 4.695-4.502 0.217-0.223 0.502-0.335 0.787-0.335s0.57 0.112 0.789 0.335c0 0 4.287 4.084 4.695 4.502s0.436 1.17 0 1.615z" fill="#2D99FF"></path>
    </svg>
  );
};

export const LIGHT_CHECK: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M19.646 5.646a.5.5 0 0 1 .765.638l-.057.07-11 11a.5.5 0 0 1-.638.057l-.07-.057-5-5a.5.5 0 0 1 .638-.765l.07.057L9 16.293 19.646 5.646z'
          fill='#FFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const TIMELINE_CHECK: React.FC = () => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.74998 4.36623L1.10348 2.71973L0.396484 3.42673L2.74998 5.78023L7.60348 0.926726L6.89648 0.219727L2.74998 4.36623Z" fill="white"/>
    </svg>
  );
};


export const DARK_TIMELINE_CHECK: React.FC = () => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.74998 4.36623L1.10348 2.71973L0.396484 3.42673L2.74998 5.78023L7.60348 0.926726L6.89648 0.219727L2.74998 4.36623Z" fill="#1C1E24"/>
    </svg>
  );
};


export const CLOCK: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M12 1.5c5.799 0 10.5 4.701 10.5 10.5S17.799 22.5 12 22.5 1.5 17.799 1.5 12 6.201 1.5 12 1.5zm0 1a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zm0 3a.5.5 0 0 1 .492.41L12.5 6v5.691l3.724 1.862a.5.5 0 0 1 .256.587l-.033.084a.5.5 0 0 1-.587.256l-.084-.033-4-2a.5.5 0 0 1-.267-.354L11.5 12V6a.5.5 0 0 1 .5-.5z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const THUMBS_UP: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M4 22.5a2.5 2.5 0 0 1-2.495-2.336L1.5 20v-7a2.5 2.5 0 0 1 2.336-2.495L4 10.5h2.675l3.868-8.703a.5.5 0 0 1 .374-.29L11 1.5A3.5 3.5 0 0 1 14.5 5v3.5h5.154a2.5 2.5 0 0 1 1.79.726l.125.133a2.5 2.5 0 0 1 .585 2.017l-1.38 8.999A2.499 2.499 0 0 1 18.28 22.5H4zm2.499-1v-10H4a1.5 1.5 0 0 0-1.493 1.356L2.5 13v7a1.5 1.5 0 0 0 1.356 1.493L4 21.5h2.499zm4.816-18.98L7.5 11.104V21.5h10.786a1.5 1.5 0 0 0 1.473-1.14l.027-.136 1.38-8.999A1.5 1.5 0 0 0 19.66 9.5H14a.5.5 0 0 1-.5-.5V5a2.5 2.5 0 0 0-2.174-2.479l-.011-.001z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const LOGO: React.FC = () => {
  return (
    <svg width='108' height='16' viewBox='0 0 108 16' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M25.067 15.998V2.945h-4.75V.002h13.146v2.943h-4.772v13.053zM33.892 15.998V4.001h3.263v2.707c.585-2.063 1.598-2.707 3.128-2.707.315 0 .856.068 1.081.16v3.051L40.15 7.19c-1.71 0-2.814 1.24-2.814 3.395V16h-3.443v-.002zM42.804 11.899V4.001h3.444v7.234c0 1.33.496 2.038 1.666 2.038 1.306 0 2.161-.908 2.161-2.548V4.001h3.444v11.997h-3.265v-1.95c-.607 1.108-1.599 1.95-3.443 1.95-2.656 0-4.007-1.33-4.007-4.099M55.05 12.097h3.355c.134 1.108.674 1.706 2.206 1.706 1.44 0 2.026-.554 2.026-1.264 0-.642-.54-.998-1.463-1.13l-1.847-.244c-2.927-.42-3.961-1.495-3.961-3.489 0-2.105 1.576-3.677 5.2-3.677 3.625 0 5.2 1.574 5.2 3.723H62.41c-.111-.842-.45-1.53-1.891-1.53-1.237 0-1.71.488-1.71 1.196 0 .62.337 1.03 1.102 1.142l1.823.244c2.769.398 4.345 1.24 4.345 3.545 0 2.105-1.688 3.677-5.515 3.677-3.824 0-5.514-1.572-5.514-3.899M68.378 12.297V6.37h-1.755V4h1.755V0h3.421v3.999h2.387V6.37h-2.385v6.059c0 .798.36 1.086 1.17 1.086h1.215v2.26c-.518.043-1.53.131-2.07.131-2.836.002-3.738-.884-3.738-3.61M83.034 15.998V8.886c0-1.33-.496-2.038-1.666-2.038-1.306 0-2.162.908-2.162 2.548v6.602h-3.443V4.001h3.263v2.071c.607-1.107 1.598-1.949 3.443-1.949 2.657 0 4.006 1.33 4.006 4.099v7.776h-3.441zM91.453 8.52h4.997c-.247-1.462-1.103-2.26-2.498-2.26-1.396 0-2.251.798-2.5 2.26M88.01 9.938c0-3.524 2.117-5.939 5.943-5.939 3.827 0 5.942 2.437 5.942 5.849 0 .31-.022.553-.067.841h-8.374c.067 1.928 1.036 3.048 2.633 3.048 1.215 0 2.07-.62 2.387-1.64h3.173c-.496 2.37-2.318 3.899-5.56 3.899-3.893.002-6.077-2.491-6.077-6.058M102.192 12.297V6.37h-1.756V4h1.756V0h3.42v3.999H108V6.37h-2.387v6.059c0 .798.36 1.086 1.17 1.086h1.215v2.26c-.518.043-1.532.131-2.07.131-2.836.002-3.736-.884-3.736-3.61'
        />
        <ellipse cx='7.983' cy='8' rx='7.043' ry='7.059' />
        <path
          d='M13.052 12.847c-2.72 2.677-7.13 2.677-9.851 0a6.78 6.78 0 0 1 0-9.696c2.72-2.677 7.13-2.677 9.85 0a6.78 6.78 0 0 1 0 9.696m.822-10.504C10.699-.78 5.553-.78 2.38 2.343a7.91 7.91 0 0 0 0 11.312c3.173 3.123 8.32 3.123 11.493 0a7.91 7.91 0 0 0 0-11.312'
          fill='#FFF'
          fillRule='nonzero'
        />
        <path
          d='M8.948 8.808a1.174 1.174 0 0 1-1.641 0 1.13 1.13 0 0 1 0-1.616 1.174 1.174 0 0 1 1.641 0 1.13 1.13 0 0 1 0 1.616M6.486 6.382a2.26 2.26 0 0 0 0 3.232 2.349 2.349 0 0 0 3.283 0 2.26 2.26 0 0 0 0-3.232 2.349 2.349 0 0 0-3.283 0'
          fill='#FFF'
          fillRule='nonzero'
        />
        <path
          d='M8.21 2.33c3.142 0 5.69 2.506 5.69 5.598a5.543 5.543 0 0 1-1.815 4.099l-1.725-1.699a3.17 3.17 0 0 0 1.101-2.4c0-1.767-1.455-3.2-3.25-3.2-.972 0-1.844.42-2.44 1.085L4.047 4.114A5.715 5.715 0 0 1 8.211 2.33z'
          fill='#00B5E2'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const DASHBOARD: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fillRule='nonzero' fill='none'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M8 21.5a.5.5 0 0 1-.09-.992L8 20.5h3.5v-3H4a2.5 2.5 0 0 1-2.495-2.336L1.5 15V5a2.5 2.5 0 0 1 2.336-2.495L4 2.5h16a2.5 2.5 0 0 1 2.495 2.336L22.5 5v10a2.5 2.5 0 0 1-2.336 2.495L20 17.5h-7.5v3H16a.5.5 0 0 1 .09.992L16 21.5H8zm12-18H4a1.5 1.5 0 0 0-1.493 1.356L2.5 5v10a1.5 1.5 0 0 0 1.356 1.493L4 16.5h16a1.5 1.5 0 0 0 1.493-1.356L21.5 15V5A1.5 1.5 0 0 0 20 3.5z'
          fill='#0F1215'
        />
      </g>
    </svg>
  );
};

export const CONTROL_ROOM: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M8.526 2.842a.5.5 0 0 1 .912-.085l.036.085L15 19.417l2.527-7.575a.5.5 0 0 1 .384-.334L18 11.5h4a.5.5 0 0 1 .09.992L22 12.5h-3.64l-2.886 8.658a.5.5 0 0 1-.912.085l-.036-.085L9 4.582l-2.526 7.576a.5.5 0 0 1-.384.334L6 12.5H2a.5.5 0 0 1-.09-.992L2 11.5h3.639l2.887-8.658z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const REPORTS: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M17.5 9.5a.5.5 0 0 1 .492.41L18 10v10a.5.5 0 0 1-.992.09L17 20V10a.5.5 0 0 1 .5-.5zm-6-6a.5.5 0 0 1 .492.41L12 4v16a.5.5 0 0 1-.992.09L11 20V4a.5.5 0 0 1 .5-.5zm-6 10a.5.5 0 0 1 .492.41L6 14v6a.5.5 0 0 1-.992.09L5 20v-6a.5.5 0 0 1 .5-.5z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const INSIGHTS: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='m12.616 1.68-10 12-.048.067A.5.5 0 0 0 3 14.5h8.433l-.93 7.438c-.061.495.561.766.881.382l10-12 .048-.067A.5.5 0 0 0 21 9.5h-8.434l.93-7.438c.062-.495-.56-.766-.88-.382zM12.29 3.63l-.787 6.307-.003.088A.5.5 0 0 0 12 10.5h7.931l-8.223 9.868.788-6.306.003-.088A.5.5 0 0 0 12 13.5H4.067l8.224-9.869z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const MOON: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M11.126 2.502A9.5 9.5 0 1 0 21.46 12.836a.5.5 0 0 0-.795-.448 6.5 6.5 0 0 1-9.091-9.09.5.5 0 0 0-.448-.796zm-.959 1.159-.107.21a7.502 7.502 0 0 0 1.438 8.593l.2.193a7.502 7.502 0 0 0 8.392 1.245l.21-.108-.047.208a8.502 8.502 0 0 1-16.749-1.78A8.502 8.502 0 0 1 9.96 3.71l.207-.048z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const CHAT: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M13.028 2.5H12.5a8.88 8.88 0 0 0-4.025.954A9 9 0 0 0 3.5 11.5l.006.349a8.88 8.88 0 0 0 .796 3.362l.059.122-1.835 5.509-.02.078a.5.5 0 0 0 .652.554l5.508-1.836-.191-.092a8.88 8.88 0 0 0 4.026.954 9 9 0 0 0 8.046-4.976 8.879 8.879 0 0 0 .953-4.025V11c-.253-4.598-3.902-8.247-8.472-8.5zm-.53 1h.474a7.98 7.98 0 0 1 7.529 7.528l-.001.472a7.882 7.882 0 0 1-.846 3.575A8 8 0 0 1 12.5 19.5a7.881 7.881 0 0 1-3.575-.846l-.092-.036a.5.5 0 0 0-.291.008L3.79 20.209l1.584-4.75a.5.5 0 0 0-.028-.384A7.88 7.88 0 0 1 4.5 11.5a8 8 0 0 1 4.424-7.154 7.883 7.883 0 0 1 3.575-.847z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const NOTIFICATIONS: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M12 1.5A6.5 6.5 0 0 1 18.5 8c0 2.981.543 5.243 1.439 6.886.307.563.633 1.008.955 1.348l.167.168c.1.094.174.154.216.182.386.257.24.837-.188.909L21 17.5H3a.5.5 0 0 1-.277-.916 2.68 2.68 0 0 0 .383-.35c.322-.34.648-.785.955-1.348.851-1.561 1.384-3.68 1.435-6.444L5.5 8A6.5 6.5 0 0 1 12 1.5zm0 1a5.5 5.5 0 0 0-5.496 5.279L6.5 8c0 3.144-.582 5.57-1.561 7.364a8.67 8.67 0 0 1-.643 1.016l-.093.12h15.593l-.092-.12a8.348 8.348 0 0 1-.514-.786l-.129-.23c-.93-1.704-1.502-3.98-1.557-6.898L17.5 8l-.004-.221A5.5 5.5 0 0 0 12 2.5zm1.297 18.25a.5.5 0 1 1 .865.5 2.5 2.5 0 0 1-4.325 0 .5.5 0 1 1 .866-.5 1.5 1.5 0 0 0 2.595 0z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const DEFAULT_AVATAR: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M16 14.5a4.5 4.5 0 0 1 4.495 4.288L20.5 19v2a.5.5 0 0 1-.992.09L19.5 21v-2a3.5 3.5 0 0 0-3.308-3.495L16 15.5H8a3.5 3.5 0 0 0-3.495 3.308L4.5 19v2a.5.5 0 0 1-.992.09L3.5 21v-2a4.5 4.5 0 0 1 4.288-4.495L8 14.5h8zm-4-12a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9zm0 1a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const CHEVRON_RIGHT: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M8.646 5.646a.5.5 0 0 1 .638-.057l.07.057 6 6a.5.5 0 0 1 .057.638l-.057.07-6 6a.5.5 0 0 1-.765-.638l.057-.07L14.293 12 8.646 6.354a.5.5 0 0 1-.057-.638l.057-.07z'
          fill='#00B5E2'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const CHEVRON_LEFT: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M13.354 5.646a.5.5 0 0 0-.638-.057l-.07.057-6 6a.5.5 0 0 0-.057.638l.057.07 6 6a.5.5 0 0 0 .765-.638l-.057-.07L7.707 12l5.647-5.646a.5.5 0 0 0 .057-.638l-.057-.07z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const INSIGHTS_DEFAULT: React.FC = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 .5C16.799.5 21.5 5.201 21.5 11S16.799 21.5 11 21.5.5 16.799.5 11 5.201.5 11 .5zm0 1a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zm0 3a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13zm0 1a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11zm0 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z'
        fill='#FFF'
        fillRule='nonzero'
      />
    </svg>
  );
};

export const NOTIFICATIONS_ICON: React.FC = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 .5C16.799.5 21.5 5.201 21.5 11S16.799 21.5 11 21.5.5 16.799.5 11 5.201.5 11 .5zm0 1a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zm0 3a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13zm0 1a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11zm0 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z'
        fill='#2d99ff'
        fillRule='nonzero'
      />
    </svg>
  );
};

export const MINIMIZE: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M10 13.5h.012c.02 0 .041.002.062.005L10 13.5a.502.502 0 0 1 .354.146l.011.013a.503.503 0 0 1 .033.039l-.044-.052a.502.502 0 0 1 .138.264l.003.018a.503.503 0 0 1 .005.052V20a.5.5 0 0 1-.992.09L9.5 20v-4.794l-6.146 6.148a.5.5 0 0 1-.765-.638l.057-.07L8.792 14.5H4a.5.5 0 0 1-.492-.41L3.5 14a.5.5 0 0 1 .41-.492L4 13.5h6zM21.354 2.646a.5.5 0 0 1 .057.638l-.057.07L15.206 9.5H20a.5.5 0 0 1 .492.41l.008.09a.5.5 0 0 1-.41.492L20 10.5h-6.02a.503.503 0 0 1-.052-.005L14 10.5a.502.502 0 0 1-.354-.146l-.011-.013a.503.503 0 0 1-.033-.039l.044.052a.502.502 0 0 1-.138-.264l-.003-.016a.503.503 0 0 1-.005-.062V4a.5.5 0 0 1 .992-.09L14.5 4v4.792l6.146-6.146a.5.5 0 0 1 .708 0z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const MAXIMAIZE: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M21 2.5h.012c.02 0 .041.002.062.005L21 2.5a.502.502 0 0 1 .354.146l.011.013a.503.503 0 0 1 .033.039l-.044-.052a.502.502 0 0 1 .138.264l.003.018a.503.503 0 0 1 .005.052V9a.5.5 0 0 1-.992.09L20.5 9V4.206l-6.146 6.148a.5.5 0 0 1-.765-.638l.057-.07L19.792 3.5H15a.5.5 0 0 1-.492-.41L14.5 3a.5.5 0 0 1 .41-.492L15 2.5h6zM10.354 13.646a.5.5 0 0 1 .057.638l-.057.07L4.206 20.5H9a.5.5 0 0 1 .492.41L9.5 21a.5.5 0 0 1-.41.492L9 21.5H2.98a.503.503 0 0 1-.052-.005L3 21.5a.502.502 0 0 1-.354-.146l-.011-.013a.503.503 0 0 1-.033-.039l.044.052a.502.502 0 0 1-.138-.264l-.003-.016a.503.503 0 0 1-.005-.062V15a.5.5 0 0 1 .992-.09L3.5 15v4.792l6.146-6.146a.5.5 0 0 1 .708 0z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const TRENDING_UP: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M23 5.5h.012c.02 0 .041.002.062.005L23 5.5a.502.502 0 0 1 .354.146l.011.013a.503.503 0 0 1 .033.039l-.044-.052a.502.502 0 0 1 .138.264l.003.018a.503.503 0 0 1 .005.052V12a.5.5 0 0 1-.992.09L22.5 12V7.206l-8.646 8.648a.5.5 0 0 1-.638.057l-.07-.057L8.5 11.207l-7.146 7.147a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1-.057-.638l.057-.07 7.5-7.5a.5.5 0 0 1 .638-.057l.07.057 4.646 4.647L21.792 6.5H17a.5.5 0 0 1-.492-.41L16.5 6a.5.5 0 0 1 .41-.492L17 5.5h6z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const TRENDING_DOWN: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='m1.284 5.589.07.057L8.5 12.793l4.646-4.647a.5.5 0 0 1 .638-.057l.07.057 8.646 8.647V12a.5.5 0 0 1 .41-.492L23 11.5a.5.5 0 0 1 .492.41l.008.09v6.02a.503.503 0 0 1-.005.052L23.5 18a.502.502 0 0 1-.146.354l-.013.011a.503.503 0 0 1-.039.033l.052-.044a.502.502 0 0 1-.264.138l-.018.003a.503.503 0 0 1-.051.005H17a.5.5 0 0 1-.09-.992L17 17.5h4.793L13.5 9.207l-4.646 4.647a.5.5 0 0 1-.638.057l-.07-.057-7.5-7.5a.5.5 0 0 1 .638-.765z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const ARROW_DOWN: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M12 4.5a.5.5 0 0 1 .5.5v12.793l6.146-6.147.07-.057a.5.5 0 0 1 .638.765l-7 7-.013.011a.503.503 0 0 1-.039.033l-.018.013A.497.497 0 0 1 12 19.5l.072-.005a.503.503 0 0 1-.051.005H12a.509.509 0 0 1-.267-.077.498.498 0 0 1-.087-.07l.052.045a.503.503 0 0 1-.04-.033l-.012-.011-7-7-.057-.07a.5.5 0 0 1 .057-.638l.07-.057a.5.5 0 0 1 .638.057l6.146 6.147V5l.008-.09A.5.5 0 0 1 12 4.5z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const ARROW_UP: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M12 4.5h.012c.02 0 .041.002.062.005L12 4.5a.502.502 0 0 1 .284.089l.018.013c.014.01.027.02.04.033l.012.011 7 7a.5.5 0 0 1-.638.765l-.07-.057L12.5 6.206V19a.5.5 0 0 1-.992.09L11.5 19V6.206l-6.146 6.148a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1-.057-.638l.057-.07 7-7 .013-.011a.503.503 0 0 1 .039-.033l-.052.044A.502.502 0 0 1 12 4.5z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export const DOWNLOAD: React.FC = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
      <g transform='translate(-1378 -393)'>
        <path
          d='M1393.75 404.25v3a1.5 1.5 0 0 1-1.5 1.5h-10.5a1.5 1.5 0 0 1-1.5-1.5v-3M1383.25 400.5l3.75 3.75 3.75-3.75M1387 404.25v-9'
          stroke='#FFF'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const HAND_CASE: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='08gkry4j2a'>
          <path d='M1366 0v800H0V0h1366z' />
        </clipPath>
      </defs>
      <g clipPath='url(#08gkry4j2a)' transform='translate(-1118 -117)'>
        <path
          d='M1123 124h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2zM1126 124v-2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M1130 129v.01'
          stroke='#2E384D'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1121 130a20 20 0 0 0 18 0'
          stroke='#2E384D'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const TEXT_BOARD: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='iz0s6lnj7a'>
          <path d='M1366 0v800H0V0h1366z' />
        </clipPath>
      </defs>
      <g clipPath='url(#iz0s6lnj7a)' transform='translate(-1118 -190)'>
        <path
          d='M1127 195h-2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2h-2'
          stroke='#2B3E50'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1129 193h2a2 2 0 1 1 0 4h-2a2 2 0 1 1 0-4zM1127 202h.01M1131 202h2M1127 206h.01M1131 206h2'
          stroke='#2B3E50'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const LOGOUT: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='cvy1ka9cga'>
          <path d='M1366 0v800H0V0h1366z' />
        </clipPath>
      </defs>
      <g clipPath='url(#cvy1ka9cga)' transform='translate(-1118 -255)'>
        <path
          d='M1132 263v-2a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2'
          stroke='#2B3E50'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1125 267h14l-3-3m0 6 3-3'
          stroke='#2B3E50'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const MENU_PROFILE: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='wbycqya2sa'>
          <path d='M1366 0v800H0V0h1366z' />
        </clipPath>
      </defs>
      <g clipPath='url(#wbycqya2sa)' transform='translate(-1118 -77)'>
        <path
          d='M1130 88a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM1124 98v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2'
          stroke='#2B3E50'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const LOADING: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <path d='M1440 0v800H0V0h1440z' />
      </defs>
      <g clipPath='url(#tb36vqd00a)' transform='translate(-782 -144)'>
        <path
          d='M794 146v4M794 162v4M786.93 148.93l2.83 2.83M798.24 160.24l2.83 2.83M784 156h4M800 156h4M786.93 163.07l2.83-2.83M798.24 151.76l2.83-2.83'
          stroke='#FFF'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const KEBAB_MENU: React.FC = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <path d='M1440 0v800H0V0h1440z' />
      </defs>
      <g clipPath='url(#l1z1z0rdqa)' transform='translate(-948 -148)'>
        <path
          d='M956.25 151.75a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM956.25 157a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0zM956.25 162.25a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0z'
          stroke='#FFF'
          strokeWidth='1.125'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const ARROW_BACK: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <path d='M1440 0v1991H0V0h1440z' />
      </defs>
      <g clip-path='url(#ztxosg2mma)' transform='translate(-258 -106)'>
        <path
          d='M263 118h14M263 118l6 6M263 118l6-6'
          stroke='#16161C'
          strokeWidth='1.5'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export const CLOSE: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M17.646 5.646a.5.5 0 0 1 .765.638l-.057.07-12 12a.5.5 0 0 1-.765-.638l.057-.07 12-12z'
          fill='#0F1215'
          fillRule='nonzero'
        />
        <path
          d='M5.646 5.646a.5.5 0 0 1 .638-.057l.07.057 12 12a.5.5 0 0 1-.638.765l-.07-.057-12-12a.5.5 0 0 1 0-.708z'
          fill='#0F1215'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
