import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux.hooks';
import { IncidentStatusUpdate, IncidentSubStatus, MainIncidentStatus } from 'constants/incident.constants';
import { TIMELINE_CHECK, DARK_TIMELINE_CHECK } from 'assets/icons/General';
import _ from 'lodash';
interface TimelineProps {
  items: IncidentStatusUpdate[];
}

const statusDisplayNames: { [key in MainIncidentStatus]: string } = {
  [MainIncidentStatus.OPEN]: 'Time Detected',
  [MainIncidentStatus.TIME_TO_ASSIGN]: 'Time to Assign',
  [MainIncidentStatus.TIME_TO_RESPONSE]: 'Time to Response',
  [MainIncidentStatus.CLOSE]: 'Time to Resolve'
};

const statusOrder: MainIncidentStatus[] = [
  MainIncidentStatus.OPEN,
  MainIncidentStatus.TIME_TO_ASSIGN,
  MainIncidentStatus.TIME_TO_RESPONSE,
  MainIncidentStatus.CLOSE
];

const Timeline: React.FC<TimelineProps> = ({ items }) => {
  const [itemsToShow, setItemsToShow] = useState<
    (IncidentStatusUpdate | { status: MainIncidentStatus; created_at: Date | null; sub_status?: null })[]
  >([]);
  const darkMode = useAppSelector((state) => state.ui.darkMode);
  const [isOldIncidentWithOnlyStartAndFinishDate, setIsOldIncidentWithOnlyStartAndFinishDate] = useState(false);
  const [isOldWithSubStatus, setIsOldWithSubStatus] = useState(false);

  const formatDate = (timestamp: Date): string => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Jerusalem'
    }).format(date);
  };

  useEffect(() => {
    const sortedItems = _.sortBy(items, ['created_at']);
    const mainStatuses = Object.values(MainIncidentStatus);
    const mainItems = sortedItems.filter((item) => !item.sub_status);

    const filteredIncidents = Object.values(
      sortedItems.reduce((acc, incident) => {
          const key = `${incident.status}:${incident.sub_status ?? ''}`;
          const existingIncident = acc[key];
          if (!existingIncident || new Date(incident.created_at) > new Date(existingIncident.created_at)) {
              acc[key] = incident;
          }
  
          return acc;
      }, {} as Record<string, any>)
  );
  
    const missingStatuses = mainStatuses.filter((status) => !mainItems.some((item) => item.status === status));
    const missingItems = missingStatuses.map((status) => ({ status, created_at: null }));

    setIsOldIncidentWithOnlyStartAndFinishDate(false);
    setIsOldWithSubStatus(false);

    let finalItems = [...filteredIncidents, ...missingItems];
    if (filteredIncidents[0]?.is_old_incident) {
      if (filteredIncidents[0]?.status === 'open') {
        finalItems = [...filteredIncidents, ...missingItems];
      } else if (['in progress', 'waiting', 'tuning'].includes(filteredIncidents[0]?.status)) {
        const subItem = {
          ...filteredIncidents[0],
          sub_status: filteredIncidents[0].status,
          status: 'time to response',
          created_at: filteredIncidents[0].updated_at
        };
        missingItems[0].created_at = filteredIncidents[0].created_at;
        missingItems.splice(3, 0, subItem);
        finalItems = [...missingItems];
        setIsOldWithSubStatus(true);
      } else if (filteredIncidents[0]?.status === 'close') {
        const newLastItem = [{ ...filteredIncidents[0], created_at: filteredIncidents[0].updated_at }];
        missingItems[0].created_at = filteredIncidents[0].created_at;
        finalItems = [...missingItems, ...newLastItem];
        setIsOldIncidentWithOnlyStartAndFinishDate(true);
      }
    }
    finalItems = _.sortBy(finalItems, [
      (item) => statusOrder.indexOf(item.status as MainIncidentStatus),
      (item) => item.created_at ? 1 : 0, 
      'created_at' 
    ]);    
    setItemsToShow(finalItems);
  }, [items]);

  const renderLine = (index: number, lineHasColorClass: string) => {
    if (index === itemsToShow.length - 1) {
      return null;
    }

    const nextItem = itemsToShow[index + 1];
    if (nextItem && !nextItem.sub_status) {
      return <div className={`line ${lineHasColorClass}`}></div>;
    }

    if (nextItem && nextItem.sub_status) {
      const subStatusCount = itemsToShow.slice(index + 1).findIndex((item) => !item.sub_status);
      const lineHeight = (subStatusCount + 1) * 35;
      return <div className={`line ${lineHasColorClass}`} style={{ height: `${lineHeight}px` }}></div>;
    }
    return null;
  };

  return (
    <div className='timeline'>
      {itemsToShow.map((item, index) => {
        const hasCurrentDate = item.created_at;
        const nextItemHasDate = index < itemsToShow.length - 1 && itemsToShow[index + 1].created_at;
        const prevItemHasDate = index > 0 && itemsToShow[index - 1].created_at;
        let isChecked: Date | null | boolean = hasCurrentDate;

        if (isOldWithSubStatus || isOldIncidentWithOnlyStartAndFinishDate) {
          isChecked = index !== itemsToShow.length - 1;
        }
        if (isOldIncidentWithOnlyStartAndFinishDate) {
          isChecked = true;
        }

        const conditionalClass =
          !hasCurrentDate && prevItemHasDate && !isOldIncidentWithOnlyStartAndFinishDate && !isOldWithSubStatus
            ? 'next-in-line-circle '
            : '';
        const lineHasColorClass =
          nextItemHasDate ||
          (isChecked && !nextItemHasDate && !isOldIncidentWithOnlyStartAndFinishDate && !isOldWithSubStatus)
            ? 'line-with-color'
            : '';

        const displayStatus = statusDisplayNames[item.status as MainIncidentStatus] || item.status;
        return (
          <div key={index} className={`timeline-item ${item.sub_status ? 'sub-status-item' : ''}`}>
            <div className='timeline-illustration'>
              {item.sub_status ? (
                <div className='timeline-circles'>
                  <div className={`small-checked-circle ${darkMode ? 'dark' : ''}`}>
                  {darkMode ? <DARK_TIMELINE_CHECK /> : <TIMELINE_CHECK />}
                  </div>
                </div>
              ) : (
                <div className='timeline-circles'>
                  {isChecked ? (
                    <div className={`checked-circle ${darkMode ? 'dark' : ''}`}>
                   {darkMode ? <DARK_TIMELINE_CHECK /> : <TIMELINE_CHECK />}
                    </div>
                  ) : (
                    <div className={`circle ${darkMode ? 'dark-circle' : ''} ${conditionalClass}`}>
                      {itemsToShow.length <= 4 || (isOldWithSubStatus && item.status==="open") ? index+1 : statusOrder.length}
                    </div>
                  )}
                  {renderLine(index, lineHasColorClass)}
                </div>
              )}
            </div>
            <div className='timeline-text'>
              {item.sub_status ? (
                <p className={`title sub-title`}>{item.sub_status}</p>
              ) : (
                <p className='title'>{displayStatus}</p>
              )}
              {item.created_at && (
                <p className={`${item.sub_status ? 'sub-date' : 'date'}`}>{formatDate(item.created_at)}</p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
