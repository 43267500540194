import _ from 'lodash';

import {
  Incident,
  IncidentEnum,
  IncidentSeverityOrder,
  IncidentSeverityTitles,
  IncidentsCategories,
  IncidentStatus,
  IncidentStatusOrder,
  IncidentStatusTitles
} from 'constants/incident.constants';
import { dataGraph, timing, sevirityColors, reportsExecutiveCounter } from 'constants/ui.constants';
import { SLA } from 'constants/company.constants';
import {
  format,
  startOfDay,
  subDays,
  endOfDay,
  startOfToday,
  isValid,
  eachMinuteOfInterval,
  differenceInMinutes
} from 'date-fns';

export class GraphsCls {
  incidents: Incident[];
  sla: SLA[];

  constructor() {
    this.incidents = [];
    this.sla = [];
  }

  set setIncidents(incidents: Incident[]) {
    this.incidents = incidents;
  }

  set setSLA(sla: SLA[]) {
    this.sla = sla;
  }

  get categoryLevelSummary(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const ObjCategories = _.groupBy(this.incidents, (item) => {
      return _.get(item, 'category_name') && _.get(item, 'category_name') in IncidentsCategories
        ? _.get(item, 'category_name')
        : 'uncategorized';
    });
    let index = 0;
    for (let key in ObjCategories) {
      dataGraph.push({
        id: IncidentsCategories[key],
        label: IncidentsCategories[key],
        value: ObjCategories[key].length
      });
      index++;
    }
    dataGraph = _.orderBy(dataGraph, ['value'], ['desc']);
    return dataGraph;
  }

  get summaryLevelIncident(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const ObjGrouping = _.groupBy(this.incidents, 'title');
    let index = 1;
    for (let key in ObjGrouping) {
      dataGraph.push({
        id: key,
        label: key,
        value: ObjGrouping[key].length
      });
      index++;
    }
    dataGraph = _.orderBy(dataGraph, ['value'], ['desc']);
    return dataGraph.slice(0, 5);
  }

  get incidentServirity(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const ObjGrouping = _.groupBy(this.incidents, 'severity');
    IncidentSeverityOrder.forEach((sevirity) => {
      const value = _.has(ObjGrouping, sevirity) ? ObjGrouping[sevirity].length : 0;
      dataGraph.push({
        id: sevirity,
        label: IncidentSeverityTitles[sevirity],
        sevirity: value
      });
    });
    return dataGraph;
  }

  get groupedIncidentServirity() {
    let dataGraph: dataGraph[] = [];
    const ObjGrouping = _.groupBy(this.incidents, 'status');
    const severityOrder = [...IncidentSeverityOrder].reverse();
    IncidentStatusOrder.forEach((status) => {
      if (_.has(ObjGrouping, status)) {
        let group = {
          id: status,
          data: []
        };
        ObjGrouping[status].forEach((incident: Incident) => {
          group.data.push({
            y: _.indexOf(severityOrder, incident.severity),
            x: new Date(incident.created_at), //date + 'T' + time,
            incident_id: incident.id,
            summary: incident.title,
            severity: incident.severity
          });
        });
        group.data = _.orderBy(group.data, ['y']);
        dataGraph.push(group);
      }
    });
    return dataGraph;
  }

  get incidentServirityWithStatus(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const ObjGrouping = _.groupBy(this.incidents, 'severity');
    IncidentSeverityOrder.forEach((sevirity) => {
      let dataSevirity = {
        sevirity: IncidentSeverityTitles[sevirity]
      };
      if (ObjGrouping.hasOwnProperty(sevirity)) {
        const ObjStatusGrouping = _.groupBy(ObjGrouping[sevirity], 'status');
        IncidentStatusOrder.forEach((status) => {
          const statusValue = ObjStatusGrouping.hasOwnProperty(status) ? ObjStatusGrouping[status].length : 0;
          dataSevirity[status] = statusValue;
        });
      }
      dataGraph.push(dataSevirity);
    });
    return dataGraph;
  }

  get incidentStatus(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const ObjGrouping = _.groupBy(this.incidents, 'status');
    IncidentStatusOrder.forEach((status) => {
      const value = ObjGrouping.hasOwnProperty(status) ? ObjGrouping[status].length : 0;
      dataGraph.push({
        id: status,
        label: IncidentStatusTitles[status],
        status: value
      });
    });
    return dataGraph;
  }

  get getSLA(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const ObjGrouping = _.groupBy(this.sla, 'severity');
    IncidentSeverityOrder.forEach((sevirity) => {
      const value = ObjGrouping.hasOwnProperty(sevirity) ? ObjGrouping[sevirity][0].response_time_minutes : 0;
      dataGraph.push({
        id: sevirity,
        label: IncidentSeverityTitles[sevirity],
        value: value
      });
    });
    return dataGraph.reverse();
  }

  get getAvrageSLA(): dataGraph[] {
    let dataGraph: dataGraph[] = [];
    const visibleIncidents = _.filter(this.incidents, (incident) => incident.is_visible === true);
    const ObjGrouping = _.groupBy(visibleIncidents, 'severity');
    const newIncidentSeverityOrder = IncidentSeverityOrder.filter((item) => item !== 'info');
    newIncidentSeverityOrder.forEach((sevirity) => {
      let value = 0;
      if (_.has(ObjGrouping, sevirity)) {
        let sumMinutes = 0;
        for (let i = 0; i < ObjGrouping[sevirity].length; i++) {
          const incident = ObjGrouping[sevirity][i];
          let num: number;
          if (incident.sla_assign_minutes) {
            num = incident.sla_assign_minutes;
            sumMinutes += num;
          } else if (!incident.sla_assign_minutes) {
            if (incident.status === IncidentStatus.OPEN) {
              num = differenceInMinutes(new Date(), new Date(ObjGrouping[sevirity][i].created_at));
              sumMinutes += num;
            } else {
              num = differenceInMinutes(
                new Date(ObjGrouping[sevirity][i].updated_at),
                new Date(ObjGrouping[sevirity][i].created_at)
              );
              sumMinutes += Math.abs(num);
            }
          }
        }
        value = Math.round(sumMinutes / ObjGrouping[sevirity].length);
      }
      dataGraph.push({
        id: sevirity,
        label: IncidentSeverityTitles[sevirity],
        value: value
      });
    });
    return dataGraph.reverse();
  }
  get getUniqueDays() {
    let uniques: { [key: string]: string | Date }[] = [];
    let uniqueDays: Date[] = [];
    this.incidents.forEach((incident) => {
      uniques.push({ formatDate: format(new Date(incident.created_at), 'd_MM_yyyy'), created_at: incident.created_at });
    });
    const uniqueDates = _.chain(uniques).orderBy('created_at').groupBy('formatDate').value();
    const uniqueKeys = _.keys(uniqueDates);
    uniqueKeys.forEach((key, index) => {
      let pos = index === uniqueKeys.length - 1 ? uniqueDates[key].length - 1 : 0;
      let uniqueDate = new Date(uniqueDates[key][pos].created_at);
      uniqueDate = index === 0 || index === uniqueKeys.length - 1 ? uniqueDate : startOfDay(uniqueDate);
      uniqueDays.push(uniqueDate);
    });
    return _.sortBy(uniqueDays);
  }

  static getHistoricalData = (range: number, totalIncidents: Incident[]) => {
    let dataGraph: any = [];
    let counter = reportsExecutiveCounter;
    let startCounter = true;
    let startDay: Date, endDay: Date, incidents: Incident[], ObjGrouping: any;
    let order = [...IncidentSeverityOrder];
    order = order.reverse();
    order.forEach((severity, index) => {
      dataGraph.push({
        id: severity,
        color: sevirityColors[index],
        data: []
      });
    });
    while (counter) {
      startDay = subDays(startOfToday(), counter * range - 1);
      endDay = endOfDay(subDays(new Date(), counter * range - range));
      incidents = _.filter(totalIncidents, (incident) => {
        return (
          startDay <= new Date(incident[IncidentEnum.CREATED_AT]) &&
          endDay >= new Date(incident[IncidentEnum.CREATED_AT])
        );
      });

      ObjGrouping = _.groupBy(incidents, IncidentEnum.SEVERITY);
      for (let index = 0; index < order.length; index++) {
        const value = _.has(ObjGrouping, order[index]) ? ObjGrouping[order[index]].length : 0;
        if (startCounter) {
          dataGraph[index].data.push({
            x: format(startDay, timing.DATE),
            y: value
          });
        }
        dataGraph[index].data.push({
          x: format(endDay, timing.DATE),
          y: value
        });
      }

      startCounter = false;
      counter--;
    }
    return dataGraph;
  };
}
