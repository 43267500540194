import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';

import { lang_prefix } from 'constants/ui.constants';
import { _t } from 'components/helpers';
import { fetchUser } from 'state/features/global.features';
import * as ICONS from 'assets/icons/General';
import { LineSeparator } from 'components/Layout/Shared/Elements';
import { toggleShowModal } from 'state/features/ui.features';
import { store } from 'state/store';
import { v4 as uuidv4 } from 'uuid';
import { IMAGE_TYPE } from 'constants/db.constants';
import { uploadAvatar } from 'services/global.service';

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.global.user);
  const companyName = useAppSelector((state) => state.company.details.company_name);
  const translation = useAppSelector((state) => state.company.translation);
  const sas = useAppSelector((state) => state.global.SAS);
  const inputFile = useRef(null);

  function openFileExplorer() {
    inputFile.current.click();
  }

  function handleImageUpload(event: any) {
    const profileImage = document.getElementById('profile-image-temp') as HTMLInputElement;
    profileImage.src = window.URL.createObjectURL(event?.target?.files?.[0]);
    profileImage.style.display = 'block';
    const randomCode = uuidv4();
    const file = event?.target?.files?.[0];
    const formData = new FormData();
    formData.append('avatarImage', file);
    formData.append('randomCode', randomCode);
    formData.append('avatarType', IMAGE_TYPE.USERS);
    formData.append('id', `${user?.id}`);
    dispatch(uploadAvatar(formData));
  }

  return (
    <div className='menu-modal-wrapper profile-modal'>
      <div className='form-header-wrapper'>
        <p className='form-header'>
          <>{_t('profile', translation, lang_prefix.PROFILE_TITLES)}</>
        </p>
      </div>
      <div className='top-part'>
        <div className='image-section'>
          <p className='image-title'>
            <>{_t('photo', translation, lang_prefix.PROFILE_TITLES)}</>
          </p>
          <div className='avatar-management'>
            <div className='logo-profile-background' onClick={openFileExplorer}>
              <img id='profile-image-temp' className='profile-image-temp' src='' alt='Profile image' />
              {user?.url !== '' ? (
                <img id='profile-image' className='profile-image' src={`${user.url + sas}`} alt='Profile image' />
              ) : (
                <ICONS.DEFAULT_AVATAR />
              )}
            </div>
            <p className='upload-text' onClick={openFileExplorer}>
              Upload your photo
            </p>
            <input type='file' ref={inputFile} className='file-input' onChange={handleImageUpload} />
          </div>
        </div>
        <div className='login-section'>
          <p className='image-title'>
            <>{_t('last_login', translation, lang_prefix.PROFILE_TITLES)}</>{' '}
            <>{user?.last_login.slice(0, 19).replace('T', ' ')}</>
          </p>
        </div>
      </div>
      <div className='user-details'>
        <div className='user-details-section'>
          <p className='image-title'>
            <>{_t('name', translation, lang_prefix.PROFILE_TITLES)}</>
          </p>
          <p>{user?.user_name}</p>
          <LineSeparator />
        </div>
        <div className='user-details-section'>
          <p className='image-title'>
            <>{_t('role', translation, lang_prefix.PROFILE_TITLES)}</>
          </p>
          <p>{user?.position}</p>
          <LineSeparator />
        </div>
        <div className='user-details-section'>
          <p className='image-title'>
            <>{_t('company', translation, lang_prefix.PROFILE_TITLES)}</>
          </p>
          <p>{companyName}</p>
        </div>
      </div>
    </div>
  );
};
