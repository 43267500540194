import React from 'react';

import { RegularText } from 'components/Layout/Shared/Elements';
import { LabelText } from 'components/Layout/Shared/Elements';
import { DEFAULT_AVATAR } from 'assets/icons/General';

export const USER_CARD: React.FC<{ role: any; title: string; avatar: string }> = (props): JSX.Element => {
  return (
    <div className={`user-card`}>
      <div className='user-card-avatar'>
        {props.avatar && props.avatar !== '' ? (
          <img className='user-card-image' src={props.avatar} />
        ) : (
          <DEFAULT_AVATAR />
        )}
      </div>
      <LabelText>{props.title}</LabelText>
      <RegularText style='grayed'>{props.role}</RegularText>
    </div>
  );
};
