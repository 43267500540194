import React from 'react';

import { Greeting } from 'components/Layout/Header/Greeting';
import { lang_prefix, rangeNumbersOptions } from 'constants/ui.constants';
import { setSinceDay } from "state/features/ui.features";
import { useAppSelector } from 'hooks/redux.hooks';
import { Dropdown } from 'components/Layout/Shared/Dropdown';
import { setDropdownOptions, _t, dayDevision } from 'components/helpers';

export const Top: React.FC<{ lastUpdate: string }> = ({ lastUpdate }) => {
  const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);
  const translation = useAppSelector((state) => state.company.translation);
  const user = useAppSelector((state) => state.global.user);

  return (
    <div className='header'>
      <Greeting lastModified={lastUpdate} title={`Good ${dayDevision()}, ${user?.first_name}!`} />
      <div className='filter'>
        <Dropdown
          onChange={setSinceDay}
          translate={true}
          translate_prefix={lang_prefix.API_FILTER_DAYS}
          value={{
            value: sinceDay,
            label: _t(sinceDay, translation, lang_prefix.API_FILTER_DAYS)
          }}
          options={setDropdownOptions([
            rangeNumbersOptions.LAST_3,
            rangeNumbersOptions.LAST_7,
            rangeNumbersOptions.LAST_14,
            rangeNumbersOptions.LAST_30,
            rangeNumbersOptions.LAST_90,
          ])}
        />
      </div>
    </div>
  )
}