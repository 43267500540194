import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import isValid from 'date-fns/isValid';
import _ from 'lodash';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import * as ICONS from 'assets/icons/General';
import { UNCATEGORIZED } from 'assets/icons/Incidents';
import { fetchNotifications } from 'services/notifications.services';
import { INotification } from 'constants/notifications.constant';
import { truncateText } from 'components/helpers';
import { setAllowDesktopNotifications, setDisplayOptionToAllow } from 'state/features/notifications.features';
import { RegularText } from '../Shared/Elements';

export const Notifications: React.FC = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector((state) => state.notifications.items);
    const displayOptionToAllow = useAppSelector((state) => state.notifications.displayOptionToAllow);

    return (
        <Dropdown onToggle={(show)=>{ if(show){ dispatch(fetchNotifications()) }}}>
            <Dropdown.Toggle as="div" id="dropdown-basic">
                <ICONS.NOTIFICATIONS/>
            </Dropdown.Toggle >
            <Dropdown.Menu align="end" className='notifications-dropdown'>
                <div className='notifications-dialogbox'>                        
                    { displayOptionToAllow && <AllowNotifications/> }
                    <div className='body'>
                        {   
                            notifications.length ? 
                            _.orderBy(notifications,'created_at','desc')
                            .map((notification:INotification,index:number) => 
                                <NotificationItem key={index} item={notification}/>
                            ) : <RegularText style='grayed'>No Notificactions</RegularText>
                        }
                    </div>                        
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export const NotificationItem: React.FC<{item:INotification}> = (props) => {
    return (
        <div className='task'>
            <div className='taskIcon'><UNCATEGORIZED/></div>
            <div className='taskHeader'>
                <div className='taskTitle'>{props.item.trigger}</div>
                <div className='taskTime'>
                    { isValid(new Date(props.item.updated_at)) && formatDistanceToNow(new Date(props.item.updated_at)) }
                </div>
            </div>            
            <div className='taskText tooltip'>
            <span className="tooltiptext wide">{props.item.title}</span>
            {truncateText(props.item.title,30)}
            </div>
            
        </div>
    )
}

export const AllowNotifications: React.FC = () => {
    const dispatch = useAppDispatch();
    return (
    <div className='header'>                          
        <div className='headerTitle'>
            <div className='headerText'>Allow desktop notifications</div>
                <span onClick={() => { dispatch(setDisplayOptionToAllow()) }}><ICONS.CLOSE/></span>
            </div>
        <div className='button allow' onClick={() => { dispatch(setAllowDesktopNotifications()) }}>Allow</div>
    </div>
    )
}