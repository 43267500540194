import React, { useState, useContext } from 'react';
import { useAppSelector } from 'hooks/redux.hooks';

import { GraphsCls } from 'class/class.graphs';
import { ProviderProps, graphContext } from 'constants/ui.constants';
import { Badge, Checkbox} from 'components/Layout/Shared/Elements';
import * as ICONS from 'assets/icons/General';
import * as CHARTS from './Charts'
import * as TAB from 'components/Layout/Shared/Tabs';
//TODO : create selectors more elegant
export const Graphs: React.FC = () => {
    const incidents = useAppSelector((state) => state.incidents.items);
    const [activeIncidentsTab,setActiveIncidentsTab] = useState('sevirity');
    const graphsCls = new GraphsCls();
    graphsCls.setIncidents = incidents;;
    const enlarge = useContext(graphContext);
    return (
        <GRAPHS_CONTAINER>
                <GRAPH position='top-left'>
                    <GRAPH_HEADER>
                        <GRAPH_HEADER_TITLE title='Incidents by' />
                        <TAB.TABS_HEADER>
                <TAB.TAB_BUTTON 
                    tab={{
                        key:'sevirity',
                        title:'Severity',
                        isActive:TAB.isActive(['sevirity','sevirity-status'],activeIncidentsTab)
                    }} 
                    toggle={setActiveIncidentsTab} 
                />
                <TAB.TAB_BUTTON 
                    tab={{key:'status',title:'Status',isActive:TAB.isActive(['status'],activeIncidentsTab)}} 
                    toggle={setActiveIncidentsTab} 
                />
            </TAB.TABS_HEADER>
                        <GRAPH_ENLARGE/>
                        <GRAPH_HEADER_ACTIONS>
                            <>{(TAB.isActive(['sevirity-status','sevirity'],activeIncidentsTab)) && 
                                <div>
                                    <Checkbox 
                                        checked={TAB.isActive(['sevirity'],activeIncidentsTab)} 
                                        onToggle={(state)=> { const value = state ? 'sevirity-status' : 'sevirity';
                                                    setActiveIncidentsTab(value)}}/> Show Status</div>
                                }
                            </>
                        </GRAPH_HEADER_ACTIONS>
                    </GRAPH_HEADER>
                    {TAB.isActive(['sevirity'],activeIncidentsTab) && 
                        <CHARTS.TOP_LEFT_CHART 
                            name='sevirity' 
                            data={graphsCls.incidentServirity.reverse()}
                        />
                    }
                    {TAB.isActive(['status'],activeIncidentsTab) && 
                        <CHARTS.TOP_LEFT_CHART 
                            name='status' 
                            data={graphsCls.incidentStatus}
                        />
                    }
                    {TAB.isActive(['sevirity-status'],activeIncidentsTab) && 
                        <CHARTS.TOP_LEFT_CHART 
                            name='sevirity-status' 
                            data={graphsCls.incidentServirityWithStatus.reverse()}
                        />
                    }
                </GRAPH>
                <GRAPH position='top-right'>
                    <GRAPH_PIE_HEADER >
                        <GRAPH_HEADER_TITLE title='Incidents by Summary'/>
                        <GRAPH_ENLARGE/>
                        <GRAPH_HEADER_ACTIONS>
                            <Badge text='Top 5'/>
                        </GRAPH_HEADER_ACTIONS>
                    </GRAPH_PIE_HEADER>
                    <CHARTS.TOP_RIGHT_CHART data={graphsCls.summaryLevelIncident}/>
                </GRAPH>
                <GRAPH position='bottom-left'>
                    <GRAPH_PIE_HEADER>
                        <GRAPH_HEADER_TITLE title='Incidents by Category' />
                        <GRAPH_ENLARGE/>
                        <GRAPH_HEADER_ACTIONS>
                            <Badge text='Top 5'/>
                        </GRAPH_HEADER_ACTIONS>
                    </GRAPH_PIE_HEADER>
                    <CHARTS.BOTTOM_LEFT_CHART data={graphsCls.categoryLevelSummary}/>
                </GRAPH>
                <GRAPH position='bottom-right'>
                    <GRAPH_HEADER>
                        <GRAPH_HEADER_TITLE title='SLA by Severity ' />
                        <GRAPH_ENLARGE/>
                    </GRAPH_HEADER>
                    <CHARTS.BOTTOM_RIGHT_CHART data={graphsCls.getAvrageSLA}/>
                </GRAPH> 
        </GRAPHS_CONTAINER> 
    )
}

export const GRAPHS_CONTAINER: React.FC<ProviderProps> = (props) => {
    return (
        <div className='graphs-container'>
            <div className='graphs'>
                {props.children}
            </div>
        </div>
    )
}

export const GRAPH: React.FC<{children:ProviderProps,position?:string}> = (props) => {
    const [maximize,setMaximize] = useState(false);
    return (
        <graphContext.Provider value={{maximize,setMaximize}}>
            <div className='graph-wrap'>
                <div className={`graph${props.position ? ' '+props.position:''}${maximize ? ' maximize':''}`}>
                    <>{props.children}</>
                </div>
            </div>
        </graphContext.Provider>
    )
}

export const GRAPH_HEADER: React.FC<ProviderProps> = (props) => {
    return (
        <div className='graph-header'>
            {props.children}
        </div>
    )
}

export const GRAPH_PIE_HEADER: React.FC<ProviderProps> = (props) => {
    return (
        <div className='graph-pie-header'>
            {props.children}
        </div>
    )
}

export const GRAPH_HEADER_TITLE: React.FC<{title:string}> = ({title}) => {
    return <div className='graph-header-title'>{title}</div>;
}

export const GRAPH_HEADER_ACTIONS: React.FC<ProviderProps> = (props) => {
    return <div className='graph-header-actions'>{props.children}</div>;
}

export const GRAPH_ENLARGE: React.FC<{onClickHandle?:any}> = ({onClickHandle}) => {
    const enlarge = useContext(graphContext);
    const onClickEvent = () => {
        enlarge.setMaximize(!enlarge.maximize);
        if(onClickHandle)
            onClickHandle(!enlarge.maximize); 
    } 
    return  <div className='graph-enlarge' onClick={onClickEvent}>
                {!enlarge.maximize && <div className='maximize'><ICONS.MAXIMAIZE/></div>}
                { enlarge.maximize && <div className='minimize'><ICONS.MINIMIZE/></div>}
            </div>;
}