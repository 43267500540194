import { API_GET_INCIDENTS, API_GET_INCIDENT_STATUS_UPDATES, API_UPDATE_INCIDENT } from 'constants/db.constants';
import { setError, setItems, setLoading, setIncident, setIncidentStatusUpdates} from 'state/features/incidents.features';
import API from '../services/api.service';

export function fetchIncidents(sinceDay: string, untilDay: string) {
  const paramsSetting = {
    sinceDaysAgo: sinceDay,
    untilDaysAgo: untilDay
  };

  return async (dispatch: any, getState:any) => {
    const { company }  = getState();
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_INCIDENTS, API.wrapParamsWithCompany(getState, paramsSetting))
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}
type editText = {
  name: string;
  value: string;
};

export function updateIncident(editText: editText, id: number) {
  const paramsSetting = {
    id: id,
    [editText.name]: editText.value
  };

  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .post(API_UPDATE_INCIDENT + API.addQueryWithCompany(getState), paramsSetting)
      .then((response) => {
        dispatch(setIncident(response.data.incident));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function fetchIncidentStatusUpdates(id: number) {
  const paramsSetting = {
    incident_id: id,
  };
  return async (dispatch: any, getState:any) => {
    const { company }  = getState();
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_INCIDENT_STATUS_UPDATES, API.wrapParamsWithCompany(getState, paramsSetting))
      .then((response) => {
        dispatch(setIncidentStatusUpdates(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}
