import React from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { Dashboard } from './Dashboard';
import { ControlRoom } from './ControlRoom';
import { Insights } from './Insights';
import { Profile } from './Profile';
import { TermsOfService } from './TermsOfService';
import { CompanyProfile } from './CompanyProfile';
import { pagesEnum } from 'constants/ui.constants';
import { Reports } from './Reports';

export const getPage: React.FC = (page) => {
  switch (page) {
    case 'dashboard':
      return <Dashboard />;
      break;
    case 'controlRoom':
      return <ControlRoom />;
      break;
    case 'profile':
      return <Profile />;
      break;
    case 'insights':
      return <Insights />;
      break;
    case 'termsOfService':
      return <TermsOfService />;
      break;
    case pagesEnum.COMPANY_PROFILE:
      return <CompanyProfile />;
      break;
    case pagesEnum.COMPANY_PROFILE_MONITOR_DEVICE:
        return <CompanyProfile section="monitor_devices"/>;
    break;
    case pagesEnum.COMPANY_PROFILE_USE_CASE:
      return <CompanyProfile section="use_case"/>;
  break;
    case pagesEnum.REPORTS:
    case pagesEnum.REPORTS_FALSE_POSITIVE_RATE:
      return <Reports />;
    break;
  }
  return <></>;
};

export const Pages = () => {
  const page = useAppSelector((state) => state.ui.page);
  return <div className={`page page-${page}`}>{getPage(page)}</div>;
};
