import React from 'react';
import _ from 'lodash';

import { RegularText, Space} from 'components/Layout/Shared/Elements';
import { useAppSelector } from 'hooks/redux.hooks';
import { lang_prefix } from 'constants/ui.constants';
import { _t } from 'components/helpers';

export const TasksSummary: React.FC<{tasks:{[key:string]:number}}> = (props) => {
    const translation = useAppSelector((state) => state.company.translation);

    return <RegularText style='content-inline-block'>
                <RegularText>{String(props.tasks.opened)}</RegularText>
                <RegularText> / </RegularText>
                <RegularText style='grayed'>{String(props.tasks.total)}</RegularText>
                <RegularText style='content-inline-block'>
                    <Space/>
                    <RegularText>
                        {_t('open_tasks', translation, lang_prefix.API_TITLE)}
                    </RegularText>
                </RegularText>
            </RegularText>;
}