import React from 'react';

import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { statusColors, lang_prefix } from 'constants/ui.constants';
import { IncidentSeverityOrder } from 'constants/incident.constants';
import { _t } from 'components/helpers';
import { useAppSelector } from 'hooks/redux.hooks';

export const CHART: React.FC<{ data: any; id: number; uniqueDays: Date[] }> = ({ data, id, uniqueDays }) => {
  const translation = useAppSelector((state) => state.company.translation);
  const severityOrder = [...IncidentSeverityOrder].reverse();

  return (
    <div className='graph-chart'>
      <ResponsiveScatterPlot
        data={data}
        colorBy='index'
        colors={statusColors}
        margin={{ top: 30, right: 30, bottom: 50, left: 100 }}
        xScale={{ type: 'time', precision: 'minute' }}
        yScale={{ type: 'linear' }}
        tooltip={({ node }) => <span className='graph-custom-tooltip'>Summary: {node.data?.summary || 'None'}</span>}
        nodeSize={(d) => {
          return d.data.incident_id === id ? 16 : 8;
        }}
        axisTop={null}
        axisRight={null}
        enableGridX={true}
        gridXValues={uniqueDays}
        gridYValues={[0, 1, 2, 3, 4]}
        axisBottom={{
          tickValues: uniqueDays,
          format: '%d.%m',
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 45,
          legend: 'Time',
          legendPosition: 'middle',
          legendOffset: 45
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 25,
          tickRotation: 0,
          ticksPosition: 'before',
          legend: _t('severity', translation, lang_prefix.INCIDENTS_PROPERTY),
          legendPosition: 'middle',
          legendOffset: -90,
          tickValues: [0, 1, 2, 3, 4],
          format: (value) => `${_t(severityOrder[value], translation, lang_prefix.INCIDENTS_SEVIRITY)}`
        }}
        legends={[
          {
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 50,
            translateY: -30,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};
