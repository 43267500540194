import React from 'react';
import { UItab } from 'constants/ui.constants';


export const TABS_CONTAINER: React.FC<{children:JSX.Element[]}> = (props) => {
    return (
        <div className='tabs-containers'>
            {props.children}
        </div>
    )
}

export const TAB_CONTAINER: React.FC<{children:JSX.Element | JSX.Element[] ,isActive:boolean, style?:string}> = (props) => {
    return (
        <div className={`tab-container${props.isActive?' active':' d-none'}${props.style?' '+props.style:''}`}>
                <>{props.children}</>
        </div>
    )
}

export const TABS_HEADER: React.FC<{children:JSX.Element[],style?:string}> = (props) => {
    return (
        <div className={`tabs-header${props.style?' '+props.style:''}`}>
            {props.children}
        </div>
)
}

export const TAB_BUTTON: React.FC<{toggle:any,tab:UItab}> = (props) => {
    return (
        <div onClick={() => {props.toggle(props.tab.key)}}
        className={`button regular${props.tab.isActive?' active':''}`}>
            {props.tab.title}
        </div>
    )
}

export const LINE_BUTTON_TAB: React.FC<{toggle:any,tab:UItab}> = (props) => {
    return (
        <div onClick={() => {props.toggle(props.tab.key)}}
        className={`button line${props.tab.isActive?' active':''}`}>
            {props.tab.title}
        </div>
    )
}

export const isActive = (tabs:string[], selectedTab:string):boolean => {
    return tabs.includes(selectedTab); 
}