import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';

import { ParagraphText, TinyText, FlexSpaceBetween, Flex } from 'components/Layout/Shared/Elements';
import { IUser } from 'state/features/global.features';
import * as ICONS from 'assets/icons/General';
import { Task, taskStatus, taskStatusLabels } from 'constants/tasks.constants';
import { Dropdown } from '../Dropdown';
import { lang_prefix, translation } from 'constants/ui.constants';
import { _t, setDropdownOptions } from 'components/helpers';
import { fetchAllComments, updateTask } from 'services/tasks.services';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import CommentsModal from 'components/Pages/ControlRoom/CommentsModal';
import _ from 'lodash';

export const STATUS_CARD: React.FC<{ item: any }> = (props): JSX.Element => {
  const colors = ['bg-red', 'bg-blue', 'bg-green', 'bg-grey', 'bg-pink'];
  const { item } = props;
  const { index, last_updating_user, task, title } = item;
  const [showAddComment, setShowAddComment] = useState(false);
  const user = useAppSelector((state) => state.global.user);
  const userName = `${user?.first_name} ${user?.last_name}`;
  const [comment, setComment] = useState<string>('');
  const [chosenStatus, setChosenStatus] = useState<taskStatus>(props?.item?.task?.status);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const charLimit = 250;
  const [commentAdded, setCommentAdded] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState<string>(taskStatusLabels[task.status]);
  const dispatch = useAppDispatch();

  const onCommentsClick = () => {
    setShowAddComment(false);
    setShowCommentsModal((prev) => !prev);
  };

  function getColorByName(name: string): string {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash % colors.length);
    return colors[index];
  }

  const onStatusUpdate = (status: taskStatus) => {
    if (taskStatusLabels[chosenStatus] !== status) {
      if (status === taskStatusLabels[taskStatus.CLOSE]) {
        setIsMandatory(false);
        setShowAddComment(true);
        setChosenStatus(taskStatus.CLOSE);
        setDropdownLabel(taskStatusLabels[taskStatus.CLOSE]);
      }
      if (status === taskStatusLabels[taskStatus.IN_PROGRESS]) {
        setIsMandatory(true);
        setShowAddComment(true);
        setChosenStatus(taskStatus.IN_PROGRESS);
        setDropdownLabel(taskStatusLabels[taskStatus.IN_PROGRESS]);
      }
    }
  };

  const getTransformedStatuses = (statuses: taskStatus[]): string[] => {
    return statuses.map((status) => taskStatusLabels[status]);
  };

  const handleSend = async () => {
    setShowAddComment(false);
    await dispatch(updateTask(chosenStatus, task?.id, comment, userName));
    if (chosenStatus === 'in progress') {
      setCommentAdded(true);
    }
    setComment('');
    await dispatch(fetchAllComments());
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [comment]);

  useEffect(() => {
    if (commentAdded) {
      const timer = setTimeout(() => setCommentAdded(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [commentAdded]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= charLimit) {
      setComment(event.target.value);
    }
  };

  return (
    <div className={`card status-card`} key={item.index}>
      <div className={`card-headline`}>
        <Flex>
          <TinyText style='grayed'>{item.date}</TinyText>
          <USER user={last_updating_user} />
        </Flex>
        <FlexSpaceBetween style={`priority ${props.item.priority}`}>
          <div className='priority-line'></div>
          <div className='priority-line'></div>
          <div className='priority-line'></div>
        </FlexSpaceBetween>
      </div>
      <div className='card-headline-content'>
        <ParagraphText>{title}</ParagraphText>
      </div>
      <div className='card-columns'>
        {task?.status === 'close' ? (
          <Dropdown
            withoutDispatch={true}
            name='task'
            onChange={(status: taskStatus) => onStatusUpdate(status)}
            translate={true}
            translate_prefix={lang_prefix.TASK_STATUS}
            value={{
              value: task.status,
              label: dropdownLabel
            }}
            options={setDropdownOptions(getTransformedStatuses([taskStatus.CLOSE, taskStatus.IN_PROGRESS]))}
            isDisabled={true}
            formatOption={undefined}
            with_all={false}
            isMulty={false}
            hideSelectedOptions={false}
            customFormatLable={undefined}
          />
        ) : (
          <Dropdown
            withoutDispatch={true}
            name='task'
            onChange={(status: taskStatus) => onStatusUpdate(status)}
            translate={true}
            translate_prefix={lang_prefix.TASK_STATUS}
            value={{
              value: task?.status,
              label: dropdownLabel
            }}
            options={setDropdownOptions(getTransformedStatuses([taskStatus.CLOSE, taskStatus.IN_PROGRESS]))}
            isDisabled={false}
            formatOption={undefined}
            with_all={false}
            isMulty={false}
            hideSelectedOptions={false}
            customFormatLable={undefined}
          />
        )}
      </div>
      {showAddComment && (
        <div className='comment-input'>
          <div className='textarea-wrapper'>
            <textarea
              ref={textareaRef}
              value={comment}
              onChange={handleChange}
              placeholder='Write a comment'
              className='comment-textarea'
            />
            <span className='char-counter'>
              {comment.length}/{charLimit}
            </span>
          </div>
          <div className='comment-footer'>
            <button onClick={handleSend} disabled={isMandatory && comment.trim() === ''} className='send-button'>
              Send
            </button>
          </div>
        </div>
      )}
      {commentAdded && (
        <div className='comment-success'>
          <ICONS.BLUE_TICK />
          <span>Comment added successfully</span>
        </div>
      )}

      {item?.commentsArray?.length !== 0 && (
        <>
          <div className='divider'></div>
          <div className='comments-wrapper' onClick={() => onCommentsClick()}>
            <p>{item?.commentsArray?.length} comments</p>
            <div className='comments-icon-wrapper'>
              {item?.commentsArray?.map((item, index) => {
                const colorClass = getColorByName(item.last_updating_user);
                return (
                  <div key={index} className={`first-letter-circle ${colorClass}`}>
                    {_.capitalize(item.last_updating_user?.charAt(0))}
                  </div>
                );
              })}
            </div>
            <div className='open-icon'>{showCommentsModal ? <ICONS.CLOSE_COMMENTS /> : <ICONS.OPEN_COMMENTS />}</div>
          </div>
        </>
      )}

      {showCommentsModal && (
        <>
          <CommentsModal isVisible={showCommentsModal} commentsArray={item.commentsArray} />
        </>
      )}
    </div>
  );
};

export const USER: React.FC<{ user: string }> = ({ user }): JSX.Element => {
  if (user) {
    const tooltip = <span className='graph-custom-tooltip'>{user}</span>;
    return (
      <OverlayTrigger placement='top' trigger={['hover', 'focus']} delay={200} overlay={tooltip} rootClose>
        <div className='card-user'>
          <ICONS.MENU_PROFILE />
        </div>
      </OverlayTrigger>
    );
  }
  return <></>;
};
