import React from 'react';

import { Text, View} from '@react-pdf/renderer';
import { styles } from '../styles';
import { Incident } from 'constants/incident.constants';
import { formatValues } from '../../helpers';

export const IncidentsTable: React.FC<{incidents:Incident[]}> = ({incidents}) => {
    return (
        <View break>
            <View style={styles.headerParagraph}>
                <Text>Incidents</Text>
            </View>
            <View style={styles.rowHeader}>
                <Text style={styles.column1}>Name</Text>
                <Text style={styles.column2}>ID</Text>
                <Text style={styles.column3}>Severity</Text>
                <Text style={styles.column4}>Date</Text>
                <Text style={styles.column5}>Status</Text>
                <Text style={styles.column5}>Reason</Text>
            </View>
            {incidents.map((incident,index) =>
                <View style={styles.row} key={index}>
                    <Text style={styles.column1}>{incident.title}</Text>
                    <Text style={styles.column2}>{incident.external_id}</Text>
                    <Text style={styles.column3}>{formatValues('severity', incident.severity)}</Text>
                    <Text style={styles.column4}><Text style={styles.smFont}>{formatValues('created_at', incident.created_at)}</Text></Text>
                    <Text style={styles.column5}>{incident.status}</Text>
                    <Text style={styles.column5}>{incident.closing_reason}</Text>
                </View>
            )}
        </View>
    )
}