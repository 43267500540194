import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { format, isValid } from 'date-fns';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { timing, lang_prefix, orderBy } from 'constants/ui.constants';
import { Task, taskStatus, TaskEnum, Comment, taskStatusLabels } from 'constants/tasks.constants';
import { STATUS_CARD } from 'components/Layout/Shared/Cards/StatusCard';
import { Dropdown } from 'components/Layout/Shared/Dropdown';
import { _t, setDropdownOptions } from 'components/helpers';
import { fetchAllComments, updateTask } from 'services/tasks.services';
import { TasksCls } from 'class/class.tasks';
import { SortHeaders } from 'components/Layout/Shared/SortHeader';
import { setConrolRoomSortTasks } from 'state/features/ui.features';
import {
  FlexSpaceBetween,
  H1,
  ParagraphText,
  ParagraphTitle,
  ParagraphTitleText,
  RegularText
} from 'components/Layout/Shared/Elements';
import { TasksPlaceholder } from './TasksPlaceholder';
import CommentsModal from './CommentsModal';

export const TasksList: React.FC<{ tasks: Task[]; includeHeader?: boolean }> = ({ tasks, includeHeader = true }) => {
  const sortTask = useAppSelector((state) => state.ui.controlRoom.sort);
  const translation = useAppSelector((state) => state.company.translation);
  const companyUsers = useAppSelector((state) => state.company.companyUsers);

  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const translations = _t(['waiting_for_you', 'status', 'priority', 'sort_by'], translation, lang_prefix.API_TITLE);
  const tasksCls = new TasksCls(tasks);
  const user = useAppSelector((state) => state.global.user);
  const comments = useAppSelector((state) => state.tasks.comments);
  const dispatch = useAppDispatch();
  const [tasksWithComments, setTasksWithComments] = useState<Task[]>([]);

  const addCommentsToTasks = (tasks: Task[], comments: Comment[]): Task[] => {
    return tasks?.map(task => ({
      ...task,
      comments: comments.filter(comment => comment.task_id === task.id)
    }));
  };

  useEffect(() => {
    const fetchCommentsAndSetTasks = async () => {
      await dispatch(fetchAllComments());
      setTasksWithComments(addCommentsToTasks(tasks, comments));
    };
    fetchCommentsAndSetTasks();
  }, [tasks, dispatch]);

  useEffect(() => {
    setTasksWithComments(addCommentsToTasks(tasks, comments));
  }, [comments, tasks]);

  if (tasksWithComments.length === 0) return <TasksPlaceholder />;


  const onStatusCardClick = (task: Task) => {
    setSelectedTask(task);
  };

  return (
    <Fragment>
      {includeHeader && (
        <div className='header'>
          <FlexSpaceBetween>
            <ParagraphTitle style='title'>{translations.waiting_for_you}</ParagraphTitle>
          </FlexSpaceBetween>
          <SortHeaders
            title={`${translations.sort_by}:`}
            handleChange={(key: TaskEnum, orderByState: orderBy) => {
              dispatch(setConrolRoomSortTasks({ name: key, orderByState: orderByState }));
            }}
            active={sortTask}
            headers={[
              {
                name: TaskEnum.priority,
                title: translations.priority
              },
              {
                name: TaskEnum.status,
                title: translations.status
              }
            ]}
          />
        </div>
      )}
      <div className='content'>
        {TasksCls.openTasks(tasksWithComments).length > 0 && (
          <H1 style={{ fontWeight: 'bold' }}>{String(TasksCls.openTasks(tasksWithComments).length)} Open Tasks</H1>
        )}
            {TasksCls.openTasks(tasksWithComments).map((task, index) => (
              <div key={index} onClick={() => onStatusCardClick(task)}>
                <STATUS_CARD
                  item={{
                    task: task,
                    title: task.title,
                    index: index,
                    date: isValid(new Date(task.updated_at)) && format(new Date(task.updated_at), timing.FULLDATE),
                    priority: task.priority,
                    last_updating_user: _.isEqual(task.status, taskStatus.IN_PROGRESS)
                      ? task.last_updating_user
                      : false,
                    commentsArray: task.comments,
                  }}
                />
              </div>
            ))}


        {TasksCls.closeTasks(tasksWithComments).length > 0 && (
          <H1 style='bold'>{String(TasksCls.closeTasks(tasksWithComments).length)} Closed Tasks</H1>
        )}
            {TasksCls.closeTasks(tasksWithComments).map((task, index) => (
              <STATUS_CARD
                key={index}
                item={{
                  task: task,
                  title: task.title,
                  index: index,
                  date: isValid(new Date(task.updated_at)) && format(new Date(task.updated_at), timing.FULLDATE),
                  priority: task.priority,
                  last_updating_user: task.last_updating_user,
                  action: <RegularText style='grayed'>Closed</RegularText>,
                  commentsArray: task.comments,
                }}
              />
            ))}

      </div>
    </Fragment>
  );
};
