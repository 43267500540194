import React, { useRef } from 'react';
export const Accordion: React.FC<{
    children: string | JSX.Element | JSX.Element[],
    active:boolean,
    activeAction:any,
    style:string
}> = (props) => {
    return <div className={`accordion${ props.active ? ' active' : ''}${ props.style ? ' '+props.style : '' }`}>
                <div className='accordion-button link' onClick={()=>{ props.activeAction() }}>Close X</div>
                {props.children}
           </div>;
}