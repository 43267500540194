import React, { useRef, useState } from 'react';

import { arrowActions } from 'constants/ui.constants';
import * as ICONS from 'assets/icons/General';
import { togglePage } from 'state/features/ui.features';
import { useAppDispatch } from 'hooks/redux.hooks';
import { InsightActionButtons } from 'components/Pages/Insights/InsightActionButtons';
import { galleryButtons, insightStatus, userPreference } from 'constants/insights.constants';
import { updateInsights } from 'components/Pages/Insights';
import { InsightStatusBadge } from 'components/Pages/Insights/InsightStatus';

export const GALLERY_SLIDER_PAGINATION: React.FC<{
  title: string;
  list: any[];
  galleryType: string;
  amountPerPage: number;
}> = ({ title, list, amountPerPage }) => {
  const [galleryState, setGalleryState] = useState({
    page: 0,
    total: list.length,
    amountPerPage: amountPerPage
  });
  const galleryRef = useRef();

  function toogleGallery(action: string) {
    const page = action === arrowActions.BACK ? galleryState.page - 1 : galleryState.page + 1;
    if (typeof galleryRef.current !== 'undefined') {
      const galleryChlidren = galleryRef.current.children;
      const galleryWidth = galleryChlidren[0].offsetWidth;
      const res = galleryState.total - page * galleryState.amountPerPage;
      const slide =
        res < galleryState.amountPerPage
          ? (page - 1) * galleryState.amountPerPage + res
          : page * galleryState.amountPerPage;

      galleryRef.current.style.left = -(slide * galleryWidth) + 'px';
      setGalleryState({ ...galleryState, page: page });
    }
  }

  return (
    <div className='slider-gallery-pagination'>
      <div className='items-gallery-title-wrap'>
        <div className='items-gallery-title'>{title}</div>
        <GALLERY_PAGINATION galleryState={galleryState} onChange={toogleGallery} />
      </div>
      <div className='items-gallery-list-wrap'>
        <div className='items-gallery-list-container'>
          <div className='items-gallery-list' ref={galleryRef}>
            <GALLERY_SLIDER_ITEMS list={list} />
          </div>
        </div>
      </div>
    </div>
  );
};

const GALLERY_SLIDER_ITEMS: React.FC<{ list: any[] }> = ({ list }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      {list.map((item, index) => {
        return (
          <div className='item-gallery-wrap' key={index}>
            <div key={index} className='item-gallery'>
              <div className='item-gallery-header'>
                <div
                  className={`left ${
                    item.status === insightStatus.AT_WORK || item.status === insightStatus.FINISHED ? 'has-status' : ''
                  }`}
                >
                  <div className='item-icon'>
                    <ICONS.INSIGHTS_DEFAULT />
                  </div>
                  <div className='item-title'>{item.title}</div>
                </div>
                { item.user_preference === userPreference.INTERESTED && <InsightStatusBadge item={item} /> }
              </div>
              <div className='item-description'>{item.description}</div>
              <InsightActionButtons
                buttonsData={galleryButtons}
                updateInsights={() => updateInsights(event, item, dispatch)}
                selectedInsight={item}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const GALLERY_PAGINATION: React.FC<{ galleryState: any; onChange: any }> = (props) => {
  const dispatch = useAppDispatch();
  const _tooglePage = (key: string) => {
    dispatch(togglePage(key));
  };

  const needToDisplayArrow = (action: string): boolean => {
    if (props.galleryState.total <= props.galleryState.amountPerPage) return false;

    if (action === arrowActions.BACK) {
      if (props.galleryState.page === 0) return false;
    } else {
      if ((props.galleryState.page + 1) * props.galleryState.amountPerPage >= props.galleryState.total) {
        return false;
      }
    }
    return true;
  };

  const paginationAmount = () => {
    const sum = props.galleryState.amountPerPage * (props.galleryState.page + 1);
    const amountToDisplay = sum > props.galleryState.total ? props.galleryState.total : sum;
    return amountToDisplay + ' / ' + props.galleryState.total;
  };

  function handleClick(action: string) {
    if (needToDisplayArrow(action)) {
      props.onChange(action);
    }
    return false;
  }

  return (
    <div className='item-gallery-pagination'>
      <div className='pagination-amount'>{paginationAmount()}</div>
      <GALLERY_PAGINATION_ARROW
        isDisabled={needToDisplayArrow(arrowActions.BACK)}
        action={arrowActions.BACK}
        handleClick={handleClick}
      />
      <GALLERY_PAGINATION_INDICATION galleryState={props.galleryState} />
      <GALLERY_PAGINATION_ARROW
        isDisabled={needToDisplayArrow(arrowActions.FORWARD)}
        action={arrowActions.FORWARD}
        handleClick={handleClick}
      />
      <div
        className='pagination-link'
        onClick={(e) => {
          _tooglePage('insights');
        }}
      >
        Show All
      </div>
    </div>
  );
};

const GALLERY_PAGINATION_ARROW: React.FC<{ action: string; isDisabled: boolean; handleClick: any }> = (props) => {
  return (
    <div
      className={`gallery-arrow ${props.action}-arrow ${props.isDisabled ? '' : 'disabled'}`}
      onClick={(e) => {
        props.handleClick(props.action);
      }}
    >
      <ICONS.CHEVRON_RIGHT />
    </div>
  );
};

const GALLERY_PAGINATION_INDICATION: React.FC<{ galleryState: any }> = (props) => {
  const pages = Math.ceil(props.galleryState.total / props.galleryState.amountPerPage);
  const pagesArray = new Array(pages).fill('');

  return (
    <div className='gallery-pagination-indication'>
      
      { pagesArray.map((dote, index) => 
        (index === Number(props.galleryState.page)) ?
          <div key={index} className='line'></div> : <div className='dote' key={index}></div>
        )
      }
    </div>
  );
};
