import React, { useContext } from 'react';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';

import { GraphsCls } from 'class/class.graphs';
import { 
        dataGraph,
        graphContext,
        sevirityColors,
        statusColors, 
        statusColorsObj,
        categoryColors,
        summaryColors,
        pagesEnum,
        sevirityColorsSLA
} from 'constants/ui.constants';
import { truncateText } from 'components/helpers';
import { togglePage, setStatus,setSevirirty, setConrolRoomSinceDay, setControlRoomSortIncidents } from 'state/features/ui.features';

export const TOP_LEFT_CHART: React.FC<{ data:dataGraph, name:string }> = ({ data, name }) => {
    const dispatch = useAppDispatch();
    const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);
    const onGraphColumnClick=(node)=>{
        if(node.data.open){
            dispatch(setSevirirty(node.data.sevirity.toLowerCase()))
        }
        else if(node.data.sevirity){
            dispatch(setSevirirty(node.data.id))
        }
        else if(node.data.status){
            dispatch(setStatus(node.data.id))
        }
        dispatch(setConrolRoomSinceDay(sinceDay))
        dispatch(togglePage(pagesEnum.CONTROL_ROOM))
    }
    const props = getProps(name); 
    return <div className='graph-chart'>
    <ResponsiveBar 
            { ...props } 
            data={data}
            margin={{ top: 20, right: 40, bottom: 20, left: 40 }}
            padding={0.9}
            valueScale={{ type: "linear" }}
            animate={false}
            enableLabel={false}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            onClick={(node) => {onGraphColumnClick(node)}}
            tooltip={(node ) => {
                return <span className='graph-custom-tooltip'>
                            {node.data?.label || node?.id } : {node?.value}
                        </span>
            }}
            axisLeft={{
                tickValues: 4,
                tickSize: 0,
                "tickPadding": 5,
                "tickRotation": 0,
                "legendPosition": "middle",
                "legendOffset": -40,
            }}
            axisBottom={{
                tickSize: 0,
                "tickPadding": 5,
                "tickRotation": 0,
                "legendPosition": "middle",
                "legendOffset": -40,
            }}
    />
    </div>;
}
export const TOP_RIGHT_CHART: React.FC<{data:dataGraph}> = ({data}) => {
    const enlarge = useContext(graphContext);
    const dispatch = useAppDispatch();
    const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);

    const onSummaryChartClick = () =>{
        dispatch(setControlRoomSortIncidents({name: 'title', orderByState: 'desc'}))
        dispatch(setConrolRoomSinceDay(sinceDay))
        dispatch(togglePage(pagesEnum.CONTROL_ROOM))
    }
    const _data = [...data];
    _data.forEach((item) => {
        item.label = enlarge.maximize ? item.id : truncateText(item.label, 40);
    })
    return <div className='graph-chart pie' onClick={onSummaryChartClick}>
    <ResponsivePie
            data={_data}
            colors={summaryColors}
            margin={{ top: 5, right: -270, bottom: 5, left: 0 }}
            innerRadius={0.65}
            animate={false}
            padAngle={0.8}
            cornerRadius={0}
            activeOuterRadiusOffset={0}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            tooltip={(node ) => {
                return <span className='graph-custom-tooltip'>{node.datum?.data.id} : {node.datum?.data.value}</span>
            }}
            legends={[{
                "anchor":"top-left",
                "direction":"column",
                "itemHeight":25,
                "itemWidth":70,
                "symbolShape":"circle",
                "symbolSize":10,
                "translateY":40,
            }]}                   
        />

    </div>;
} 
export const BOTTOM_LEFT_CHART: React.FC<{data:dataGraph, enlargeState:any, translateY?:number}> = ({data, enlargeState, translateY=40}) => {
    const enlarge = enlargeState ? enlargeState : useContext(graphContext);
    const showData = !enlarge.maximize ? data.slice(0,5) : data;
    const dispatch = useAppDispatch();
    const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);

    const onCategoryChartClick = () =>{
        dispatch(setControlRoomSortIncidents({name: 'category_name', orderByState: 'desc'}))
        dispatch(setConrolRoomSinceDay(sinceDay))
        dispatch(togglePage(pagesEnum.CONTROL_ROOM))
    }
    return <div className='graph-chart pie' onClick={onCategoryChartClick}>
    <ResponsivePie
            data={showData}
            animate={false}
            colors={summaryColors}
            margin={{ top: 5, right: -270, bottom: 5, left: 0 }}
            innerRadius={0}
            padAngle={0.7}
            cornerRadius={0}
            activeOuterRadiusOffset={0}
            borderWidth={1}
            enableArcLabels={false}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            enableArcLinkLabels={false}
            tooltip={(node ) => {
                return <span className='graph-custom-tooltip'>{node.datum?.data.id} : {node.datum?.data.value}</span>
            }}
            legends={[{
                "anchor":"top-left",
                "direction":"column",
                "itemHeight":25,
                "itemWidth":170,
                "translateY":translateY,
                "symbolShape":"circle",
                "symbolSize":10}]
            }
        />

    </div>;
}

export const BOTTOM_RIGHT_CHART: React.FC<{data:dataGraph}> = ({data}) => {
    const SLA = useAppSelector((state) => state.company.sla);
    const enlarge = useContext(graphContext);
    const graphsCls = new GraphsCls();
    graphsCls.setSLA = SLA;
    const dispatch = useAppDispatch();
    const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);

    const severityData = [
        { severity: "critical", minutes: null },
        { severity: "high", minutes: null },
        { severity: "medium", minutes: null },
        { severity: "low", minutes: null },
    ];

    const updateSeverityData = (data: any[]) => {
        data.forEach(item => {
            if (item.assign_time_minutes !== null) {
                const severity = severityData.find(s => s.severity === item.severity);
                if (severity&& item.assign_time_minutes) {
                    severity.minutes = item.assign_time_minutes;
                }
            }
        });
    };
    
    updateSeverityData(SLA);

    const onSeverityChartClick = () =>{
        dispatch(setControlRoomSortIncidents({name: 'severity', orderByState: 'desc'}))
        dispatch(setConrolRoomSinceDay(sinceDay))
        dispatch(togglePage(pagesEnum.CONTROL_ROOM))
    }
    
    return <div className='graph-chart direction-rtl' onClick={onSeverityChartClick}>
        <ResponsiveBar
            data={data}
            keys={["value"]}
            indexBy="label"
            layout='horizontal'
            margin={{ top: 0, right: 20, bottom: 20, left: 100 }}
            padding={0.65}
            valueScale={{ type: "linear" }}
            colorBy="index"
            colors={sevirityColorsSLA}
            animate={true}
            enableLabel={false}
            enableGridY={false}
            tooltip={(node ) => {
                return <span className='graph-custom-tooltip'>{node.data?.label || node.data?.id} : {node.data?.value} Minutes Average</span>
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickValues: 5,
                tickSize: 0,
                tickPadding: 1,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: 10,
            }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 100,
                legendPosition:"start",
                legendOffset: 0,         
            }}
            
            legends={[
                {
                    dataFrom: 'labels',
                    anchor: 'left',
                    direction: 'column',
                    justify: false,
                    translateY: 0,
                    translateX: -20,
                    itemsSpacing: enlarge.maximize ? 40 : 6,
                    itemWidth: 0,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 0,
                    data: severityData.map(s => ({
                        id: s.severity,
                        label: ` ${s.minutes !== null ? s.minutes + 'min': ''}`,
                        color: 'white'
                    })),

                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
    />
    </div>;
}
  
export const HISTORICAL_DATA: React.FC<{data:any[]}> = ({data}) => {
    const theme = {
        background: "none",
        axis: {
            ticks:{
                text:{
                    fontSize: "11px",
                }
            }
        },
        legends: {
            text: {
              fontSize: "13px",
              
            }
          }
    };

    return (
        <ResponsiveLine
        data={data}
        indexBy="id"
        theme={theme}
	    colors={sevirityColors}
        margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            reverse: false
        }}
        yFormat=" >-.2f"
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 6,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                style:{
                    fontSize:'20px'
                }
            }
        ]}
    />
    );
}

const getProps = (name:string) => {
    let props = {
        keys:["sevirity"],
        indexBy:"label",
        colorBy : "index",
    }
    switch(name){
        case 'status':
            props.keys = ['status'];
            props.colorBy = "index";
            props.colors = statusColors;
        break;
        case 'sevirity':
            props.colors =  sevirityColors;
        break;
        case 'sevirity-status':
            props.keys = ['open','in progress','tuning','waiting','close'];
            props.indexBy = 'sevirity';
            props.colors = statusColors;
            props.colorBy = (d) => {return (statusColorsObj[d.id])};
        break;
    }
    return props;
}