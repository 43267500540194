import React, { useEffect, useRef, useState } from 'react';
import { format, isValid } from 'date-fns';

import { ONE_COLUMN } from 'components/Layout/theme';
import { Flex, ParagraphTitle, Space } from 'components/Layout/Shared/Elements';
import { INPUTS_GROUP_CARD, INPUTS_GROUP_CARD_ROW } from 'components/Layout/Shared/Cards/InputsGroupCard';
import { DEFAULT_AVATAR } from 'assets/icons/General';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { ICompany, MonitoredDevices } from 'constants/company.constants';
import { USER_CARD } from 'components/Layout/Shared/Cards/UserCard';
import { IMAGE_TYPE } from 'constants/db.constants';
import { timing } from 'constants/ui.constants';
import { v4 as uuidv4 } from 'uuid';
import { uploadAvatar } from 'services/global.service';
import { SOURCE_IP } from './SourceIp';
import _ from 'lodash';
import * as ICONS from 'assets/icons/General';
import { Dropdown } from 'components/Layout/Shared/Dropdown';


export const CompanyProfile: React.FC<{section?:string}> = ({section}) => {
  const dispatch = useAppDispatch();
  const companyDetails: ICompany | {} = useAppSelector((state) => state.company.details);
  const monitoredDevices: MonitoredDevices[] = useAppSelector((state) => state.company.monitoredDevices);
  const companyUsers = useAppSelector((state) => state.company.companyUsers);
  const companySourceIps = useAppSelector((state) => state.company.sourceIps);
  const sas = useAppSelector((state) => state.global.SAS);
  const inputFile = useRef(null);
  const monitoredDeviceRef = useRef(null);
  const useCaseRules = useAppSelector((state) => state.company.useCaseRules);
  const useCaseRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredRules, setFilteredRules] = useState(useCaseRules);
  const [sortOption, setSortOption] = useState<string | null>("date_desc");

  const handleSearchChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    const filterAndSortRules = () => {
      let filtered = useCaseRules.filter(rule =>
        rule.rule_name.toLowerCase().startsWith(searchInput.toLowerCase())
      );

      if (sortOption === 'az') {
        filtered = filtered.sort((a, b) => a.rule_name.trim().localeCompare(b.rule_name.trim()));
      } else if (sortOption === 'date_desc') {
        filtered = filtered.sort((a, b) => {
          const dateA = Date.parse(a.last_report);
          const dateB = Date.parse(b.last_report);
          return dateB - dateA;
        });
      }

      setFilteredRules(filtered);
    };

    filterAndSortRules();
  }, [searchInput, useCaseRules, sortOption]);


  useEffect(() => {
    if(!_.isEmpty(monitoredDeviceRef.current)&&section==="monitor_devices"){
      const offsetTop = section ? monitoredDeviceRef?.current?.offsetTop -50: 0;
      window.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'});
    }
    if(!_.isEmpty(useCaseRef.current)&&section==="use_case"){
      const offsetTop = section ? useCaseRef?.current?.offsetTop -50: 0;
      window.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'});
    }
  });

  function openFileExplorer() {
    inputFile.current.click();
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };



  function handleImageUpload(event) {
    const companyImage = document.getElementById('company-image-temp') as HTMLInputElement;
    companyImage.src = window.URL.createObjectURL(event?.target?.files?.[0]);
    companyImage.style.display = 'block';
    const randomCode = uuidv4();
    const file = event?.target?.files?.[0];
    const formData = new FormData();
    formData.append('avatarImage', file);
    formData.append('randomCode', randomCode);
    formData.append('avatarType', IMAGE_TYPE.COMPANY);
    formData.append('id', `${companyDetails?.id}`);
    dispatch(uploadAvatar(formData));
  }

  const sortingOptions = [
    { label: 'Rule Name A-Z', value: 'az' },
    { label: 'Descending Date', value: 'date_desc' }
  ];
  
  const handleSortChange = (value: string) => {
    setSortOption(value)
  };

  useEffect(() => {
    const initialSort = () => {
      const sorted = [...useCaseRules].sort((a, b) => new Date(b.last_report).getTime() - new Date(a.last_report).getTime());
      setFilteredRules(sorted);
    };
    initialSort();
  }, [useCaseRules]);

  return (
    <ONE_COLUMN page='norrow'>
      <ParagraphTitle>Company Profile</ParagraphTitle>
      <INPUTS_GROUP_CARD title='General Info'>
        <INPUTS_GROUP_CARD_ROW label='LOGO' tooltip='Edit Company Logo'>
          <div className='editable-img company-img' onClick={openFileExplorer}>
            <img id='company-image-temp' className='company-image-temp' src='' alt='Company image' />
            {companyDetails.url !== '' ? (
              <img id='company-image' className='company-image' src={companyDetails.url + sas} alt='Company image' />
            ) : (
              <DEFAULT_AVATAR />
            )}
            <input type='file' ref={inputFile} className='file-input' onChange={handleImageUpload} />
          </div>
        </INPUTS_GROUP_CARD_ROW>
        <INPUTS_GROUP_CARD_ROW label='Company Name'>{companyDetails.company_name}</INPUTS_GROUP_CARD_ROW>
        <INPUTS_GROUP_CARD_ROW label='Joined'>{isValid(new Date(companyDetails.joining_date)) && format(new Date(companyDetails.joining_date), timing.FULLDATE)}</INPUTS_GROUP_CARD_ROW>
        <INPUTS_GROUP_CARD_ROW label='Renew Date'>{isValid(new Date(companyDetails.renew_date)) && format(new Date(companyDetails.renew_date), timing.FULLDATE)}</INPUTS_GROUP_CARD_ROW>
        <INPUTS_GROUP_CARD_ROW label='Sector'>{companyDetails.sector}</INPUTS_GROUP_CARD_ROW>
        <INPUTS_GROUP_CARD_ROW label='Time Zone'>{companyDetails.area_timestamp}</INPUTS_GROUP_CARD_ROW>
      </INPUTS_GROUP_CARD>
      <Space />
      <INPUTS_GROUP_CARD title='Users'>
        <Flex style='flex-wrap'>
          {companyUsers.map((user, index) => (
            <USER_CARD title={user.fullName} role={user.position} avatar={user.url + sas} key={index} />
          ))}
        </Flex>
      </INPUTS_GROUP_CARD>
      <Space />
      <div ref={monitoredDeviceRef}></div>
      <INPUTS_GROUP_CARD title='Monitored Device Type'>
        <table>
          <tbody>
            <tr>
              <th>Device type</th>
              <th>Count</th>
              <th>Status</th>
            </tr>
            {monitoredDevices.map((devices, index) => (
              <tr key={index}>
                <td>{devices.device_type}</td>
                <td>{devices.count}</td>
                <td>{devices.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </INPUTS_GROUP_CARD>
      <Space />
      <INPUTS_GROUP_CARD title='Source IP Range'>
        <table>
          <tbody>
            <tr>
              <th>IP</th>
              <th>Description</th>
            </tr>
            {companySourceIps.map((source, index) => (
              <SOURCE_IP source={source} key={index} />
            ))}
          </tbody>
        </table>
      </INPUTS_GROUP_CARD>
      <div ref={useCaseRef}></div>

      <div className='use-case-table-wrapper'>
      <INPUTS_GROUP_CARD>
        <div className='use-case-table-header'>
        <header>Rules table</header>
            <div className="search-container">
            <div className="search-icon">
              <ICONS.SEARCH_ICON />
            </div>
            <input type="text" value={searchInput} onChange={handleSearchChange} placeholder='Search Rule Name'/>
            <Dropdown
                withoutDispatch={true}
                name='task'
                onChange={handleSortChange}
                translate={false}
                value={null}
                options={sortingOptions}
                isDisabled={false}
                isMulty={false}
                hideSelectedOptions={false}
                customFormatLable={null}
                placeholder="SORT BY"
                with_all={false} formatOption={undefined} translate_prefix={''}             
                />

            </div>

      </div>

      <div className='use-case-table'>
      <table>
          <tbody>
            <tr>
              <th>RULE NAME</th>
              <th>STATUS</th>
              <th>SEVERITY</th>
              <th>LAST REPORT</th>
            </tr>
            {useCaseRules.length === 0 ? (
                <div>No rules to view for now.</div>
              ) : filteredRules.length ? (
                filteredRules.map((rule, index) => (
                  <tr key={index}>
                    <td className="tooltip">
                      <div className="text-content">{rule.rule_name}</div>
                      <span className="tooltiptext">
                        {
                          rule.rule_name
                        }
                      </span>
                    </td>
                    <td>{_.capitalize(rule.status)}</td>
                    <td>
                      <>
                        <div className={`severity-indicator ${rule.severity.toLowerCase()}`}></div>
                        {_.capitalize(rule.severity)}
                      </>
                    </td>
                    <td>{formatDate(rule.last_report)}</td>
                  </tr>
                ))
              ) : (
                <div>No matching results. Try searching a different rule name</div>
              )
            }
          </tbody>
        </table>
      </div>

      </INPUTS_GROUP_CARD>
      </div>
    </ONE_COLUMN>
  );
};
