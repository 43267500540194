import { dropdownOption, timing } from "constants/ui.constants";
import  _  from 'lodash';
import { differenceInCalendarDays, format } from 'date-fns';

export const compareDay = (date1:Date, date2:Date, gap:number) => {
    const result = differenceInCalendarDays(
        new Date(date1),
        new Date(date2)
    )
    return result === gap ? true : false;
}

export const dayTitle = (date:Date,yesterday:string,today:string) => {
    return compareDay(new Date(),date,0) ? 
    today : compareDay(new Date(),date,1) ? 
    yesterday : format(new Date(date), timing.DATE);
}

export const dayDevision = ():string => {
    const hours = new Date().getHours();   
    return hours > 17 ? 'evening' : hours > 11 ? 'afternoon' : 'morning';
}

export const truncateText = (text:string, length:number):string => {
    if(typeof text === "string"){
        return text.length > length ?
        text.substring(0, length-3) + "..." : text;
    }
    return text;
}

export const setDropdownOptions = (list:string[]):dropdownOption[] => {
    let options:dropdownOption[] = [];
    list.forEach((option:string) => {
        options.push({
            value:option,
            label:option
        })
    })
    return options;
}

export function _t(
    key:string |
     string[], translation:{[key:string]:string} = {}, prefix?:string
     ):string | {[key:string]:string}{
    if(_.isArray(key)){
        const ObjTranslations = {};
        key.forEach((name:string) => {
            ObjTranslations[name] = _.has(translation,prefix + name) ? translation[prefix+name] : name
        })
        return ObjTranslations;
    }
    return (_.has(translation,prefix + key)) ? translation[prefix+key] : key;
};