import React from 'react';
import _ from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';

import { lang_prefix, orderBy } from 'constants/ui.constants';
import { IncidentEnum, Incident } from 'constants/incident.constants';
import { setReportsSort } from "state/features/ui.features";
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { _t } from 'components/helpers';
import { SortHeader } from 'components/Layout/Shared/SortHeader';
import { formatValues, toogleTasks } from '../helpers';

export const IncidentsTable: React.FC<{
    sortedIncidents:any,
    filteredColumns:any}> = ({sortedIncidents,filteredColumns}) => {

    const dispatch = useAppDispatch();
    const translation = useAppSelector((state) => state.company.translation);
    const sort = useAppSelector((state) => state.ui.reports.sort);

    return (
        <div style={{'overflowX': 'auto','transform': 'rotateX(180deg)'}}>
        <table style={{'transform': 'rotateX(180deg)'}}>
            <thead>                
            <tr>                
                {
                    filteredColumns.map((column,index) => {
                        return ( 
                            <th key={index}>
                                <SortHeader
                                    header={{title:_t(column,translation,lang_prefix.INCIDENTS_PROPERTY), name:column}}
                                    active={{name:sort.name, orderByState:sort.orderByState}}
                                    handleChange={ (key:IncidentEnum,orderByState:orderBy) =>
                                        { dispatch(setReportsSort({name:key, orderByState:orderByState}))
                                    }}
                                />
                            </th>
                        )
                    })
                }                
            </tr>
            </thead>
            <tbody>
            {
                sortedIncidents.map((incident:Incident,index) => {
                    const toogleTasksProps = toogleTasks(incident.tasks, incident.id, dispatch);
                    return <tr key={index}>
                        {
                            filteredColumns.map((column,indexColumn) => {
                                const tooltip = (
                                    <span className="graph-custom-tooltip">
                                      {formatValues(column, incident[column],translation)}
                                    </span>
                                );
                                const trigger = incident[column] && typeof incident[column] === 'string' ? 'click' : [];
                                return ( 
                                    <td key={indexColumn}>
                                        {!_.isEqual(column,IncidentEnum.TASKS) ?
                                        <OverlayTrigger placement="left"  trigger={trigger} overlay={tooltip} rootClose>
                                            <span style={{cursor:'default'}}>{formatValues(column, incident[column],translation)}</span>
                                        </OverlayTrigger>
                                        : <span {...toogleTasksProps} className='link link-nav'>{formatValues(column, incident[column],translation)}</span>}
                                    </td>
                                )
                            })
                        } 
                    </tr>
                })
            }
            </tbody>
        </table>
        </div>
    )
}
