import React from 'react';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import { lang_prefix } from 'constants/ui.constants';
import { IncidentEnum, IncidentSeverityOrder, IncidentStatusOrder, Incident } from 'constants/incident.constants';
import { taskStatusOrder } from 'constants/tasks.constants';
import { setReportsFilter, setReportsColumns } from "state/features/ui.features";
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { setDropdownOptions, _t } from 'components/helpers';
import { Dropdown } from 'components/Layout/Shared/Dropdown';
import { DOWNLOAD } from 'assets/icons/General';
import { formatValues } from '../helpers';
import { IncidentsCls } from 'class/class.incidents';
import { DateFilter } from '../DateFilter';

export const FiltersRow: React.FC<{
    incidents:Incident[],
    sortedIncidents:Incident[],
    filteredColumns:IncidentEnum[]}> = ({incidents, sortedIncidents, filteredColumns}) => {
    
    const dispatch = useAppDispatch();
    const filter = useAppSelector((state) => state.ui.reports.filter);
    const translation = useAppSelector((state) => state.company.translation);
    
    const defaultProps = {
        with_all:true,
        translate:true,
        withoutDispatch:true,
    }

    const filters = [
        {
            name:IncidentEnum.SEVERITY,
            translate_prefix:lang_prefix.INCIDENTS_SEVIRITY,
            options: setDropdownOptions(IncidentSeverityOrder)
        },
        {
            name:IncidentEnum.STATUS,
            translate_prefix:lang_prefix.INCIDENTS_STATUS,
            options:setDropdownOptions(IncidentStatusOrder)
        },
        {
            name:IncidentEnum.OWNER,
            translate_prefix:lang_prefix.CONTROLROOM_FILTER,
            options:setDropdownOptions(IncidentsCls.uniques(IncidentEnum.OWNER, incidents))
        },
        {
            name:IncidentEnum.TASKS,
            translate_prefix:lang_prefix.TASK_STATUS,
            options:setDropdownOptions(taskStatusOrder)
        },
        {
            name:IncidentEnum.CLOSING_REASON,
            translate_prefix:lang_prefix.API_FILTER_TITLES,
            options:setDropdownOptions(IncidentsCls.uniques(IncidentEnum.CLOSING_REASON, incidents))
        },

    ]

    return (
        <header>
            <Dropdown
                withoutDispatch={true}
                placeholder={ _t('all_columns', translation, lang_prefix.API_FILTER_TITLES) }
                name='columns'
                value={'all_columns'}
                onChange={(column) => { dispatch(setReportsColumns(column)) }}
                customFormatLable = {(option)=>{
                    return <div className={`${_.includes(filteredColumns,option.value) ? ' select-box__option--is-multy-selected':''}`}>
                            { _t(option.label, translation, lang_prefix.INCIDENTS_PROPERTY) }
                        </div>
                }}
                options={setDropdownOptions(_.values(IncidentEnum))}
            />
            <DateFilter
                label='From'
                uiDate={filter.start_date}
                dateKey='start_date'
                handleDispatch={setReportsFilter}
            />
            <DateFilter
                label='To'
                dateKey='end_date'
                uiDate={filter.end_date}
                handleDispatch={setReportsFilter}
            />
            {
                filters.map((filterItem,index) => {
                    const props = {
                        ...filterItem,
                        ...defaultProps,
                        value:{ 
                            value:filter[filterItem.name],
                            label:_t(filter[filterItem.name], translation, filterItem.translate_prefix)
                        },
                        onChange: (value:string) => { 
                            dispatch(setReportsFilter({name:filterItem.name, value:value}))
                        }
                    };
                    return <Dropdown key={index} { ...props } />
                })
            }
            <CSVLink
                className='download-button'
                data={prepearDownload(sortedIncidents, filteredColumns)}
            >
                <div className='button'>CSV <DOWNLOAD/></div>
            </CSVLink>
        </header>
    )
}

export const prepearDownload = (sortedIncidents:Incident[],filteredColumns:IncidentEnum[]) => {
    const translation = useAppSelector((state) => state.company.translation);
    let results:{[key:string]:string}[] = [];
    for(let i = 0 ; i < sortedIncidents.length; i++){
        let incidentObject = {};
        filteredColumns.forEach((name) => {
            let customName = _t(name,translation,lang_prefix.INCIDENTS_PROPERTY)
            incidentObject[customName] = formatValues(name, sortedIncidents[i][name],translation);   
        })
        results.push(incidentObject)
    }
    return results;
}