import React from "react";
import Select from 'react-select';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { _t } from 'components/helpers';

export const Dropdown: React.FC<{
    onChange:any,
    isDisabled:boolean,
    withoutDispatch:boolean,
    formatOption:any,
    value:any,
    options:any,
    translate:boolean
    translate_prefix:string,
    with_all:boolean,
    name:string,
    isMulty:boolean,
    hideSelectedOptions:boolean,
    customFormatLable:any,
    placeholder?:string,
}> = (props):JSX.Element => {
    
    const dispatch = useAppDispatch();
    const translation = useAppSelector((state) => state.company.translation)
    const options = props.with_all ?
    [{ label:'all_'+props.name, value:'all_'+props.name }, ...props.options] :
    props.options;
    
    return <Select
        isMulti={props.isMulty}
        hideSelectedOptions={props.hideSelectedOptions}
        placeholder={props.placeholder || ''}
        isSearchable={false}
        isDisabled={props.isDisabled}
        classNamePrefix='select-box'
        className="select-box-container"
        isClearable={false}
        onChange={(option) => {
            if(!props.withoutDispatch){
                dispatch(props.onChange(option.value))
            }else{
                props.onChange(option.value)
            }
        }}
        formatOptionLabel={(option) => {
            if(props.translate){
                return _t(option.label,translation,props.translate_prefix)
            }
            if(props.customFormatLable){
                return props.customFormatLable(option);
            }
            return option.label;
        }}        
        value={props.value}
        options={options}
    />
}
