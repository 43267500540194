import { createSlice } from '@reduxjs/toolkit';

import { INotification } from 'constants/notifications.constant';
import _, { isEqual } from 'lodash';

interface IUiState {
  loading: boolean;
  error: boolean;
  items: INotification[];
  intervalNotifications: INotification[];
  allowDesktopNotifications: boolean;
  displayOptionToAllow: boolean;
}

const initialState: IUiState = {
  loading: false,
  error: false,
  items: [],
  intervalNotifications:[],
  allowDesktopNotifications: false,
  displayOptionToAllow: true
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload;
    },
    setIntervalNotifications: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      const newItems = payload.IntervalNotifications || [];
      state.intervalNotifications = _.unionWith(state.intervalNotifications, newItems, isEqual);
    },
    setError: (state) => {
      state.error = true;
    },
    setAllowDesktopNotifications: (state) => {
      state.allowDesktopNotifications = true;
      state.displayOptionToAllow = false;
    },
    setDisplayOptionToAllow: (state) => {
      state.displayOptionToAllow = false;
    },
    updateIntervalNotifications: (state, { payload }) => {
      state.intervalNotifications.splice(payload, 1);
    }
  }
});

export const { 
  setLoading,
  setItems,
  setIntervalNotifications,
  setError,
  setAllowDesktopNotifications,
  setDisplayOptionToAllow,
  updateIntervalNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
