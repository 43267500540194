import React from 'react';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { setPopupDisplay } from 'state/features/ui.features';
import { ProviderProps } from 'constants/ui.constants';

export const PopUp: React.FC<ProviderProps> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const popupDisplay = useAppSelector((state) => state.ui.navHeader.popupDisplay);

  return (
    <>
      {popupDisplay && (
        <div className='popup'>
          <div className='popup-close' onClick={() => dispatch(setPopupDisplay())}>
            <p>&#43;</p>
          </div>
          <div className='container'>
            <>{props.children}</>
          </div>
        </div>
      )}
    </>
  );
};
