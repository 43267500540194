import _ from 'lodash';

import { Task, taskStatus, TaskEnum, taskStatusOrder, taskPriorityOrder } from 'constants/tasks.constants';
import { Incident } from 'constants/incident.constants';
import { orderBy } from 'constants/ui.constants';

export class TasksCls {
    private tasks:Task[];

    constructor(tasks:Task[] = []){
        this.tasks = [...tasks];
    }

    get amount():number{
        return this.tasks.length;
    }

    get open():number{
        return _.filter(this.tasks,{status:taskStatus.OPEN}).length;
    }

    sortBy(name:TaskEnum, orderByState:orderBy ):Task[] {
        let results:Task[] = [];
        const customOrder = (name === TaskEnum.priority) ?
        [...taskPriorityOrder]: [...taskStatusOrder];
        const ObjGrouping = _
        .chain(this.tasks)
        .orderBy([TaskEnum.created_at],orderByState)
        .groupBy(name)
        .value();
        
        if(_.includes([TaskEnum.priority,TaskEnum.status],name)){
            const _customOrder = orderByState === orderBy.ASC ?
            customOrder : customOrder.reverse();
            _customOrder.forEach(term => {
                if(_.has(ObjGrouping,term)){
                    results = _.concat(results,ObjGrouping[term])
                }
            })
            _.keys(ObjGrouping).forEach((key) => {
                if(!_.includes(customOrder,key)){
                    results = _.concat(results,ObjGrouping[key])
                }
            })
        }else{
            results = _.orderBy(this.tasks, name, orderByState);
        }

        return results;
    }

    groupedBy(key:keyof Task):{[key:number]:Task[]}{
        return  _.groupBy(this.tasks,key);
    }

    getById(id:number){
       return _.filter(this.tasks,(task:Task) => task.incident_id === id);
    }

    static openTasks = (tasks:Task[]): Task[] => {
        return _.filter(tasks, function(task){ return task.status !== taskStatus.CLOSE} )
    }

    static closeTasks = (tasks:Task[]): Task[] => {
        return _.filter(tasks, function(task){ return task.status === taskStatus.CLOSE} )
    }
}