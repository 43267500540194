
import * as ICONS from 'assets/icons/Incidents';
import { Task } from 'constants/tasks.constants';

export enum IncidentStatus  {
  OPEN = 'open',
  TIME_TO_ASSIGN = 'time to assign',
  TIME_TO_RESPONSE = 'time to response',
  TUNING = 'tuning',
  IN_PROGRESS = 'in progress',
  WAITING = 'waiting',
  CLOSE = 'close'
}
export enum MainIncidentStatus {
  OPEN = 'open',
  TIME_TO_ASSIGN = 'time to assign',
  TIME_TO_RESPONSE = 'time to response',
  CLOSE = 'close'
}

export enum IncidentSubStatus {
  IN_PROGRESS = 'in progress',
  WAITING = 'waiting',
  TUNING = 'tuning',
}

export const IncidentStatusOrder:IncidentStatus[] = [
  IncidentStatus.OPEN,
  IncidentStatus.IN_PROGRESS,
  IncidentStatus.TUNING,
  IncidentStatus.WAITING,
  IncidentStatus.CLOSE
]

export const IncidentStatusTitles = {
  [IncidentStatus.OPEN]:'Open',
  [IncidentStatus.IN_PROGRESS]:'In Progress',
  [IncidentStatus.TUNING]:'Tuning',
  [IncidentStatus.WAITING]:'Waiting',
  [IncidentStatus.CLOSE]:'Closed',
}

export const IncidentStatusTitlesTimeline = {
  [IncidentStatus.OPEN]:'Open',
  [IncidentStatus.IN_PROGRESS]:'In Progress',
  [IncidentStatus.TUNING]:'Tuning',
  [IncidentStatus.WAITING]:'Waiting',
  [IncidentStatus.CLOSE]:'Closed',
  [IncidentStatus.TIME_TO_ASSIGN]:'Time to assign',
  [IncidentStatus.TIME_TO_RESPONSE]:'Time to response',
}

export enum IncidentSeverity {
  INFO = 'info',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}
export const IncidentSeverityOrder:IncidentSeverity[] = [
  IncidentSeverity.CRITICAL,
  IncidentSeverity.HIGH,
  IncidentSeverity.MEDIUM,
  IncidentSeverity.LOW,
  IncidentSeverity.INFO,
]

export const IncidentSeverityTitles = {
  [IncidentSeverity.CRITICAL]:'Critical',
  [IncidentSeverity.HIGH]:'High',
  [IncidentSeverity.MEDIUM]:'Medium',
  [IncidentSeverity.LOW]:'Low',
  [IncidentSeverity.INFO]:'Info',
}

export type IncidentFieldsForDisplay = 
'severity' | 
'status' |
'closing_reason' | 
'title' | 
'created_at' | 
'category_name' | 
'updated_at' | 
'owner' | 
'tasks' | 
'id' |
'external_id';

export type partialIncident = Pick<Incident,IncidentFieldsForDisplay>;

export const IncidentAmountPerColumn:number = 6;

export interface Incident {
    is_visible?: boolean;
    id:number
    external_id: number// for know only like jira ticket
    incident_name: string
    severity: IncidentSeverity
    closing_reason?:string 
    status?: IncidentStatus 
    category_name?: string
    title: string
    summary?: string
    description?: string
    mitre_tactic?: string
    investigation?: string
    owner?: string
    origin?: string
    host_name?: string
    created_at: Date
    updated_at: Date
    last_update?: Date
    renew_date?: Date
    source_user?: string
    destination_user?: string
    analyst_name?: string
    source_ip?: string
    destination_ip?: string
    source_geo?: string
    destination_geo?: string
    active?: boolean
    sector?: string
    sla_assign_minutes?: number
    sla_initial_triage: Date
    sla_time_to_resolve: Date
    remediation_action:number
    tasks:Task[]
}

export interface IncidentStatusUpdate {

  id:number
  external_id: number
  incident_id: number
  status: IncidentStatus 
  sub_status?: IncidentSubStatus 
  created_at: Date
  updated_at?: Date
  is_old_incident?: any
}

export enum IncidentEnum {
  CREATED_AT = 'created_at',
  TASKS = 'tasks',
  SEVERITY = 'severity',
  CATEGORY_NAME = 'category_name',
  TITLE = 'title',
  EXTERNAL_ID = 'external_id',
  STATUS = 'status',
  CLOSING_REASON = 'closing_reason',
  DESCRIPTION = 'description',
  INVESTIGATION = 'investigation',
  OWNER = 'owner',
  SOURCE_IP ='source_ip',
  DESTINATION_IP ='destination_ip',
  SOURCE_GEO = 'source_geo',
  DESTINATION_GEO = 'destination_geo',
  SOURCE_USER = 'source_user',
  DESTINATION_USER = 'destination_user',
  ORIGIN = 'origin',
  ID = 'id',
  MITER_TACTIC = 'mitre_tactic', 
  HOST_NAME = 'host_name', 
  SUMMARY = 'summary',
}



export enum IncidentsClosingReason {
  FALSE_POSITIVE_RATE = 'False Positive',
}

export enum IncidentsCategories  {
  Compliance = 'Compliance',
  'Cloud Activity' = 'Cloud Activity',
  'Cloud Threat' = 'Cloud Threat',
  'TH Incident' = 'TH Incident',
  'TH Summary' = 'TH Summary',
  'Endpoint Threat' = 'Endpoint Threat',
  'Malicious Delivery' = 'Malicious Delivery',
  Reconnaissance = 'Reconnaissance',
  'Actions on Objectives' = 'Action on Objectives',
  'Command & Control' = 'Command & Control',
  CTI = 'CTI',
  Exploitation = 'Exploitation',
  'Fraud / Extortion' = 'Fraud / Extortion',
  'Asset Status' = 'Asset Status',
  DDoS = 'DDoS',
  DoS = 'DoS',
  'Sabotage / Destruction' = 'Sabotage / Destruction',
  'OT Security' = 'OT Security',
  uncategorized = 'Uncategorized'
}

export const Components = {
  uncategorized : ICONS.UNCATEGORIZED,
  'Asset Status' : ICONS.ASSETS_STATUS,
  CTI : ICONS.CTI,
  DDoS : ICONS.DDOS,
  'OT Security' : ICONS.OT_SECURITY,
  'Sabotage / Destruction' : ICONS.SABOTAGE_DESCRUTION,
  Compliance: ICONS.COMPLIANCE,
  'Endpoint Threat':ICONS.ENDPOINT_THREAT,
  'Malicious Delivery':ICONS.MALICIOS_DELIVERY,
  Reconnaissance:ICONS.REONNAISSANCE,
  'Actions on Objectives':ICONS.ACTION_ON_OBJECTIVES,
  'Cloud Activity':ICONS.COMPLIANCE,
  'Cloud Threat':ICONS.ENDPOINT_THREAT,
  'TH Incident': ICONS.UNCATEGORIZED,
  'TH Summary': ICONS.UNCATEGORIZED,
  'Command & Control': ICONS.COMMAND_CONTROL     
}

export enum activeTabs {
  INEVESTIGATION = 'investigation',
  CHART = 'chart'
}

export enum reportsTabs {
  EXPORT_EXCEL = 'exportExcel',
  EXECUTIVE_REPORT = 'executiveReport'
}

export const activeIncidentProp = [
  IncidentEnum.ID,
  IncidentEnum.EXTERNAL_ID,
  IncidentEnum.ORIGIN,
  IncidentEnum.MITER_TACTIC,
  IncidentEnum.SOURCE_IP,
  IncidentEnum.DESTINATION_IP,
  IncidentEnum.SOURCE_USER,
  IncidentEnum.DESTINATION_USER,
  IncidentEnum.STATUS,
  IncidentEnum.HOST_NAME,
  IncidentEnum.CATEGORY_NAME,
  IncidentEnum.SEVERITY,
]

export const readonlyIncidentProp = [
  IncidentEnum.ID,
  IncidentEnum.EXTERNAL_ID,
  IncidentEnum.CATEGORY_NAME,
  IncidentEnum.SEVERITY
]

export const defaultIncidentColumns = [
  IncidentEnum.CREATED_AT,
  IncidentEnum.TASKS,
  IncidentEnum.SEVERITY,
  IncidentEnum.CATEGORY_NAME,
  IncidentEnum.TITLE,
  IncidentEnum.EXTERNAL_ID,
  IncidentEnum.STATUS,
  IncidentEnum.CLOSING_REASON,  
]