import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { v4 as uuidv4 } from 'uuid';

import { setPopupDisplay } from 'state/features/ui.features';
import { createInsight, fetchInsights } from 'services/insights.services';
import { LineSeparator } from 'components/Layout/Shared/Elements';
import { IMAGE_TYPE } from 'constants/db.constants';

export const InsightForm: React.FC<{}> = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const maxFileSize = 2000000;
  const defaultPriority = '1';
  const companyName = useAppSelector((state) => state.company.companyName);
  const [insightForm, setInsightForm] = useState({
    title: '',
    description: '',
    priority: defaultPriority,
  });
  const [fieldMissing, setFieldMissing] = useState(false);
  const [success, setSuccess] = useState(false);

  function checkMissingFields(event) {
    event.preventDefault();
    resetFormStates();
    if (
      insightForm.title === '' ||
      insightForm.description === '' ||
      insightForm.priority === ''
    ) {
      setFieldMissing(true);
    } else {
      submitNewInsight(event);
    }
  }

  function resetFormStates() {
    setSuccess(false);
    setFieldMissing(false);
  }

  async function submitNewInsight(event) {
    const insightImage = document.getElementById('insight-image') as any;
    const randomCode = uuidv4();
    const file = insightImage?.files?.[0];
    const formData = new FormData();
    formData.append('avatarImage', file);
    formData.append('randomCode', randomCode);
    formData.append('title', insightForm.title);
    formData.append('description', insightForm.description);
    formData.append('priority', insightForm.priority);
    await dispatch(createInsight(formData));
    event.target.id === 'saveAndNew' ? resetForm() : closePopupAndFetchInsights();
  }

  function closePopupAndFetchInsights() {
    dispatch(setPopupDisplay());
    dispatch(fetchInsights());
  }

  function resetForm() {
    setSuccess(true);
    setInsightForm({ title: '', description: '', priority: '1' });
  }


  return (
    <>
      <div className='form-wrapper insight-form'>
        <h2 className='form-header insight-form-header'>Create a new insight</h2>
        <form>
          <div className='form-group'>
            <label>*Insight title</label>
            <input
              required
              type='text'
              className='form-input'
              name='title'
              value={insightForm.title}
              onChange={(e) => setInsightForm({ ...insightForm, title: e.target.value })}
            />
          </div>
          <div className='form-group'>
            <label>*Insight description</label>
            <textarea
              required
              className='form-input'
              rows={3}
              name='description'
              value={insightForm.description}
              onChange={(e) => setInsightForm({ ...insightForm, description: e.target.value })}
            ></textarea>
          </div>
          <div className='form-group'>
            <label>*Priority</label>
            <select
              required
              className='form-input'
              name='priority'
              value={insightForm.priority}
              onChange={(e) => setInsightForm({ ...insightForm, priority: e.target.value })}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div className='submit-buttons'>
            <div className='submit-button insight-submit' onClick={checkMissingFields} id='saveAndNew'>
              Save &#38; Create new
            </div>
            <div className='submit-button insight-submit' onClick={checkMissingFields} id='saveAndClose'>
              Save &#38; Close
            </div>
          </div>
          <div className='submit-response'>
            {fieldMissing && <p className='form-message error'>All fields are required</p>}
            {success && <p className='form-message success'>Form was sent successfully</p>}
          </div>
        </form>
      </div>
    </>
  );
};
