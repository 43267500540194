export const globalEndpoints = (function () {
  const host = document.location.host;

    if (host.includes('localhost')) {
      return {
        login: 'https://trustnetplatformdev.b2clogin.com',
        api: 'http://localhost:8080',
        app: 'http://localhost:9000',
        authorityDomain: 'https://trustnetplatformdev.b2clogin.com',
        tenantId: '438ede12-e64c-48b8-a0ba-9de2d52c762c',
        clientId: 'f4dc84a0-755d-418e-ab88-518df0ec1b15'
      }
    } else if (host.includes('proud-ocean')) {
      return {
        login: 'https://trustnetplatformdev.b2clogin.com',
        api: 'https://app-trustnet-server-dev-d7b0bdfraceqhjat.israelcentral-01.azurewebsites.net',
        app: 'https://proud-ocean-059af3003.5.azurestaticapps.net',
        authorityDomain: 'https://trustnetplatformdev.b2clogin.com',
        tenantId: '438ede12-e64c-48b8-a0ba-9de2d52c762c',
        clientId: 'f4dc84a0-755d-418e-ab88-518df0ec1b15'
      }
    } else if (host.includes('dev-platform.tsoc')) {
        return {
          login: 'https://trustnetplatformdev.b2clogin.com',
          api: 'https://api.dev-platform.tsoc.co.il',
          app: 'https://dev-platform.tsoc.co.il',
          authorityDomain: 'https://trustnetplatformdev.b2clogin.com',
          tenantId: '438ede12-e64c-48b8-a0ba-9de2d52c762c',
          clientId: 'f4dc84a0-755d-418e-ab88-518df0ec1b15'
        }
    } else
      return {
        login: 'https://trustnetplatform.b2clogin.com',
        api: 'https://api.platform.tsoc.co.il/',
        app: 'https://platform.tsoc.co.il',
        authorityDomain: 'https://trustnetplatform.b2clogin.com',
        tenantId: 'bedd68be-10bd-4551-ab01-8329b7ced6e4',
        clientId: '10a27b48-0e42-48a7-b08b-90e1b5d55336'
      }
  })()