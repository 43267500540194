import React from 'react';
import _ from 'lodash';

import { useAppSelector } from 'hooks/redux.hooks';
import { TasksList } from './TasksList';

export const RightSide: React.FC = () => {
    const tasks = useAppSelector((state) => state.tasks.items);
    const incidents = useAppSelector((state) => state.incidents.items);
    return <TasksList tasks={tasks} incidents={incidents}/>;
}