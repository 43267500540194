import React from 'react';
import _ from 'lodash';
import { EditText } from 'react-edit-text';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';

import { SourceIp } from 'constants/company.constants';
import { updateSourceIp } from 'services/company.services';
import { userRoles } from 'state/features/global.features';

export const SOURCE_IP: React.FC<{ source: SourceIp }> = ({ source }): JSX.Element => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.global.userRole);

  function approveEdit(editText: any, source: SourceIp) {
    const textToUpdate =
      editText.name === 'ip'
        ? { ip: editText.value }
        : editText.name === 'description'
        ? { description: editText.value }
        : {};
    dispatch(updateSourceIp({ ...source, ...textToUpdate }));
  }

  return (
    <tr>
      <td>
        <EditText
          inline={true}
          readonly={_.isEqual(userRole,userRoles.USER)}
          name='ip'
          defaultValue={source.ip}
          placeholder={source.ip}
          inputClassName='editable-text'
          onSave={(ip) => {
            approveEdit(ip, source);
          }}
        />
      </td>
      <td>
        <EditText
          inline={true}
          readonly={_.isEqual(userRole,userRoles.USER)}
          name='description'
          defaultValue={source.description}
          placeholder={source.description}
          inputClassName='editable-text'
          onSave={(description) => {
            approveEdit(description, source);
          }}
        />
      </td>
    </tr>
  );
};
