export interface Task {
    id:number,
    status?: taskStatus
    priority?: string
    title: string
    summary: string
    description: string
    owner: string
    is_relevant: boolean
    created_at: Date
    updated_at: Date
    incident_id:number,
    last_updating_user:string,
    comments?: Comment[]
  }

  export interface Comment {
    task_id: number
    incident_id?: number
    external_id?: number
    last_updating_user: ReactNode
    created_at: string | number | Date
    id: number,
    name: string,
    comment: string
    date: Date
  }

  export enum TaskEnum {
    ID = 'id',    
    status = 'status',
    priority = 'priority',
    title =  'title',
    summary =  'summary',
    description =  'description',
    owner = 'owner',
    is_relevant =  'is_relevant',
    created_at = 'created_at',
    update_at =  'update_at',
    incident_id = 'incident_id'
  }

  export enum taskStatus {
    OPEN = 'open',
    IN_PROGRESS = 'in progress',
    CLOSE = 'close',
  }

  export const taskStatusLabels: Record<taskStatus, string> = {
    [taskStatus.OPEN]: 'Status',
    [taskStatus.CLOSE]: 'Approved',
    [taskStatus.IN_PROGRESS]: 'Declined',
};

  export const taskStatusOrder = [
    taskStatus.OPEN,
    taskStatus.IN_PROGRESS,
    taskStatus.CLOSE
  ]

  export enum taskPriority {
    HIGH = 'high',
    MEDIUM = 'medium',
    LOW = 'low'
  }

  export const taskPriorityOrder = [
    taskPriority.HIGH,
    taskPriority.MEDIUM,
    taskPriority.LOW
  ]

