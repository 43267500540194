import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { lang_prefix } from 'constants/ui.constants';
import { setError, setLoading, setSuccess } from 'state/features/contact.features';
import { _t } from 'components/helpers';
import { sendEmail } from 'services/contact.service';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as ICONS from 'assets/icons/General';
import { TinyText } from './Elements';

export const ContactForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.global.user);
  const [form, setForm] = useState({ title: '', description: '' });
  const translation = useAppSelector((state) => state.company.translation);
  const contact = useAppSelector((state) => state.contact);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((status) => !status);

  useEffect(() => {
    if (contact.success) {
      dispatch(setSuccess(false));
        setForm({ title: '', description: '' });
        toggleShow(); 
    }
  }, [contact]);

  function sendContactForm(event: any) {
    event.preventDefault();
    dispatch(setError(false));
    dispatch(sendEmail({ user, ...form }));
  }
  return (
<>
  <div onClick={toggleShow}><ICONS.CHAT /></div>
  <Offcanvas show={show} onHide={handleClose}>
  <Offcanvas.Header closeButton></Offcanvas.Header>
  <Offcanvas.Body>
    <div className='menu-modal-wrapper'>
      <p className='form-header'>Contact Us</p>
      <Form validated={false} onSubmit={sendContactForm}>
        <Form.Group className='mb-3 form-group' controlId='title'>
          <Form.Label>
            <>{_t('title', translation, lang_prefix.CONTACT_LABELS)}</>
          </Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Title'
            className='form-input'
            name='title'
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </Form.Group>
        <Form.Group className='mb-3 form-group textarea' controlId='description'>
          <Form.Label>
            <>{_t('description', translation, lang_prefix.CONTACT_LABELS)}</>
          </Form.Label>
          <Form.Control
            required
            as='textarea'
            placeholder='Tell us how we can help you...'
            className='form-input textarea'
            rows={6}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
          />
        </Form.Group>
        <Button variant='primary' type='submit' className='submit-button' disabled={contact.loading}>
          Submit
        </Button>
        {contact.error && <p><TinyText style='error-message'>{contact.error}</TinyText></p>}
      </Form>
    </div>
    </Offcanvas.Body>
  </Offcanvas>
  </>
  );
};
