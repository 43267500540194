import {
  API_GET_MONITORED_DEVICE_NUMBER,
  API_GET_MONITORED_DEVICES,
  API_GET_SLA,
  API_GET_COMPANY_CONFIGURATION,
  API_GET_COMPANY,
  API_COMPANY_USERS,
  API_GET_SOURCE_IP,
  API_UPDATE_SOURCE_IP,
  API_GET_ADMIN_COMPANIES,
  API_GET_All_USE_CASE_RULES
} from 'constants/db.constants';
import {
  setError,
  setMonitoredDeviceNumber,
  setMonitoredDevices,
  setSLA,
  setLoading,
  setConfiguration,
  setTranslation,
  setDetails,
  setCompanyUsers,
  setSourceIps,
  updateSourceIps,
  setAdminCompanies,
  setUseCaseRules
} from 'state/features/company.features';
import { translation } from 'constants/ui.constants';
import API from '../services/api.service';
import { SourceIp } from 'constants/company.constants';

export function fetchConfiguration() {
  return async (dispatch:any,getState:any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_GET_COMPANY_CONFIGURATION, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setConfiguration(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function fetchMonitoredDeviceNumber() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_MONITORED_DEVICE_NUMBER, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setMonitoredDeviceNumber(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function fetchMonitoredDevices(company: string) {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_GET_MONITORED_DEVICES, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setMonitoredDevices(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}
export function fetchUseCaseRules() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_GET_All_USE_CASE_RULES, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setUseCaseRules(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function fetchSLA() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_SLA, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setSLA(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}
//TODO: replace with wordpress API
export function fetchTranslation() {
  return (dispatch: any, getState:any) => {
    dispatch(setTranslation(translation));
  };
}

export function fetchCompanyDetails() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_GET_COMPANY, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setDetails(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function fetchCompanyUsers() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_COMPANY_USERS, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setCompanyUsers(response.data.users));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function fetchSourceIps() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_GET_SOURCE_IP, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setSourceIps(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function updateSourceIp(item: SourceIp) {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .post(API_UPDATE_SOURCE_IP + API.addQueryWithCompany(getState), item)
      .then((response) => {
        dispatch(updateSourceIps(response.data[0]));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}

export function fetchAdminCompanies() {
  return async (dispatch: any) => {
    dispatch(setLoading());
    API.getInstance()
      .get(API_GET_ADMIN_COMPANIES)
      .then((response) => {
        dispatch(setAdminCompanies(response.data));
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}
