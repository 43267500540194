import React from 'react';

import { infoBox } from 'constants/ui.constants';
import { togglePage } from 'state/features/ui.features';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import * as ICONS from 'assets/icons/General';

export const InfoBoxes: React.FC<{ list:infoBox[] }>= ({ list }) => {
    return (
        <div className='infoBoxs'>
        {list.map((infoBox,index)=>
            <InfoBox item={infoBox} key={index}/>
        )}
        </div>
    )
}

export const InfoBox: React.FC<{ item:infoBox }>= ({ item }) => {
    const dispatch = useAppDispatch();
    const _tooglePage = (key:string) => {dispatch(togglePage(key))}
    return (
        <div className='infoBox' onClick={(e)=> {_tooglePage(item.linkTo)}}>
            <div className='content'>
                <div className='title'>{item.title}</div>
                <div className='text'>{item.text}</div>
            </div>
            <div className='infoBox-link'>
                <ICONS.CHEVRON_RIGHT/>
            </div>
        </div>
    )
}