import React, { useRef } from 'react';
import { useAppDispatch } from 'hooks/redux.hooks';

import { ProviderProps } from 'constants/ui.constants';
import * as ICONS from 'assets/icons/General';
import { InsightStatusBadge } from 'components/Pages/Insights/InsightStatus';
import { insightStatus, userPreference } from 'constants/insights.constants';

export const GALLERY_SLIDER: React.FC<{
  selected: any;
  title: string;
  list: any[];
  selectItem: any;
  amountPerPage: number;
}> = ({ selected, title, list, selectItem, amountPerPage }) => {
  const dispatch = useAppDispatch();
  const galleryRef = useRef(null);
  const galleryContainerRef = useRef(null);
  const actionBackRef = useRef(null);
  const actionForwardRef = useRef(null);
  const galleryPage = useRef({
    page: 0,
    total: list.length,
    amountForPage: amountPerPage
  });

  function toogleGallery(action: string) {
    galleryPage.current.page = action === 'back' ? galleryPage.current.page - 1 : galleryPage.current.page + 1;
    const galleryWidth = galleryContainerRef.current.offsetWidth;
    galleryRef.current.style.left = -(galleryPage.current.page * galleryWidth) + 'px';
    toggleArrowDisplay('back', actionBackRef);
    toggleArrowDisplay('forward', actionForwardRef);
  }

  function toggleArrowDisplay(action: string, arrow: React.ElementRef) {
    if (!needToDisplayArrow(action)) {
      arrow.current.classList.add('hidden');
    } else {
      arrow.current.classList.remove('hidden');
    }
  }

  function needToDisplayArrow(action: string): boolean {
    let display: boolean = true;
    if (list.length <= galleryPage.current.amountForPage) return false;

    if (action === 'back') {
      if (galleryPage.current.page === 0) return false;
    } else {
      if ((galleryPage.current.page + 1) * galleryPage.current.amountForPage >= list.length) {
        return false;
      }
    }
    return display;
  }

  return (
    <GALLERY_SLIDER_WRAP title={title} forwardRef={galleryPage}>
      <SLIDER_ARROW
        action={'back'}
        forwardRef={actionBackRef}
        onToggle={toogleGallery}
        display={needToDisplayArrow('back')}
      />
      <div className='items-gallery-list-container' ref={galleryContainerRef}>
        <div className='items-gallery-list' ref={galleryRef}>
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className={`item-gallery ${item.id === selected.id ? 'selected' : ''}`}
                onClick={() => selectItem(item, dispatch)}
              >
                <div className='item-gallery-header'>
                  <div
                    className={`left ${
                      item.user_preference === userPreference.INTERESTED
                        ? 'has-status'
                        : ''
                    }`}
                  >
                    <div className='item-icon'>
                      <ICONS.INSIGHTS_DEFAULT />
                    </div>
                    <div className='item-title'>{item.title}</div>
                  </div>
                  { item.user_preference === userPreference.INTERESTED && <InsightStatusBadge item={item} /> }
                </div>
                <div className='item-description'>{item.description}</div>
              </div>
            );
          })}
        </div>
      </div>
      <SLIDER_ARROW
        action={'forward'}
        forwardRef={actionForwardRef}
        onToggle={toogleGallery}
        display={needToDisplayArrow('forward')}
      />
    </GALLERY_SLIDER_WRAP>
  );
};

const SLIDER_ARROW: React.FC<{ action: string; forwardRef: any; display: boolean; onToggle: any }> = (props) => {
  return (
    <div
      className={`arrow-gallery-${props.action} ${props.display ? '' : 'hidden'}`}
      ref={props.forwardRef}
      onClick={(e) => {
        props.onToggle(props.action);
      }}
    ></div>
  );
};

const GALLERY_SLIDER_WRAP: React.FC<{ title: string; children: ProviderProps }> = (props) => {
  return (
    <div className='slider-gallery'>
      <div className='items-gallery-title-wrap'>
        <div className='items-gallery-title'>{props.title}</div>
      </div>
      <div className='items-gallery-list-wrap'>{props.children}</div>
    </div>
  );
};

const GALLERY_SLIDER_PAGINATION_WRAP: React.FC<{ title: string; children: ProviderProps; forwardRef: any }> = (
  props
) => {
  return (
    <div className='slider-gallery-pagination'>
      <div className='items-gallery-title-wrap'>
        <div className='items-gallery-title'>{props.title}</div>
        <GALLERY_PAGINATION forwardRef={props.forwardRef} />
      </div>
      <div className='items-gallery-list-wrap'>{props.children}</div>
    </div>
  );
};
const GALLERY_PAGINATION: React.FC<{ forwardRef: any }> = (props) => {
  return (
    <div className='item-gallery-pagination'>
      {props.forwardRef.current.amountForPage * (props.forwardRef.current.page + 1)} / {props.forwardRef.current.total}
      <SLIDER_ARROW action='back' />
    </div>
  );
};

/** TODO : convert ref to useState like GallerySliderPagination when start working on Insights page */
