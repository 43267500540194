import _ from 'lodash';

import { infoBox,pagesEnum } from 'constants/ui.constants';

export class InfoBoxesCls {
    infoBoxes:infoBox[];

    constructor(){
        this.infoBoxes = [];
    }

    setInfoBox( title:string, text:string, linkTo:pagesEnum ){
        this.infoBoxes.push({
            title:title,
            text:text,
            linkTo:linkTo
        })
    }

    get getAll(){
        return this.infoBoxes;
    }

}