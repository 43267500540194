import React from 'react';
import _ from 'lodash';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { FiltersRow } from './FiltersRow';
import { IncidentsCls } from 'class/class.incidents';
import { TasksCls } from 'class/class.tasks';
import { ViewerPDF } from './ViewerPDF';

export const ExecutiveReport: React.FC = () => {
  const uiReports = useAppSelector((state) => state.ui.reports);
  const incidents = useAppSelector((state) => state.incidents.items);
  const tasks = useAppSelector((state) => state.tasks.items);
  const insights = useAppSelector((state) => state.insights.items);
  const incidentsCls = new IncidentsCls(incidents);
  const tasksCls = new TasksCls(tasks);
  incidentsCls.joinTasks(tasksCls.groupedBy('incident_id'));
  incidentsCls.filter(uiReports.executive);
  const sortedIncidents = incidentsCls.sortBy(uiReports.sort.name, uiReports.sort.orderByState);

  return (
      <ViewerPDF incidents={sortedIncidents} totalIncidents={incidents} insights={insights} />
  );
};
