import React from 'react';

import * as ICONS from 'assets/icons/General';
import { INotification } from 'constants/notifications.constant';

export const NOTIFICATION_CARD: React.FC<{ notification: INotification }> = ({notification}): JSX.Element => {
  return (
    <>
      <ICONS.NOTIFICATIONS_ICON />
      <div className='notification-details'>
        <p className='notification-text title'>
           {notification.trigger}
        </p>
        <p className='notification-text description'>
          {notification.title}
        </p>
      </div>
      <img className='notification-image' src='https://trustnetstorage.file.core.windows.net/images/users/26518c91-ece0-4b61-9ddb-48d716b52ad5?sv=2021-06-08&ss=f&srt=sco&sp=r&se=2022-09-23T02:50:22Z&st=2022-09-13T18:50:22Z&spr=https&sig=VBlxV0bq%2B88AEry%2Flx4e8O28fnK%2BQhLzErlT5cICusI%3D' alt='Flying Blue' />
      {/* <img className='notification-image' src='flying_blue.png' alt='Flying Blue' /> */}
    </>
  );
};
