import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import _ from 'lodash';

import { Left } from './Left';
import { Right } from './Right';
import { Dropdown } from '../Shared/Dropdown';
import { VerticalContentMiddle } from '../Shared/Elements';
import { setDropdownOptions } from 'components/helpers';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { userRoles } from 'state/features/global.features';
import { setAdminCompany, setDetails } from 'state/features/company.features';
import { Profile } from 'components/Pages/Profile';


export const Header: React.FC = () => {
  const userRole = useAppSelector((state) => state.global.userRole);
  const adminCompanies = useAppSelector((state) => state.company.adminCompanies);
  const companyName = useAppSelector((state) => state.company.details?.company_name);
  const options = _.keys(_.groupBy(adminCompanies, 'company_name'));
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [profilePopup, setProfilePopup] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (menuItem: string) => {
    switch (menuItem) {
      case 'profile-popup':
        setProfilePopup(true);
        break;
    }
    setShow(true);
  };

  return (
    <>
    <div className='main-header'>
      <div className='nav'>
        <Left />
        {_.isEqual(userRole, userRoles.ADMIN) && (
          <VerticalContentMiddle style='user-management'>
            <Dropdown
              withoutDispatch={true}
              onChange={(option:string) => {
                            const company = _.find(adminCompanies,{'company_name':option});                          
                            dispatch(setAdminCompany(company?.company_name));
                        }}
              options={setDropdownOptions(options)}
              value={{ value: companyName, label: companyName }}
            />
          </VerticalContentMiddle>
        )}
        <Right handleShow={handleShow} />
      </div>
    </div>
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        {profilePopup && <Profile />}
      </Offcanvas.Body>
    </Offcanvas>
  </>
  );
};
