import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import _ from 'lodash';

import { PrivateRoute } from './components/PrivateRoute/privateRoute.component';
import { ROUTES } from './constants/routes.constants';
import { useAppDispatch, useAppSelector } from './hooks/redux.hooks';
import { login, logout, setUserRole, userRoles } from './state/features/global.features';
import { fetchAdminCompanies, fetchTranslation, fetchCompanyDetails } from 'services/company.services';
import { THEME } from './components/Layout/theme';
import { Header } from './components/Layout/Header/';
import { Pages } from './components/Pages/';
import { Profile } from './components/Pages/Profile';
import { NotificationsStack } from 'components/Layout/Shared/NotificationsStack';
import { getSAS } from 'services/global.service';
import { ControlRoom } from 'components/Pages/ControlRoom';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();

  const user = useAppSelector((state) => state.global.user);
  const userRole = useAppSelector((state) => state.global.userRole);
  const companyName = useAppSelector((state) => state.company.details?.company_name);

  useEffect(() => {
    if (!_.isEmpty(accounts)) {
      dispatch(setUserRole(accounts));
      dispatch(login());
    }
  }, [accounts]);

  useEffect(() => {
    if (_.isEqual(userRoles.ADMIN, userRole)) {
      dispatch(fetchAdminCompanies());
    } else {
      dispatch(fetchCompanyDetails());
    }
  }, [user]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
      {user && companyName ? (
        <Routes>
          <Route path={ROUTES.ROOT} element={<Home companyName={companyName} />} />
          <Route
            path={ROUTES.PROFILE}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
            <Route
            path={ROUTES.TICKET}
            element={
              <THEME>
              <Header />
              <div className='page controlRoomPage'>
              <ControlRoom />
              </div>
            </THEME>
            }
          />
        </Routes>
      ) : (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>Loading....</div>
      )}
    </MsalAuthenticationTemplate>
  );
};

const Home: React.FC<{ companyName: string }> = ({ companyName }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTranslation());
    dispatch(getSAS());
    setInterval(() => {
      dispatch(getSAS());
    }, (1000 * 60 * 60));
    }, []);

  return (
    <div id={companyName}>
      <THEME>
        <Header />
        <Pages />
        <NotificationsStack />
      </THEME>
    </div>
  );
};
export default App;
