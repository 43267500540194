import React, { useEffect } from 'react';
import { EditText, EditTextarea } from 'react-edit-text';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';

import { ProviderProps } from 'constants/ui.constants';
import { Button } from 'components/Layout/Shared/Elements';
import * as ICONS from 'assets/icons/General';
import { galleryButtons, Insight } from 'constants/insights.constants';
import { InsightActionButtons } from 'components/Pages/Insights/InsightActionButtons';
import { updateInsights } from 'components/Pages/Insights';
import { setMode } from 'state/features/ui.features';

export const THEME: React.FC<ProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const theme = localStorage.getItem('theme') || 'dark'; 
    dispatch(setMode(theme === 'dark')); 
  }, []);
  const darkMode = useAppSelector((state) => state.ui.darkMode);

  return (
    <div className={`app-wrapper${darkMode ? ' dark' : ''}`} id='app-wrapper'>
      <div className='app'>{children}</div>
    </div>
  );
};

export const TWO_COLUMNS: React.FC<{
  LeftSide: React.FC;
  RightSide: React.FC;
  page: string;
  rightSideWidth?: string;
}> = ({ LeftSide, RightSide, page, rightSideWidth }) => {
  return (
    <div className={`two-columns ${page}`}>
      <div className='container'>
        <div className='left-side'>
          <LeftSide />
        </div>
        <div className={`right-side${rightSideWidth ? ' ' + rightSideWidth : ''}`}>
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export const ONE_COLUMN: React.FC<{ children: any; page?: string }> = (props) => {
  return (
    <div className={`one-column ${props.page || ''}`}>
      <div className='container'>{props.children}</div>
    </div>
  );
};
export const GALLERY: React.FC<{ selectedInsight: Insight; approveEdit?: any; readonly: boolean }> = ({
  selectedInsight,
  approveEdit,
  readonly
}) => {
  const dispatch = useAppDispatch();
  const title = selectedInsight?.title;
  const text = selectedInsight?.description;

  return (
    <div className='gallery'>
      <div className='gallery-content'>
        <div className='gallery-title'>
          <div className='item-icon'>
            <ICONS.INSIGHTS_DEFAULT />
          </div>
          <div className='title'>
            <EditText
              inline={true}
              readonly={readonly}
              name='title'
              className='text-edit'
              defaultValue={title}
              placeholder={title}
              inputClassName='editable-textarea insight-paragraph-edit'
              onSave={(title) => {
                approveEdit(title);
              }}
            />
          </div>
        </div>
        <div className='text'>
          <EditTextarea
            readonly={readonly}
            name='description'
            defaultValue={text}
            placeholder={text}
            rows={7}
            className='paragraph'
            inputClassName='editable-textarea insight-paragraph-edit'
            onSave={(text) => {
              approveEdit(text);
            }}
          />
        </div>
        <div className='actions-row'>
          <InsightActionButtons
            buttonsData={galleryButtons}
            updateInsights={() => updateInsights(event, selectedInsight, dispatch)}
            selectedInsight={selectedInsight}
          />
        </div>
      </div>
    </div>
  );
};

export const ACTIONS_ROW: React.FC<{}> = () => {
  return (
    <div className='actions-row'>
      <Button />
    </div>
  );
};
