import { format, isValid } from 'date-fns';
import _ from 'lodash';

import { timing } from 'constants/ui.constants';
import { IncidentEnum } from 'constants/incident.constants';
import { lang_prefix } from 'constants/ui.constants';
import { _t } from 'components/helpers';
import { setReportsSort, setReportsTasks, reportsTasksInitialState } from "state/features/ui.features";
import { Task } from 'constants/tasks.constants';

export const formatValues = (name:IncidentEnum, value:any, translation) =>{
    switch(name){
        case IncidentEnum.STATUS:
            return _t(value,translation,lang_prefix.INCIDENTS_STATUS);
        case IncidentEnum.SEVERITY:
            return _t(value,translation,lang_prefix.INCIDENTS_SEVIRITY);
        default:
            if(value && typeof value === 'string' && isValid(new Date(value))){
                return format(new Date(value),timing.FULLDATE);
            }
            if(_.isArray(value)){
                return value.length;
            }
        break;
    }                    
    return value || '-';
}

export const toogleTasks = (tasks:Task[],incidentId:number, dispatch:any) => {
    return !_.isEmpty(tasks) ? 
    { onClick:() => dispatch(setReportsTasks({showTasks:true, incidentId:incidentId}))} : {};
}