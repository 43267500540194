import React from 'react';

import { useAppSelector } from 'hooks/redux.hooks';
import darkLogo from 'assets/images/dark/blue-empty-state.webp';
import logo from 'assets/images/blue-empty-state.webp';

export const TasksPlaceholder: React.FC = () => {
    const darkMode = useAppSelector((state) => state.ui.darkMode);
    return (            
        <div className='placeholder'>
            <div className='board'>
                <img src={darkMode ? darkLogo : logo}/>
                <div className='grayed'>No tasks to show</div>
            </div>
        </div>
    );
}