import React from 'react';
import { useAppSelector } from 'hooks/redux.hooks';
import { userRoles } from 'state/features/global.features';
import { cancelText, inProgress, progressDone, insightStatus } from 'constants/insights.constants';
import { Button } from 'components/Layout/Shared/Elements';

export const DueDateMenu: React.FC<{ updateInsights: any }> = ({ updateInsights }): JSX.Element => {
  const selectedInsight = useAppSelector((state) => state.insights.selected);
  const userRole = useAppSelector((state) => state.global.userRole);

  return (
    <>
      <div className='due-date-menu'>
        {userRole === userRoles.ADMIN && (
          <>
            <Button text={inProgress} callback={updateInsights} style='hover-menu-item' />
            <Button text={progressDone} callback={updateInsights} style='hover-menu-item' />
          </>
        )}
        {userRole === userRoles.USER && selectedInsight?.status === insightStatus.WAITING_FOR_WORK && (
          <>
            <Button text={cancelText} callback={updateInsights} style='hover-menu-item' />
          </>
        )}
      </div>
    </>
  );
};
