import React, { useState } from 'react';
import { useAppDispatch } from 'hooks/redux.hooks';
import { 
    Incident,
    IncidentFieldsForDisplay,
    IncidentsCategories,
    Components 
} from 'constants/incident.constants';
import { timing } from 'constants/ui.constants'; 
import * as TAB from 'components/Layout/Shared/Tabs';
import { format } from 'date-fns';
import { RegularText, Space } from 'components/Layout/Shared/Elements';
import { setConrolRoomActiveIncident } from 'state/features/ui.features';
import {activeTabs} from 'constants/incident.constants'
import { togglePage } from 'state/features/ui.features';
import { pagesEnum} from 'constants/ui.constants';

export const Incidents: React.FC<{ sevirityIncidents:Pick<Incident,IncidentFieldsForDisplay>[], recentIncidents:Pick<Incident,IncidentFieldsForDisplay>[] }> = ({ sevirityIncidents,recentIncidents }) => {
    const [activeIncidentsTab,setActiveIncidentsTab] = useState('recent');

    const isActive = (key:string):boolean => {
        return activeIncidentsTab === key ? true :false; 
    }
    return  <>
        <div className='header'>
            <div className='title tooltip'>Incidents</div>
            <TAB.TABS_HEADER>
                <TAB.TAB_BUTTON 
                    tab={{key:'recent',title:'Recent',isActive:isActive('recent')}} 
                    toggle={setActiveIncidentsTab} 
                />
                <TAB.TAB_BUTTON 
                    tab={{key:'sevirity',title:'Severity',isActive:isActive('sevirity')}} 
                    toggle={setActiveIncidentsTab} 
                />                
            </TAB.TABS_HEADER>
        </div>
        <Space/>
        <TAB.TABS_CONTAINER>
            <TAB.TAB_CONTAINER isActive={isActive('recent')}>
                <div className='content'>
                    {recentIncidents.map((item, index)=>
                        <IncidentItem item={item} key={index}  />
                    )}
                </div>
            </TAB.TAB_CONTAINER>
            <TAB.TAB_CONTAINER isActive={isActive('sevirity')}>
                <div className='content'>
                    {sevirityIncidents.map((item, index)=>
                        <IncidentItem item={item} key={index} />
                    )}
                </div>
            </TAB.TAB_CONTAINER>
        </TAB.TABS_CONTAINER>
    </>
}

export const IncidentItem: React.FC<{ item:Pick<Incident,IncidentFieldsForDisplay> }> = ({ item }) => {
    const itemCreatedDate = item.created_at ? new Date(item.created_at):undefined;
    const dispatch = useAppDispatch();

    const handleIncidentClick = () => {
        const dispatchState = {tab:activeTabs.INEVESTIGATION, active:true, incident:item}; 
        dispatch(setConrolRoomActiveIncident(dispatchState))
        dispatch(togglePage(pagesEnum.CONTROL_ROOM))
    };
    const Block = (key:string) => {
        // component does exist
        if (typeof Components[key] !== "undefined") {
          return React.createElement(Components[key]);
        }
        return React.createElement(Components['uncategorized'])
    } 
    return (
    <div onClick={handleIncidentClick} >
        <div className={`card rows-card sevirity-${item.severity}`}>
            <div className={`headline tooltip incident-icon-${item.category_name}`}>
            <span className="tooltiptext">
                {
                    IncidentsCategories[item.category_name] ? 
                    IncidentsCategories[item.category_name] : 
                    IncidentsCategories.uncategorized
                }
            </span>{ Block(item.category_name) }
            </div>
            <div className='text'>{item.title}</div>
            <div className='footer'>
                <RegularText style='left-footer'>{format(new Date(itemCreatedDate), timing.FULLDATE)}</RegularText>
                <div className='right-footer'>
                    <div>{item.status}</div>
                </div>
            </div>
        </div>
    </div>
    )
}