import React from 'react';
import { Task, taskStatus, Comment } from 'constants/tasks.constants';
import { FlexSpaceBetween } from 'components/Layout/Shared/Elements';
import { format, parseISO } from 'date-fns';
import { timing } from 'constants/ui.constants';
import * as ICONS from 'assets/icons/General';
import _ from 'lodash';

interface ModalProps {
  isVisible: boolean;
  commentsArray: Comment[]| null;

}

const CommentsModal = (props: ModalProps) => {

  const { isVisible, commentsArray, } = props;
  if (!isVisible) return null;
  const colors = ['bg-red', 'bg-blue', 'bg-green', 'bg-grey', 'bg-pink'];
  
  function getColorByName(name: string): string {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash % colors.length);
    return colors[index];
  }

  return (
    <div className='comments-modal'>
      <div className='modal-content-comments'>
        <div className='comments-section'>
          {commentsArray?.map((comment, index) => {
             const colorClass = getColorByName(comment.last_updating_user);
            return (
              <div key={index} className='first-line'>
                <div className='comment-user-name'>
                  <div className={`first-letter-circle ${colorClass}`}><div className='letter'>{_.capitalize(comment?.last_updating_user?.charAt(0))}</div></div>
                  <p className='name'>{comment?.last_updating_user}</p>
                  <p className='date'>{format(new Date(comment?.created_at), timing.FULLDATE)}</p>
                </div>
                <p className='comment'>{comment.comment}</p>
              </div>
            );
          })}
                  <div>
                  </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
