import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_USER_LOGIN, API_GET_AVATAR, API_UPDATE_AVATAR } from 'constants/db.constants';
import API from '../../../services/api.service';
import { IMAGE_TYPE } from 'constants/db.constants';

export const login = createAsyncThunk('user/login', (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_USER_LOGIN)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const userRoles = {
  ADMIN: 'admin',
  USER: 'user'
};
interface IGlobalState {
  isAuth: boolean;
  user: IUser | null;
  loading: boolean;
  error: IUserError;
  userRole: string;
  SAS: string;
}
export interface IUser {
  id: number;
  name: string;
  username: string;
  email: string;
  role: string;
  user_name: string;
  position: string;
  last_login: Date;
  first_name: string;
  last_name: string;
  fullName: string;
  url: string;
}
export interface IUserError {
  error: boolean;
  errorCode: number | null;
  errorMessage: string;
}

interface IAvatarData {
  details: any;
  randomCode: string;
  name: string;
  avatarInfo: object;
}

const initialState: IGlobalState = {
  isAuth: false,
  user: null,
  error: {
    error: false,
    errorCode: null,
    errorMessage: ''
  },
  loading: false,
  userRole: userRoles.USER,
  SAS:''
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    logout: (state) => {
      state.isAuth = false;
    },
    setError: (state) => {
      state.error.error = true;
    },
    setUserRole:(state, { payload }) => {
      try{
        const jobTitle = payload[0].idTokenClaims.jobTitle;
        if(jobTitle.includes('trustnet')){
          state.userRole = userRoles.ADMIN;
        }
      }catch(error){
        console.log(error)
      }
    },
    setSAS:(state, { payload }) => {
      state.SAS = payload;
    }
  },
  extraReducers: {
    [login.pending.type]: (state) => {
      state.loading = true;
      state.error.error = false;
    },
    [login.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.user = payload?.user;
    },
    [login.rejected.type]: (state) => {
      state.loading = false;
      state.error.error = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const { logout, setError, setUserRole, setSAS } = globalSlice.actions;

export default globalSlice.reducer;
