import React from 'react';

import { setConrolRoomSinceDay, togglePage } from 'state/features/ui.features';
import { pagesEnum, pagesList, lang_prefix, rangeNumbersOptions } from 'constants/ui.constants';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import * as ICONS from 'assets/icons/General';
import { store } from 'state/store';
import { _t } from 'components/helpers';
import { useNavigate } from 'react-router-dom';

export const Left: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate= useNavigate()
  let page = useAppSelector((state) => state.ui.page);
  const path=location.pathname;
  if(path.includes('ticket')){
    page= 'controlRoom'
  }
  const _tooglePage = (key: string) => {
    store.dispatch(togglePage(key));
    dispatch(setConrolRoomSinceDay(rangeNumbersOptions.LAST_7) ) 
    navigate('/')
  };
  const translation = useAppSelector((state) => state.company.translation);
  const translations = _t(pagesList, translation, lang_prefix.API_NAV_TITLE);

  const PAGE_ICON: React.FC<{ page: string }> = ({ page }) => {
    switch (page) {
      case 'dashboard':
        return <ICONS.DASHBOARD />;
      case 'controlRoom':
        return <ICONS.CONTROL_ROOM />;
      case 'reports':
        return <ICONS.REPORTS />;
      case 'insights':
        return <ICONS.INSIGHTS />;
    }
    return <></>;
  };
  const isActive = (name: pagesEnum) => {
    switch (name) {
      case pagesEnum.REPORTS:
        return page === name || page === pagesEnum.REPORTS_FALSE_POSITIVE_RATE;
        break;
      default:
        return page === name;
    }
  };
  return (
    <div className='nav-left'>
      <div
        className='logo logo-light'
        onClick={() => {
          _tooglePage(pagesEnum.DASHBOARD);
        }}
      >
        <ICONS.LOGO />
      </div>
      <div className='pages-menu'>
        {pagesList.map((key, index) => {
          return (
            <div
              key={index}
              className={`nav-link ${isActive(key) ? ' active' : ''}`}
              onClick={(e) => {
                if (page !== key) _tooglePage(key);
              }}
            >
              <div className={`icon`}>
                <PAGE_ICON page={key} />
              </div>
              <div className='caption'>{translations[key]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
