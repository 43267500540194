import { API_GET_NOTIFICATIONS, API_GET_INTERVAL_NOTIFICATIONS } from "constants/db.constants";
import { notificationsAmount } from "constants/ui.constants";
import { setError, setItems, setLoading, setIntervalNotifications } from "state/features/notifications.features";
import API from 'services/api.service';

export function fetchNotifications() {
  const paramsSetting = {
    howMany: notificationsAmount,
  };

  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_NOTIFICATIONS, API.wrapParamsWithCompany(getState, paramsSetting))
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch(() => {
        dispatch(setError());
      });
  };
}

export function fetchIntervalNotifications() {
  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_INTERVAL_NOTIFICATIONS, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setIntervalNotifications(response.data));
      })
      .catch(() => {
        dispatch(setError());
      });
  };
}