import { Page, Text, View, Document, StyleSheet, Image, Canvas } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding:'16px 32px 32px',
  },
  section: {
    padding: 0,
  },
  header:{
    display:'flex',
  },
  headerTitle:{
    fontSize:'15px'
  },
  smFont:{
    fontSize:'8px'
  },
  legend:{
    fontSize:'12px',
    padding:'6px'
  },
  headerDate:{
    color:'gray',
    fontSize:'13px',
    lineHeight:'20px',
    marginTop:'8px',
  },
  companyLogo:{
    width:'30px',
    height:'30px',
    borderRadius:'50%',
    backgroundColor:'gray',
    margin:'auto'
  },
  companyName:{
    fontSize:'11px'
  },
  infoBox:{
    backgroundColor:'#f2f5f8',
    fontSize:'12px',
    padding:'8px',
  },
  headerParagraph:{
    fontSize:'16px',
    paddingTop:'8px',
    paddingBottom:'8px',
    color:'#5e6978'
  },
  row:{
    flexDirection:'row',
    paddingTop:'16px',
    paddingBottom:'16px',
    borderBottom:'1px solid #e7ebee',
    fontSize:'9px',
  },
  rowHeader:{
    flexDirection:'row',
    paddingTop:'8px',
    paddingBottom:'8px',
    backgroundColor:'#e7ebee',
    fontSize:'10px',
    fontWeight:'bold'
  },
  column1:{
    padding:'0 5px',
    width:'40%',
  },
  column2:{
    padding:'0 5px',
    width:'10%'
  },
  column3:{
    padding:'0 5px',
    width:'15%'
  },
  column4:{
    padding:'0 5px',
    width:'20%'
  },
  column5:{
    padding:'0 5px',
    width:'15%'
  },
  column6:{
    padding:'0 5px',
    width:'60%'
  },
  column7:{
    padding:'0 5px',
    width:'50%'
  },
  column8:{
    padding:'0 5px',
    width:'25%'
  }
});