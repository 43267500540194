import { createSlice } from '@reduxjs/toolkit';

import {Incident, IncidentStatusUpdate} from "constants/incident.constants";
import _ from 'lodash';

interface IUiState {
  loading:boolean;
  error:boolean;
  items:Incident[];
  incidentStatus: IncidentStatusUpdate[];
}


const initialState: IUiState = {
    loading:false,
    error:false,
    items : [],
    incidentStatus:[]
}

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setItems: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.items = payload;
    },
    setIncidentStatusUpdates: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.incidentStatus = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    setIncident:(state, { payload }) => {
      const index = _.findIndex(state.items,{id:payload.id});
      state.items[index] = payload;
    },
  },
})

export const { setLoading, setItems, setError, setIncident, setIncidentStatusUpdates} = incidentsSlice.actions;

export default incidentsSlice.reducer;