import React, { useEffect } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { fetchIncidents } from 'services/incidents.services';
import { fetchConfiguration, fetchMonitoredDeviceNumber, fetchSLA } from 'services/company.services';
import { InfoBoxes } from './InfoBox';
import { Top } from './Top';
import { Incidents } from './IncidentsList';
import { IncidentAmountPerColumn } from 'constants/incident.constants';
import { eventPerSeconds, timing, pagesEnum } from 'constants/ui.constants';
import * as THEME from 'components/Layout/theme';
import { GALLERY_SLIDER_PAGINATION } from 'components/Layout/Shared/GallerySliderPagination';
import { Graphs } from './Graphs';
import { IncidentsCls } from 'class/class.incidents';
import { TasksCls } from 'class/class.tasks';
import { InfoBoxesCls } from 'class/class.infoBox';
import { fetchTasks } from 'services/tasks.services';
import { fetchInsights } from 'services/insights.services';
import { Insight, userPreference } from 'constants/insights.constants';

function getInfoBoxList(incidentsAmount: number, falsePositiveRate: string): infoBox[] {
  const tasks = useAppSelector((state) => state.tasks.items);
  const monitoredDeviceNumber = useAppSelector((state) => state.company.monitoredDeviceNumber);
  const configuration = useAppSelector((state) => state.company.configuration);
  const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);
  const useCaseRules = useAppSelector((state) => state.company.useCaseRules);
  const keyEventPerSecond = eventPerSeconds[sinceDay];
  const eventPerSecond = configuration[keyEventPerSecond];
  const infoBoxCls = new InfoBoxesCls();
  
  infoBoxCls.setInfoBox(falsePositiveRate, 'False positive', pagesEnum.REPORTS_FALSE_POSITIVE_RATE);
  infoBoxCls.setInfoBox(eventPerSecond, 'EPS', pagesEnum.COMPANY_PROFILE_MONITOR_DEVICE);
  infoBoxCls.setInfoBox(String(incidentsAmount), 'Total incident', pagesEnum.CONTROL_ROOM);
  infoBoxCls.setInfoBox(String(monitoredDeviceNumber), 'Monitored sources', pagesEnum.COMPANY_PROFILE_MONITOR_DEVICE);
  infoBoxCls.setInfoBox(String(useCaseRules.length), 'Rules', pagesEnum.COMPANY_PROFILE_USE_CASE);
  infoBoxCls.setInfoBox(String(TasksCls.openTasks(tasks).length), 'Open tasks', pagesEnum.CONTROL_ROOM);
  return infoBoxCls.getAll;
}

export const LeftSide: React.FC = () => {
  const incidents = useAppSelector((state) => state.incidents.items);
  const insights = useAppSelector((state) => state.insights.items);
  const incidentsCls = new IncidentsCls(incidents);
  const lastUpdate = incidentsCls.lastUpdate ? format(new Date(incidentsCls.lastUpdate), timing.TIME) : '';
  const amountPerPage = window.innerWidth < 1920 ? 1 : 2;
  const groupedInsights = _.groupBy(insights,'user_preference');
  let sortedInsights:Insight[] = [];
  _.values(userPreference).forEach((state) => {
    if(_.has(groupedInsights, state)){
      sortedInsights = _.concat(sortedInsights, groupedInsights[state]);
    }
  })
  
    // Reverse the sortedInsights array
  sortedInsights.reverse();
  
  return (
    <>
      <Top lastUpdate={lastUpdate} />
      <InfoBoxes list={getInfoBoxList(incidentsCls.amount, incidentsCls.falsePositiveRate)} />
      <Graphs />
      {insights.length > 0 && (
        <GALLERY_SLIDER_PAGINATION
          title='Insights'
          list={sortedInsights}
          galleryType='pagination'
          amountPerPage={amountPerPage}
        />
      )}
    </>
  );
};

export const RightSide: React.FC = () => {
  const incidents = useAppSelector((state) => state.incidents.items);
  const incidentsCls = new IncidentsCls(incidents);
  const sortByDate = incidentsCls.sortByDate.slice(0, IncidentAmountPerColumn);
  const sortBySevirity = incidentsCls.sortBySevirity.slice(0, IncidentAmountPerColumn);
  return <Incidents recentIncidents={sortByDate} sevirityIncidents={sortBySevirity} />;
};

export const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const sinceDay = useAppSelector((state) => state.ui.sinceDaysAgo);
  const untilDay = useAppSelector((state) => state.ui.untilDaysAgo);
  const company = useAppSelector((state) => state.company.details);
  useEffect(() => {
    dispatch(fetchInsights());
  }, [company]);
  useEffect(() => {
    dispatch(fetchIncidents(sinceDay, untilDay));
    dispatch(fetchTasks(sinceDay, untilDay));
  }, [sinceDay,company]);

  useEffect(() => {
    dispatch(fetchSLA());
    dispatch(fetchMonitoredDeviceNumber());
    dispatch(fetchConfiguration());
  }, [company]);

  return <THEME.TWO_COLUMNS page='dashboard' LeftSide={LeftSide} RightSide={RightSide} />;
};
