import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface IUiState {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: IUiState = {
  loading: false,
  success: false,
  error: false
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    }
  }
});

export const { setLoading, setSuccess, setError } = contactSlice.actions;

export default contactSlice.reducer;
