import _ from 'lodash';

import { API_GET_TASKS, BASE_URL, API_UPDATE_TASK, API_GET_ALL_TASKS_COMMENTS } from 'constants/db.constants';
import { setComments, setError, setItems, setLoading, setTask } from 'state/features/tasks.features';
import { taskStatus } from 'constants/tasks.constants';
import API from '../services/api.service';
import { useAppSelector } from 'hooks/redux.hooks';

export function fetchTasks(sinceDay: string, untilDay: string) {
  const paramsSetting = {
    sinceDaysAgo: sinceDay,
    untilDaysAgo: untilDay
  };

  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_TASKS, API.wrapParamsWithCompany(getState, paramsSetting))
      .then((response) => {
        dispatch(setItems(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function fetchAllComments() {

  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .get(API_GET_ALL_TASKS_COMMENTS, API.wrapParamsWithCompany(getState))
      .then((response) => {
        dispatch(setComments(response.data));
      })
      .catch((er) => {
        dispatch(setError());
      });
  };
}

export function updateTask(status: taskStatus, id: number, comment?:string, last_updating_user?:string ) {

  const paramsSetting = {
    task_id: id,
    status: status,
    ...(comment && { comment: comment }),
    last_updating_user:last_updating_user || ''
  };

  return async (dispatch: any, getState:any) => {
    dispatch(setLoading());
    await API.getInstance()
      .post(API_UPDATE_TASK + API.addQueryWithCompany(getState), paramsSetting)
      .then(async (response) => {
        dispatch(setTask(response?.data?.task));
        await dispatch(fetchAllComments());
      })
      .catch((error) => {
        dispatch(setError());
      });
  };
}
