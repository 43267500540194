import React,{ Fragment, useEffect } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

import { timing,lang_prefix } from 'constants/ui.constants';
import { Incident, IncidentSeverityOrder } from 'constants/incident.constants';
import { SubParagraphTitle, ParagraphText } from 'components/Layout/Shared/Elements';
import { compareDay,dayTitle,_t } from 'components/helpers';
import { useAppSelector } from 'hooks/redux.hooks';
import { IncidentItem } from './IncidentItem';
import { IncidentEnum } from 'constants/incident.constants';

export const IncidentsList: React.FC<{incidents:Incident[], style?:string}> = ({incidents, style}) => {
    const activeIncident = useAppSelector((state) => state.ui.controlRoom.activeIncident);
    const sortOrder = useAppSelector((state) => state.ui.controlRoom.incidentSort.name);
    let sortedList=incidents;
    if (sortOrder === IncidentEnum.TITLE || sortOrder === IncidentEnum.CATEGORY_NAME || sortOrder === IncidentEnum.SEVERITY) {
        const grouped = _.groupBy(incidents, (obj) => obj[sortOrder] ?? "uncategorized");
        
        let sortedGroups;
        if (sortOrder === IncidentEnum.SEVERITY) {
            sortedGroups = _(grouped)
                .toPairs()
                .orderBy(([key]) => IncidentSeverityOrder.indexOf(key) !== -1 ? IncidentSeverityOrder.indexOf(key) : Infinity) 
                .map(([, group]) => _.orderBy(group, [IncidentEnum.CREATED_AT], ['desc']))
                .flatten()
                .value();
        } else {
            sortedGroups = _(grouped)
                .values()
                .orderBy('length', 'desc')
                .map(group => _.orderBy(group, [IncidentEnum.CREATED_AT], ['desc']))
                .flatten()
                .value();
        }
    
        sortedList = sortedGroups;
    }
    
    let lastDate:Date;
    return  (
        <Fragment>
        { incidents.length > 0 && <div className={`content${_.isEmpty(style) ? '' : ' '+style}`}>
            {sortedList.map((item, index) => {
                const isSelected = (activeIncident.active) && 
                (_.get(activeIncident.incident,'id') === item.id) ? true : false;
                let display = false;
                let displayTitleHeader=(item?.title!==sortedList[index-1]?.title) && sortOrder===IncidentEnum.TITLE;
                let displayCategoryHeader=(item?.category_name!==sortedList[index-1]?.category_name) && sortOrder===IncidentEnum.CATEGORY_NAME;
                let category=item.category_name?item.category_name:"uncategorized";
                const createDate = new Date(item.created_at);

                if(sortOrder===IncidentEnum.CREATED_AT &&(index === 0 || index > 0 && !compareDay(createDate,lastDate,0))){
                    display = true;
                    lastDate = createDate;
                }

                return <Fragment key={index}>
                    {display && <IncidentsDayHeader key={'header'+index} date={item.created_at} />}
                    {displayTitleHeader && <SubParagraphTitle>{item.title}</SubParagraphTitle>}
                    {displayCategoryHeader && <SubParagraphTitle>{category}</SubParagraphTitle>}
                    <IncidentItem item={item} isSelected={isSelected} tab={activeIncident.tab}/>
                </Fragment>
            })}
        </div>}
        </Fragment>
    )
}

export const IncidentsDayHeader: React.FC<{date:Date}> = ({date}) => {
    const createDate = new Date(date);
    const translation = useAppSelector((state) => state.company.translation)
    const today = translation[lang_prefix.API_TIMING + timing.TODAY] || timing.TODAY;
    const yesterday = translation[lang_prefix.API_TIMING + timing.YESTERDAY] || timing.YESTERDAY;
    return  <div className='day-header'>
        <SubParagraphTitle>{dayTitle(createDate, yesterday, today)}</SubParagraphTitle>
        { compareDay(new Date(), createDate,1) &&
            <ParagraphText style='grayed day-header-date'>
                { date && format(new Date(date), timing.FULLDATE)}
            </ParagraphText>
        }
    </div>;
}