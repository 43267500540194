import { createSlice } from '@reduxjs/toolkit';

import { SLA, CompanyConfiguration, ICompany, MonitoredDevices, SourceIp, IUseCaseRule } from 'constants/company.constants';
import { IUser } from '../features/global.features';

interface IUiState {
  loading: boolean;
  error: boolean;
  monitoredDeviceNumber: number;
  monitoredDevices: MonitoredDevices[];
  sla: SLA[];
  configuration: CompanyConfiguration;
  translation: { [key: string]: string };
  details: ICompany | {};
  companyUsers: IUser[];
  sourceIps: SourceIp[];
  adminCompanies: string[];
  adminCompany:string;
  useCaseRules: IUseCaseRule[]
}

const initialState: IUiState = {
  loading: false,
  error: false,
  monitoredDeviceNumber: 0,
  monitoredDevices: [],
  sla: [],
  configuration: {
    event_per_second_first: 0,
    event_per_second_second: 0,
    event_per_second_third: 0
  },
  translation: {},
  details: {},
  companyUsers: [],
  sourceIps: [],
  adminCompanies: [],
  adminCompany:'',
  useCaseRules: []
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setMonitoredDeviceNumber: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.monitoredDeviceNumber = payload.sum === null ? 0 : payload.sum;
    },
    setMonitoredDevices: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.monitoredDevices = payload;
    },
    setConfiguration: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.configuration = payload;
    },
    setSLA: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.sla = payload;
    },
    setError: (state) => {
      state.error = true;
    },
    setTranslation: (state, { payload }) => {
      state.translation = payload;
    },
    setDetails: (state, { payload }) => {
      state.details = payload;
    },
    setAvatar: (state, { payload }) => {
      state.details.url = payload;
    },
    setCompanyUsers: (state, { payload }) => {
      payload.map((user: IUser) => {
        user.fullName = `${user.first_name} ${user.last_name}`;
      });
      state.companyUsers = payload;
    },
    setSourceIps: (state, { payload }) => {
      state.sourceIps = payload;
    },
    updateSourceIps: (state, { payload }) => {
      state.sourceIps = payload;
      const indexToUpdate = state.sourceIps.findIndex((item) => item.id === payload.id);
      state.sourceIps[indexToUpdate] = payload;
    },
    setAdminCompanies: (state, { payload }) => {
      state.adminCompanies = payload;
    },
    setAdminCompany: (state, { payload }) => {
      state.details = {company_name:payload};
      state.adminCompany = payload;
    },
    setUseCaseRules: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.useCaseRules = payload;
    },
  }
});

export const {
  setLoading,
  setMonitoredDeviceNumber,
  setMonitoredDevices,
  setSLA,
  setError,
  setConfiguration,
  setTranslation,
  setDetails,
  setCompanyUsers,
  setSourceIps,
  updateSourceIps,
  setAdminCompanies,
  setAvatar,
  setAdminCompany,
  setUseCaseRules
} = companySlice.actions;

export default companySlice.reducer;
