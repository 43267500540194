/* eslint-disable functional/no-this-expression */
/* eslint-disable functional/no-class */
/* eslint-disable functional/no-try-statement */
import axios, { AxiosError } from 'axios';
import _ from 'lodash';

import { getToken } from '../services/auth.service';
import { msalInstance } from '../index';
import { globalEndpoints } from 'constants/global.constants';

export default class ApiService {
  static instance: ApiService;

  constructor() {
    if (ApiService.instance) {
      return ApiService.instance;
    }
    ApiService.instance = this;
  }

  static getInstance = () => ApiService.instance || new ApiService();

  static getServerUrl = () =>  globalEndpoints.api;

  get = async (partialUrl: string, params: any = {}): Promise<any> => {
    const jwt = await getToken(msalInstance);

    if (jwt === null) {
      return Promise.reject();
    }

    const headers = {
      Authorization: `Bearer ${jwt}`
    };

    const completeUrl = ApiService.getServerUrl() + partialUrl;
    try {
      const res = await axios.get(completeUrl, { headers, params: params });
      return await Promise.resolve(res);
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  };

  post = async (partialUrl: string, data: Record<string, any> = {}): Promise<any> => {
    const jwt = await getToken(msalInstance);

    const headers = {
      Authorization: `Bearer ${jwt}`
    };

    const completeUrl = ApiService.getServerUrl() + partialUrl;
    try {
      const res = await axios.post(completeUrl, data, { headers });
      return await Promise.resolve(res);
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  };

  handleResponseError = (error: AxiosError<any>) => {
    const apiError = {
      message: error.message,
      data: error?.response?.data,
      statusCode: error?.response?.status
    };
    return Promise.reject(apiError);
  };

  static wrapParamsWithCompany = (getState:any,extraParams:any = {}) => {
    const { company } = getState();
    return {...extraParams, companyName:company?.details?.company_name }
  };
  static addQueryWithCompany = (getState:any) => {
    const { company } = getState();
    return '?companyName=' + company?.details?.company_name;
  };
}
