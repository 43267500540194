export const GET_CONFIG = {
  baseURL: '',
  withCredentials: false,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  }
};

export const IMAGE_TYPE = {
  COMPANY: 'company',
  USERS: 'users',
  INSIGHT: 'insight'
};

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  StagingTrustnet = 'StagingTrustnet',
  Production = 'production'
}

export const CLIENT_URLS = {
  [Environment.Development]: 'http://localhost:9000',
  [Environment.Staging]: 'https://proud-ocean-059af3003.5.azurestaticapps.net',
  [Environment.StagingTrustnet]: 'https://dev-platform.tsoc.co.il',
  [Environment.Production]: 'https://platform.tsoc.co.il/',
}

export const SERVER_URLS = {
  [Environment.Development]: 'http://localhost:8080',
  [Environment.Staging]: 'https://app-trustnet-server-dev-d7b0bdfraceqhjat.israelcentral-01.azurewebsites.net',
  [Environment.StagingTrustnet]: 'https://api.dev-platform.tsoc.co.il',
  [Environment.Production]: 'https://api.platform.tsoc.co.il/',
}


// export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://app-trustnet-server-dev-d7b0bdfraceqhjat.israelcentral-01.azurewebsites.net/';
// export const BASE_URL = 'http://localhost:3000';
export const API_GET_INCIDENTS = '/api/portal/incident/getByDays';
export const API_GET_INCIDENT_STATUS_UPDATES = '/api/portal/incident/getStatusUpdates';
export const API_UPDATE_INCIDENT = '/api/portal/incident/update';
export const API_GET_TASKS = '/api/portal/task/getByDays';
export const API_GET_INSIGHTS = '/api/portal/insight/getByDays';
export const API_GET_COMPANY = '/api/portal/company/getCompany';
export const API_GET_MONITORED_DEVICE_NUMBER = '/api/portal/company/getMonitoredDeviceNumber';
export const API_GET_MONITORED_DEVICES = '/api/portal/company/getMonitoredDevice';
export const API_GET_All_USE_CASE_RULES = '/api/portal/company/useCase/getAll';
export const API_GET_SLA = '/api/portal/company/getSLA';
export const API_GET_COMPANY_CONFIGURATION = '/api/portal/configuration/getConfiguration';
export const API_GET_TRANSLATION = 'translation.json';
export const API_UPDATE_INSIGHT = '/api/portal/insight/update';
export const API_UPDATE_TASK = '/api/portal/task/addComment';
export const API_GET_ALL_TASKS_COMMENTS = '/api/portal/task/getTaskComments';
export const API_CREATE_INSIGHT = '/api/portal/insight/create';
export const API_USER_LOGIN = '/api/portal/user/login';
export const API_GET_INTERVAL_NOTIFICATIONS = '/api/portal/notifications/getInterval';
export const API_GET_NOTIFICATIONS = '/api/portal/notifications/getRecent';
export const API_SEND_CONTACT_US_EMAIL = '/api/portal/user/contactUs';
export const API_GET_AVATAR = '/api/portal/global/getAvatar';
export const API_UPDATE_AVATAR = '/api/portal/global/uploadAvatar';
export const API_GET_SAS = '/api/portal/global/getSAS';
export const API_COMPANY_USERS = '/api/portal/user/getAll';
export const API_GET_SOURCE_IP = '/api/portal/company/getSourceIP';
export const API_UPDATE_SOURCE_IP = '/api/portal/company/updateSourceIP';
export const API_GET_INSIGHT_IMAGE = '/api/portal/user/getInsightImage';
export const API_GET_ADMIN_COMPANIES = '/api/portal/company/getAdminCompanies';
