import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { format, isValid } from 'date-fns';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { Task, taskStatus, Comment } from 'constants/tasks.constants';
import { timing, lang_prefix } from 'constants/ui.constants';
import { STATUS_CARD } from 'components/Layout/Shared/Cards/StatusCard';
import { reportsTasksInitialState, setReportsTasks } from 'state/features/ui.features';
import { Dropdown } from 'components/Layout/Shared/Dropdown';
import { fetchAllComments, updateTask } from 'services/tasks.services';
import { _t, setDropdownOptions } from 'components/helpers';
import { CLOSE } from 'assets/icons/General';
import { RegularText } from 'components/Layout/Shared/Elements';

export const IncidentTasks: React.FC<{ tasksState: {}, tasks: Task[] }> = (props) => {
    const dispatch = useAppDispatch();
    const translation = useAppSelector((state) => state.company.translation);
    const user = useAppSelector((state) => state.global.user);
    const comments = useAppSelector((state) => state.tasks.comments);
    const [tasksWithComments, setTasksWithComments] = useState<Task[]>([]);
    const tasksList = _.filter(tasksWithComments, { incident_id: props.tasksState.incidentId });
    
    const addCommentsToTasks = (tasks: Task[], comments: Comment[]): Task[] => {
        const commentsByTaskId = comments.reduce((acc, comment) => {
          if (!acc[comment.task_id]) {
            acc[comment.task_id] = [];
          }
          acc[comment.task_id].push(comment);
          return acc;
        }, {} as { [key: number]: Comment[] });
      
        return tasks.map(task => ({
          ...task,
          comments: commentsByTaskId[task.id] || []
        }));
      };
      
    
      useEffect(() => {
        const fetchCommentsAndSetTasks = async () => {
          await dispatch(fetchAllComments());
          setTasksWithComments(addCommentsToTasks(props.tasks, comments));
        };
        fetchCommentsAndSetTasks();
      }, [props.tasks, dispatch]);
    
      useEffect(() => {
        setTasksWithComments(addCommentsToTasks(props.tasks, comments));
      }, [comments, props.tasks]);

      
    return (
        <div id="myModal" className={`modal ${props.tasksState.showTasks ? ' show' : ''}`}>
            <div className="modal-content">
                <div className='close-modal' onClick={() => { dispatch(setReportsTasks(reportsTasksInitialState)) }}>
                    <CLOSE />
                </div>
                {tasksList.map((task, index) =>
                    <STATUS_CARD
                    item={{
                      task: task,
                      title: task.title,
                      index: index,
                      date: isValid(new Date(task.updated_at)) && format(new Date(task.updated_at), timing.FULLDATE),
                      priority: task.priority,
                      last_updating_user: _.isEqual(task.status, taskStatus.IN_PROGRESS)
                        ? task.last_updating_user
                        : false,
                      commentsArray: task.comments,
                    }}
                  />
                )}
            </div>
        </div>

    )
}
