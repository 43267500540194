import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { Insight } from 'constants/insights.constants';
import { Button } from 'components/Layout/Shared/Elements';
import { CSVLink } from 'react-csv';
import { GALLERY } from 'components/Layout/theme';
import { updateInsight } from 'services/insights.services';
import _ from 'lodash';
import { DOWNLOAD } from 'assets/icons/General';

export const GalleryWrapper: React.FC<{ selectedInsight: Insight; insights: Insight[]; readonly: boolean }> = ({
  selectedInsight,
  insights,
  readonly
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const sas = useAppSelector((state) => state.global.SAS);

  function approveEdit(editText: Record<string, string>) {
    const textToUpdate =
      editText.name === 'title'
        ? { title: editText.value }
        : editText.name === 'description'
          ? { description: editText.value }
          : {};
    const { ...insightToUpdate }: any = selectedInsight;
    if (insightToUpdate.hasOwnProperty('key')) {
      delete insightToUpdate.key;
    }
    if (!insightToUpdate.hasOwnProperty('url')) {
      insightToUpdate.url = null;
    }
    dispatch(updateInsight({ ...insightToUpdate, ...textToUpdate }));
  }

  return (
    <>
      {selectedInsight && Object.keys(selectedInsight).length !== 0 ? (
        <div className='gallery-wrapper'>
          <CSVLink className='download-button' data={insights}>
            <div className='button button-action insights-download'>
              <p>CSV</p>
              <DOWNLOAD />
            </div>
          </CSVLink>
          <GALLERY selectedInsight={selectedInsight} approveEdit={approveEdit} readonly={readonly} />
        </div>
      ) : (
        <div className='gallery'>
          <div className='gallery-content empty-gallery'>
            <img
              className='empty-gallery-image'
              src={'https://trustnetplatformstorage.file.core.windows.net/images/global/fishing_blue.png' + sas}
              alt={'Fishing Blue'}
            />
            <div className='empty-gallery-text'>
              <h2>There are no insights to display</h2>
              <p>Please click the "Plus" sign to create a new insight</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
