import React, { Fragment } from 'react';
import _ from 'lodash';

import { orderBy  } from 'constants/ui.constants';
import { _t } from 'components/helpers';
import { ARROW_DOWN, ARROW_UP } from 'assets/icons/General';

type sortHeaderType = {
    name:string;
    title:string;
}

type active = {
    name:string;
    orderByState:orderBy;
}

type sortParams = {
    title?:string;
    headers:sortHeaderType[];
    handleChange:any;
    active:active;
}

export const SortHeaders: React.FC<sortParams> = ({headers, handleChange, active, title}) => {
    return <div className='sort-headers'>
    {title && <div className='sort-header grayed'>{title}</div>}
    { headers.map((header:sortHeaderType,index:number) => {
        return <SortHeader key={index} handleChange={handleChange} header={header} active={active}/>
    })}
    </div>;
}

export const SortHeader: React.FC<{header:sortHeaderType,active:active,handleChange:any}> = ({header, handleChange, active}) => {
        return <div className='sort-header'>
            {header.title}
            <div
                className={`header-controler${active.name === header.name ? ' active':''}`}
                onClick={()=>{
                    const orderByState = active.name === header.name ?
                    active.orderByState === orderBy.ASC ?
                    orderBy.DESC : orderBy.ASC : orderBy.ASC ;
                    handleChange(header.name,orderByState)}}
            >
                { active.name === header.name ?
                active.orderByState === orderBy.ASC ?
                <ARROW_DOWN/> : <ARROW_UP/> : <ARROW_DOWN/> }
            </div>
        </div>;
}