import React from 'react';
import { useAppDispatch } from 'hooks/redux.hooks';
import { Insight, userPreference } from 'constants/insights.constants';
import { setSelectedInsight } from 'services/insights.services';
import { GALLERY_SLIDER } from 'components/Layout/Shared/GallerySlider';

function selectItem(item: Insight, dispatch: any) {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  dispatch(setSelectedInsight(item.id));
}

export const InsightsGallerySliders: React.FC<{ selectedInsight: Insight; insights: Insight[] }> = ({
  selectedInsight,
  insights
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const insightsPerPage = 4;
  const newUserPreference = Object.values(userPreference);

  return (
    <>
      {newUserPreference.map((value, index) => {
        const preference_text = value;
        const relatedInsights = insights.filter((item) => item.user_preference === preference_text);
        return relatedInsights.length > 0 ? (
          <GALLERY_SLIDER
            key={index}
            title={preference_text}
            selected={selectedInsight}
            list={insights.filter((item) => item.user_preference === preference_text)}
            selectItem={selectItem}
            amountPerPage={insightsPerPage}
          />
        ) : (
          <React.Fragment key={index}></React.Fragment>
        );
      })}
    </>
  );
};
