import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { endOfDay, format, isValid, startOfDay } from 'date-fns';

import { timing } from 'constants/ui.constants';
import { useAppDispatch } from 'hooks/redux.hooks';
import 'react-datepicker/dist/react-datepicker.css';

type params = {
  label:string;
  dateKey:string;
  uiDate:string, 
  handleDispatch:any
}

export const DateFilter: React.FC<params> = (props) => {
    const {uiDate, handleDispatch, label, dateKey} = props; 
    const dispatch = useAppDispatch();  
    const selectedDate = !isValid(new Date(uiDate)) ? new Date() : new Date(uiDate);
    const inputRef = useRef(null)

    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (date) => {
      dispatch(handleDispatch({
        name:dateKey,
        value: _.isEqual(dateKey,'start_date') ? 
        String(startOfDay(date)) : String(endOfDay(date)),
      }));
      setIsOpen(!isOpen);
    };
    const handleClick = (e:Event) => {
      e.preventDefault();
      setIsOpen(!isOpen);
    };
    useEffect(() => {
      function handleClickOutside(e:Event) {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);

    }, [inputRef]);
    return (
      <div className="select-box-container datepicker1" ref={inputRef}>
        <div className='select-box__control'>
            <div className='select-box__value-container'>
                <div  className='select-box__single-value'  onClick={handleClick}>
                {label} - {isValid(selectedDate) && format(selectedDate, timing.DATE)}
                </div>
            </div>
        </div>
      
      {isOpen && (
            <div className='datepicker-wrap'>
                <DatePicker
                    selected={selectedDate}
                    selectsStart={dateKey === 'start_date'}
                    selectsEnd          
                    onChange={handleChange}                              
                    inline
                />
            </div>
      )}
      </div>
    )
}