import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

import { timing } from 'constants/ui.constants';
import { IncidentsCls } from 'class/class.incidents';
import { activeTabs, IncidentStatus, partialIncident, IncidentsClosingReason } from 'constants/incident.constants';
import { LONG_CARD } from 'components/Layout/Shared/Cards/LongCard';
import { TasksSummary } from './TaskSummary';
import { activeIncidentInitialState, setConrolRoomActiveIncident } from 'state/features/ui.features';
import { useAppDispatch } from 'hooks/redux.hooks';

export const IncidentItem: React.FC<{ item:partialIncident, isSelected:boolean, tab?:activeTabs, activeTitle?:boolean }>
 = ({ item, isSelected, tab, activeTitle = true }) => {
    const tasks = IncidentsCls.tasksSummary(item);
    let extraParams:any = {};
    if(item.status === IncidentStatus.CLOSE){
        extraParams.reason = item.closing_reason
    }
    if(activeTitle){
        extraParams.activeTitle = () => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            const dispatchState = isSelected ? activeIncidentInitialState : {tab:tab, active:true, incident:item}; 
            dispatch(setConrolRoomActiveIncident(dispatchState))
        }
    }
    const dispatch = useAppDispatch();
        return <LONG_CARD
        item={{
            ...extraParams,
            severity:item.severity,
            category_name:item.category_name,
            title:item.title,
            external_id:item.external_id,
            isSelected:isSelected,
            //createdDate:format(new Date(item.created_at), timing.TIME),
            createdDate: format(new Date(item.created_at), 'HH:mm'),
            //updateDate:'Last update ' + format(new Date(item.updated_at), timing.FULLDATE),
            updateDate: 'Last update ' + format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm'), // Update the date format to 'dd/MM/yyyy HH:mm'
            status:item.status,
            owner:item.owner ? item.owner : '-',
            tasks: <TasksSummary tasks={tasks}/>
        }}
    />
}