import React from 'react';
import _ from 'lodash';
import { Text, View } from '@react-pdf/renderer';
import { format, isValid } from 'date-fns';

import { styles } from '../styles';
import { Insight, userPreference } from 'constants/insights.constants';
import { timing } from 'constants/ui.constants';

export const InsightsTable: React.FC<{insights:Insight[]}> = ({insights}) => {
    const companyInsights = _.filter(insights,{'user_preference' : userPreference.INTERESTED})

    return (
        <View break>
            <View style={styles.headerParagraph}>
                <Text>All Insights</Text>
            </View>
            <View style={styles.rowHeader}>
                <Text style={styles.column7}>Title</Text>
                <Text style={styles.column8}>Status</Text>
                <Text style={styles.column8}>Due Date</Text>
            </View>
            {companyInsights.map((insight, index) =>
                <View style={styles.row} key={index}>
                    <Text style={styles.column7}>{insight.title}</Text>
                    <Text style={styles.column8}>{insight.status}</Text>
                    <Text style={styles.column8}>{!_.isEmpty(insight.due_date) && isValid(new Date(insight.due_date)) && format(new Date(insight.due_date), timing.DATE)}</Text>
                </View>
            )}
        </View>
    )
}