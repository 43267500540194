import React from 'react';


import { _t} from 'components/helpers';
import { useAppSelector } from 'hooks/redux.hooks';
import { lang_prefix } from 'constants/ui.constants';
import { ParagraphText, ParagraphTitleText } from 'components/Layout/Shared/Elements';

export const Greeting: React.FC<{lastModified:string, title:string}> = ({lastModified, title}) => {
    const translation = useAppSelector((state) => state.company.translation);
    

    return (
        <div className='greeting'>
            <ParagraphTitleText>
                {title}
            </ParagraphTitleText>
            {lastModified && <ParagraphText style='modified'>
                {_t('last_modified',translation,lang_prefix.API_TITLE)} {lastModified}
            </ParagraphText>}
        </div>
    )
}