import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import _ from 'lodash';

import './scss/index.scss';
import App from './app/App';
import { store } from './app/state/store';
import { msalConfig } from './services/auth.service';

export const msalInstance = new PublicClientApplication(msalConfig);
const handleRedirectResponse = (resp: AuthenticationResult | null) => {
  if (_.isNil(resp)) {
    const currentAccounts = msalInstance.getAllAccounts();
    currentAccounts.length === 1 && msalInstance.setActiveAccount(currentAccounts[0]);
  } else {
    msalInstance.setActiveAccount(resp.account);
  }
};
msalInstance
  .handleRedirectPromise()
  .then(handleRedirectResponse)
  .catch((error) => {
    console.error(error.message);
  });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
