import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { ONE_COLUMN } from 'components/Layout/theme';
import { fetchInsights, updateInsight } from 'services/insights.services';
import { userPreference, cancelText, insightStatus, inProgress, Insight } from 'constants/insights.constants';
import { Action } from 'constants/action-buttons.constants';
import { userRoles } from 'state/features/global.features';
import { DueDate } from './DueDate';
import { GalleryWrapper } from './GalleryWrapper';
import { InsightsGallerySliders } from './InsightsGallerySliders';
import { AddInsight } from './AddInsight';
import { PopUp } from 'components/Layout/Shared/PopUp';
import { InsightForm } from './InsightForm';
import _ from 'lodash';

export const Insights: React.FC = () => {
  const dispatch = useAppDispatch();
  const insights = useAppSelector((state) => state.insights.items);
  const selectedInsight = useAppSelector((state) => state.insights.selected);
  const userRole = useAppSelector((state) => state.global.userRole);

  useEffect(() => {
    dispatch(fetchInsights());
  }, []);

  return (
    <ONE_COLUMN>
      <DueDate updateInsights={() => updateInsights(event, selectedInsight, dispatch)} />
      <GalleryWrapper selectedInsight={selectedInsight} insights={insights} readonly={_.isEqual(userRole,userRoles.USER)} />
      { _.isEqual(userRole,userRoles.ADMIN) && <AddInsight /> }
      <InsightsGallerySliders selectedInsight={selectedInsight} insights={insights} />
      <PopUp>
        <InsightForm></InsightForm>
      </PopUp>
    </ONE_COLUMN>
  );
};

export function updateInsights(event: any, item: Insight, dispatch: any) {
  const { key, url, ...insightToUpdate }: any = item;
  switch (event.target.innerHTML) {
    case Action.INTERESTED.toLowerCase():
      insightToUpdate.user_preference = userPreference.INTERESTED;
      insightToUpdate.status = insightStatus.WAITING_FOR_WORK;
      break;
    case Action.WE_GOT_ONE.toLowerCase():
      insightToUpdate.is_relevant = !insightToUpdate.is_relevant;
      insightToUpdate.user_preference = userPreference.WE_GOT_ONE;
      break;
    case Action.MAYBE_LATER.toLowerCase():
      insightToUpdate.user_preference = userPreference.MAYBE_LATER;
      break;
    case cancelText:
      insightToUpdate.user_preference = userPreference.RECOMMENDED_FOR_YOU;
      insightToUpdate.status = insightStatus.NEW_RECOMMENDATION;
      break;
    case inProgress:
      insightToUpdate.status = insightStatus.AT_WORK;
      break;
    case insightStatus.FINISHED:
      insightToUpdate.status = insightStatus.FINISHED;
      insightToUpdate.user_preference = userPreference.WE_GOT_ONE;
      break;
    default:
      insightToUpdate.status = insightStatus.NEW_RECOMMENDATION;
      insightToUpdate.user_preference = userPreference.RECOMMENDED_FOR_YOU;
      break;
  }
  dispatch(updateInsight(insightToUpdate));
}
