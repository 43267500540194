import React from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { setPopupDisplay } from 'state/features/ui.features';

export const AddInsight: React.FC<{}> = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.global.userRole);
  const darkMode = useAppSelector((state) => state.ui.darkMode);

  return (
    <>
      {userRole && (
        <div className='add-item-wrapper'>
          <div className={`add-item ${darkMode ? 'dark' : ''}`}>
            <div className='grey-button' onClick={() => dispatch(setPopupDisplay())}>
              <p>&#43;</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
